export const html:string = `
<div class="product-production-time" v-if="message">
    {{ message }}
</div>
<div class="product-production-time" v-if="!message && rush != standard">
    Ships in {{ rush }} to {{ standard }} days
</div>
<div class="product-production-time" v-if="!message && rush == standard">
    Ships within {{ standard }} days
</div>
`;