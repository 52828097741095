import {html} from './AutosaveComponent.html';
import {AutosaveService} from '../../services/AutosaveService';
import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import CustomEditorService from '../../services/CustomEditorService';
import IVueComponent from '../../../../core/adapters/IVueComponent';


class AutosaveComponentController extends VueComponent {
    public static override $inject = [
        'AutosaveService',
    ];

    @prop()
    productService: CustomEditorService;

    @data()
    is_ready: boolean;

    @data()
    autosave: AutosaveService;

    constructor(component, autosave: AutosaveService) {
        super(component);

        this.autosave = autosave;
        this.productService.setup_complete.then(() => {
            this.autosave.setup(this.productService);
            this.is_ready = true;
        });
    }
}

export default function AutosaveComponent(): IVueComponent {

    return {
        controller: AutosaveComponentController,
        template: html,
        tag: 'autosave-component'
    };
}
