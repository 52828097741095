export const html:string = `
<div class="tool-tab">
    <label>Product Details</label>
    
    <div class="right-aligned-options-area" v-if="!productService.base.allow_custom_size">
        <div class="tool-selection-set">
            <div class="left-side">
                <span>Product Size</span>
            </div>
            <div class="right-side">
                <generic-dropdown :items="productService.recommended_sizes" v-model:selected="productService.size"></generic-dropdown>
            </div>
        </div>
    </div>
    <div v-for="clipart_element in element_manager.editableBackgroundClipartElements">
        <div v-for="region in clipart_element.model.regions" :class="{'important-options-area': region.region.type == 1 && region.hasStroke}">
            <div class="right-aligned-options-area">
                <div class="tool-selection-set">
                    <div class="left-side">
                        <span>{{ region.region.name }}</span>
                    </div>
                    <div class="right-side" v-if="!region.use_material_colors">
                        <generic-color-dropdown :items="productService.colors_available" v-model:selected="region.regionColor" :trigger-function="updateBackgroundColorCallback" :trigger-scope="region"></generic-color-dropdown>
                    </div>
                    <div class="right-side" v-if="region.use_material_colors">
                        <generic-color-dropdown :items="productService.colors_available_for_material" v-model:selected="region.regionColor" :trigger-function="updateBackgroundColorCallback" :trigger-scope="region"></generic-color-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <error-list :errors="productService.errors" field="material_color"></error-list>
    <div v-if="canvas_service.sides.length > 1" class="design-tool-sides clearfix">
        <div class="col-xs-6 side-preview-area" v-for="side in canvas_service.sides" @click="canvas_service.selectSide(side.id)" :class="{'selected-side': side.id == canvas_service.active_side}">
            <sub-canvas-preview :service="productService.canvasService" :side="side"></sub-canvas-preview>
            <label class="text-center full-width">{{ side.name }}</label>
        </div>
    </div>
    <slot></slot>
</div>
`;
