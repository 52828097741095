export const html:string = `
<div class="tool-options-footer" v-show="showFooterActions && activeTab != 'crop'" v-cloak>
    <button class="btn tool-duplicate-button" @click="duplicateItem($event)">
        <i class="fa fa-clone"></i>
        Duplicate Layer
    </button>
    <button class="btn tool-delete-button" @click="element.remove($event)">
        <i class="fa fa-times"></i>
        Remove Layer
    </button>
</div>
<div class="tool-options-footer" v-show="showFooterActions && activeTab == 'crop'" v-cloak>
    <button class="btn tool-duplicate-button" @click="doneEditingClipping($event)">
        <i class="fa fa-check"></i> Finish & Crop Item
    </button>
    <button class="btn tool-delete-button" @click="removeClipping($event)">
        <i class="fa fa-times"></i>
        Remove Cropping
    </button>
</div>
`;