import {ProductTypeEnum} from '../../models/enums';
import {MaterialDisplay} from '../../models/Template';

export const html: string = `
<div v-if="productService.base.material_display == ${MaterialDisplay.MATERIAL_ADHESIVE_DISPLAY}">
    <div class="col-xs-12 col-xs-full col-md-6 col-full-left">
        <div class="form-group">
            <label class="options-label">Material</label>
            <error-list field="material" :errors="productService.errors"></error-list>
            <generic-dropdown :items="productService.materialTypes()" v-model:selected="productService.material_name"></generic-dropdown>
        </div>
    </div>
    <div class="col-xs-12 col-xs-full col-md-6 col-full-left">
        <div class="form-group">
            <label class="options-label">Adhesive</label>
            <generic-dropdown :items="productService.adhesiveTypes()" v-model:selected="productService.adhesive_type"></generic-dropdown>
        </div>
    </div>
</div>
<div v-if="!productService.base.material_display || productService.base.material_display == ${MaterialDisplay.STANDARD_MATERIAL_LIST_DISPLAY}">
    <div v-show="productService.base.materials.length > 1 && !productService.base.hide_material" class="options-material margin-bttm">
        <label for="id-material-list" class="options-label">Select Material</label>
        <generic-dropdown :items="productService.base.materials" v-model:selected="productService.configured.material"></generic-dropdown>
    </div>
    
    <div v-if="showSelectColor()" class="clearfix margin-bttm options-color">
        <label for="id-color-list" class="options-label">Select Color</label>
        <error-list class="margin-bttm-0" field="material_color" :errors="productService.errors"></error-list>
        <div v-if="!productService.configured.material_color.id" class="block">
            <span class="name">No Color Selected</span>
        </div>
        
        <generic-color-dropdown v-if="!productService.base.show_material_color" :items="productService.colors_available" v-model:selected="productService.configured.material_color"></generic-color-dropdown>
        <generic-color-dropdown v-if="productService.base.show_material_color" :items="productService.colors_available_for_material" v-model:selected="productService.configured.material_color"></generic-color-dropdown>
    </div> 
</div>
`;
