import VueApp from '../../../core/adapters/VueApp';
import {data, method, prop} from '../../../core/adapters/VueComponent';
import ProductServiceAbstract from '../../products_shared/services/ProductServiceAbstract';

export default class ProductPageLoaderAbstract extends VueApp {
    protected base_data_key: string;
    protected configured_data_key: string;
    protected product_data: any;

    @prop()
    defaultPrice: string;

    @data()
    product_service: ProductServiceAbstract;

    @data()
    default_image: string;

    @data()
    default_image_webp: string;

    @data()
    hide_webp: boolean;

    @data()
    video_url: string;

    @data()
    show_packaging_description: boolean;

    constructor(product_service: ProductServiceAbstract,
                base_data_key: string = 'product',
                configured_data_key: string = 'configured_product') {
        super();

        this.product_service = product_service;
        this.base_data_key = base_data_key;
        this.configured_data_key = configured_data_key;
    }

    override beforeMount() {
        super.mounted();

        let user_element = document.getElementById("product-data");
        if (user_element && user_element.textContent) {
            this.bindData(JSON.parse(atob(user_element.textContent.replace(/(\r\n\t|\n|\r\t|\s)/gm, ''))));
        }
        else {
            console.error('No product data found to use for initialization, product service setup may fail. Verify that a script with id "product-data" exists and has text content.')
        }
    }

    bindData(data) {
        this.product_data = data;
        this.product_service.setupFromData(this.product_data[this.base_data_key], this.product_data[this.configured_data_key], this.product_data);
        this.onServiceSetup();
    }

    // Called when the service is setup
    protected onServiceSetup(): void {}

    @method()
    setImage(normal, webp) {
        this.default_image = normal;
        this.default_image_webp = webp;
        this.hide_webp = !webp;
        this.video_url = null;
    }

    @method()
    setVideo(video_url) {
        this.video_url = video_url;
        this.default_image = null;
        this.default_image_webp = null;
    }

    @method()
    scrollToTestimonials($event) {
        document.getElementById('reviews').scrollIntoView({behavior: 'smooth'});
    }
}

