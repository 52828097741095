export const html:string = `
<div class="tool-tab full-width-tool">
    <div class="col-xs-12">
        <label><i class="fa fa-cubes"></i> Change Type</label>
    </div>
    <div>
        <div class="col-xs-12 swap-search">
            <div class="input-group">
                <input type="text" class="form-control search-input" placeholder="Search..." @keydown.enter="search($event)" v-model="query">
                <a v-show="!loading" @click="search($event)" class="input-group-addon"><i class="fa fa-search"></i> Search</a>
                <a v-show="loading" @click="search($event)" class="input-group-addon"><i class="fa fa-spin fa-spinner"></i> Loading</a>
            </div>
        </div>
        <div class="col-xs-12 category-selection-area" v-if="categories && categories.length > 0">
            <div class="category-selection-box" @click="open_category_selection = !open_category_selection" v-click-outside="close_dropdown_callback">
                <div class="category-search-box">
                    <span>Category Filters</span>
                    <i class="fa fa-chevron-down pull-right"></i>
                </div>
                <div class="open-category-box" v-show="open_category_selection">
                    <div class="category-item" @click="search()">
                        <input type="checkbox" id="category-all" v-model="allCategoriesSelected" @change="setAllCategories">
                        <label for="category-all">All Categories</label>
                    </div>
                    <hr>
                    <div class="category-item" v-for="category in categories" @click="search()">
                        <input type="checkbox" :id="'category-' + category.id" v-model="category.selected">
                        <label :for="'category-' + category.id">{{ category.name }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-6 swap-item" v-for="item in items">
        <div class="inner-container" @click="swapTo(item)">
            <img :src="item.thumbnail_url">
            <span>{{ item.name }}</span>
        </div>
    </div>
</div>
`;
