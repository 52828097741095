import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './AddCliaprt.html';

class DesignToolAddClipartController extends AbstractDesignToolController {

}


export default function DesignToolAddClipart(): IVueComponent {

    return {
        controller: DesignToolAddClipartController,
        template: html,
        tag: 'design-tool-add-clipart'
    };
}
