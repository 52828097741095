export const html: string = `
<div v-if="element" class="clipart-editor-container">
    <div class="row">
        <div class="col-xs-12">
            <label>Edit Selected Item Name</label>
        </div>
        <div class="col-xs-12 col-sm-6">
            <input id="ddbElementName" v-model="element.model.text" class="form-control flat" />
        </div>
        <div class="col-xs-6 col-sm-3">
            <a class="btn-ghost btn-ghost-sm full" @click="save($event)"><span><i class="fa fa-check fa-success"></i> Save</span></a>
        </div>
        <div class="col-xs-6 col-sm-3">
            <a class="btn-ghost btn-ghost-sm full" @click="remove($event)"><span><i class="fa fa-trash fa-danger"></i> Remove</span></a>
        </div>
    </div>
</div>
`;
