import {html} from './SetupSize.html';
import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import UserProfileService from '../../../userprofile/services/UserProfileService';

export class SizeController extends VueComponent {
    static override $inject = [
        'UserProfileService',
    ];

    constructor(component, ups: UserProfileService) {
        super(component);

        this.ups = ups;
    }

    @prop()
    productService;

    @data()
    show_more_sizes: boolean;

    @data()
    ups: UserProfileService;
}

export default function TemplateSetupSize(): IVueComponent {
    return {
        controller: SizeController,
        template: html,
        tag: 'template-setup-options-size'
    }
}
