import VueApp from '../../../../core/adapters/VueApp';
import DesignToolAddClipart from './AddClipart/AddClipart';
import DesignToolAddFile from './AddFile/AddFile';
import DesignToolAddQrCode from './AddQRCode/AddQrCode';
import DesignToolAddText from './AddText/AddText';
import DesignToolBackgroundColors from './BackgroundColors/BackgroundColors';
import DesignToolCrop from './Crop/Crop';
import DesignToolEditClipart from './EditClipart/EditClipart';
import DesignToolEditFile from './EditFile/EditFile';
import DesignToolEditQRCode from './EditQRCode/EditQRCode';
import DesignToolEditText from './EditText/EditText';
import DesignToolLayers from './Layers/Layers';
import DesignToolSides from './Sides/Sides';
import DesignToolSwapTemplate from './SwapTemplate/SwapTemplate';
import DesignToolFooterActions from './FooterActions/FooterActions';
import VariableDataSelector from './VariableDataSelector/VariableDataSelector';

export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(DesignToolAddClipart);
    vue_app.registerComponent(DesignToolAddFile);
    vue_app.registerComponent(DesignToolAddQrCode);
    vue_app.registerComponent(DesignToolAddText);
    vue_app.registerComponent(DesignToolBackgroundColors);
    vue_app.registerComponent(DesignToolCrop);
    vue_app.registerComponent(DesignToolEditClipart);
    vue_app.registerComponent(DesignToolEditFile);
    vue_app.registerComponent(DesignToolEditQRCode);
    vue_app.registerComponent(DesignToolEditText);
    vue_app.registerComponent(DesignToolLayers);
    vue_app.registerComponent(DesignToolSides);
    vue_app.registerComponent(DesignToolSwapTemplate);
    vue_app.registerComponent(DesignToolFooterActions);
    vue_app.registerComponent(VariableDataSelector);
}
