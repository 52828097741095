export const html: string = `
<div class="tool-tab full-width-tool">
    <div class="qr-setup clearfix qr-setup-tool">
        <div class="col-xs-12">
            <label><i class="fa fa-qrcode"></i> Add QR/Bar Code</label>
            <span class="back-a-step" @click="reset()" v-show="step == 2 || step == 1.5"><i class="fa fa-arrow-left"></i> Back To Types</span>
        </div>
        <div v-show="step == 1" class="type-setup">
            <div class="col-xs-6 barcode">
                <i class="far fa-qrcode"></i>
                <button class="btn btn-ghost btn-full btn-sm" @click="active = 5; step = 1.5;">QR Code</button>
                <p class="padded">
                    Enter your own information to easily add a qr code to your sticker. We support website urls, contact cards and sms codes.
                </p>
            </div>
            <div class="col-xs-6 barcode">
                <i class="far fa-barcode-alt"></i>
                <button class="btn btn-ghost btn-full btn-sm" @click="active = 5; step = 2;">Bar Code</button>
                <p class="padded">
                    Add a bar code for product identification, stock management, or item tracking. We support a variety of types such as UPCA, PZN, EAN, ISBN, and more.
                </p>
            </div>
        </div>
        
        <div v-if="step == 1.5">
            <div class="col-xs-6">
                <img src="/static/img/qr/qr_code_website.png" alt="URL QR Code"/>
                <button class="btn btn-ghost btn-full btn-sm" @click="active = 1; step = 2;">Website URL</button>
                <p class="padded">
                    Direct people to any place on your website.
                </p>
            </div>
            <div class="col-xs-6">
                <img src="/static/img/qr/qr_code_contact.png" alt="Contact Card QR Code"/>
                <button class="btn btn-ghost btn-full btn-sm" @click="active = 2; step = 2;">Contact Info</button>
                <p class="padded">
                    Easily allow people to add your contact info
                </p>
            </div>
            <div class="col-xs-6">
                <img src="/static/img/qr/qr_code_text.png" alt="Text QR Code"/>
                <button class="btn btn-ghost btn-full btn-sm" @click="active = 3; step = 2;">Text Message</button>
                <p class="padded">
                    Send SMS text messages to predetermined number.
                </p>
            </div>
            <div class="col-xs-6">
                <img src="/static/img/qr/qr_code_custom.png" alt="Custom QR Code"/>
                <button class="btn btn-ghost btn-full btn-sm" @click="active = 4; step = 2;">Custom</button>
                <p class="padded">
                    Advanced options for custom QR codes.
                </p>
            </div>
        </div>
      
        <div class="data-setup col-xs-12">
            <div id="website" v-show="active == 1 && step == 2" class="qr-url">
                <label class="sub-label">Website</label>
                
                <div class="form-group col-xs-12">
                    <h5>Website URL:</h5>
                    <input class="form-control" name="url" :placeholder="'Example: ' + company.short_url" v-model="qr_data.url">
                </div>
                
                <div class="col-xs-12">
                    <button class="btn btn-ghost btn-sm pull-right" @click="next()">Continue</button>
                </div>
            </div>
            <div id="contact" v-show="active == 2 && step == 2">
                <div class="personal-info clearfix">
                    <label class="sub-label">Personal Information</label>
                    
                    <div class="form-group col-xs-12">
                        <h5>Name:</h5>
                        <input class="form-control" name="name" v-model="qr_data.name">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Email:</h5>
                        <input class="form-control" name="email" v-model="qr_data.email">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Address:</h5>
                        <input class="form-control" name="address" v-model="qr_data.home_address_1">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>State:</h5>
                        <input class="form-control" name="state" v-model="qr_data.home_state">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>Zip:</h5>
                        <input class="form-control" name="zip" v-model="qr_data.home_zip">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Title:</h5>
                        <input class="form-control" name="title" v-model="qr_data.title">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Telephone:</h5>
                        <input class="form-control" name="phone" v-model="qr_data.phone_home">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>City:</h5>
                        <input class="form-control" name="city" v-model="qr_data.home_city">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Country</h5>
                        <input class="form-control" name="country" v-model="qr_data.home_country">
                    </div>
                </div>
                <div class="personal-info clearfix">
                    <label class="sub-label">Work Information</label>
                    
                    <div class="form-group col-xs-12">
                        <h5 class="first-label">Company Name:</h5>
                        <input class="form-control" name="company_name" v-model="qr_data.company">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Telephone:</h5>
                        <input class="form-control" name="phone" v-model="qr_data.phone_work">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Address:</h5>
                        <input class="form-control" name="address" v-model="qr_data.work_address_1">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>City:</h5>
                        <input class="form-control" name="city" v-model="qr_data.work_city">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>State:</h5>
                        <input class="form-control" name="state" v-model="qr_data.work_state">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>Zip:</h5>
                        <input class="form-control" name="zip" v-model="qr_data.work_zip">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Country</h5>
                        <input class="form-control" name="country" v-model="qr_data.work_country">
                    </div>
                </div>
                <div class="col-xs-12">
                    <button class="btn btn-ghost btn-sm pull-right" @click="next()">Continue</button>
                </div>        
            </div>
            <div id="text" v-show="active == 3 && step == 2">
                <label class="sub-label">Text Message</label>
                
                <div class="form-group col-xs-12">
                    <h5>Cell Phone Number:</h5>
                    <input class="form-control" name="country" placeholder="Example: 555-555-5555" v-model="qr_data.sms">
                    <span class="helper-text">This is the cell phone number the text is coming from.</span>
                </div>
                
                <div class="form-group col-xs-12">
                    <h5>Message:</h5>
                    <textarea class="form-control" rows="10" name="message" placeholder="Type your message here" v-model="qr_data.smsm"></textarea>
                </div>
                
                <div class="col-xs-12">
                    <button class="btn btn-ghost btn-sm pull-right" @click="next()">Continue</button>
                </div>  
            </div>
            <div id="custom" v-show="active == 4 && step == 2">
                <label class="sub-label">Custom</label>
                
                <div class="form-group col-xs-12">
                    <h5>QR Code Data</h5>
                    <textarea class="form-control" rows="10" name="info" v-model="qr_data.custom"></textarea>
                </div>
                
                <div class="col-xs-12">
                    <button class="btn btn-ghost btn-sm pull-right" @click="next()">Continue</button>
                </div> 
            </div>
            <div id="custom" v-show="active == 5 && step == 2">
                <label class="sub-label">Bar Code</label>
                <div class="form-group col-xs-12">
                    <h5>Bar Code Type</h5>
                    <error-list field="bar_code_type" :errors="errors"></error-list>
                    <generic-dropdown v-model:selected="bar_code_type" :items="bar_code_types"></generic-dropdown>
                </div>
                
                <div class="form-group col-xs-12">
                    <h5>Data</h5>
                    <error-list field="value" :errors="errors"></error-list>
                    <input type="text" v-model="qr_data.value">
                </div>
                
                <div class="col-xs-12">
                    <button class="btn btn-ghost btn-sm pull-right" @click="next()">Continue</button>
                </div> 
            </div>
        </div>
        
        <div class="qr-step-block col-xs-12 col-full">
            <label>Creating a QR Code is Easy!</label>
            <div class="customize-steps light">
                <ul>
                    <li v-for="item in steps" :class="{'completed': step >= item.index}" @click="back(item.index)">
                        {{item.title}}
                        <h5>{{item.help}}</h5>
                    </li>
                </ul>
                <ul class="graphic">
                    <li :class="{'completed': step > 1}" @click="back(1)">
                        <i class="fa fa-check"></i>
                    </li>
                    <li :class="{'completed': step > 2}" @click="back(2)">
                        <span></span>
                        <i class="fa fa-check"></i>
                    </li>
                    <li class="{'completed': step > 3}" @click="back(3)">
                        <span></span>
                        <i class="fa fa-check"></i>
                    </li>
                </ul>
            </div>
            <p v-show="active" class="helper-text">
                Please keep in mind that not all QR scanners can read every type of QR code.
                <br>
                {{ company.name }} does not provide any support and will not refund sticker orders if your custom QR code does not work.
                <br>
                Please try your QR code before you purchase it.
            </p>
        </div>
    </div>
</div>
`;
