export const html: string = `
<div>
    <div class="col-xs-12">
        <template-setup-material :product-service="productService"></template-setup-material>
    </div>
    <div class="col-xs-12" v-if="!noShape">
        <template-setup-options-shapes :product-service="productService"></template-setup-options-shapes>
    </div>
    <div class="col-xs-12">
        <template-finalize-options :product-service="productService"></template-finalize-options>
    </div>
    <div class="col-md-12 col-xs-12" v-if="designTool">
        <quality-warning :product-service="productService"></quality-warning>
    </div>
    <div class="col-md-6 col-xs-12">
        <template-setup-options-size :product-service="productService"></template-setup-options-size>
    </div>
    <div class="col-md-6 col-xs-12">
        <template-setup-options-color :product-service="productService"></template-setup-options-color>
    </div>
    <div class="col-md-6 col-xs-12">
        <quantity-options :full="true" :product-service="productService"></quantity-options>
    </div>
</div>
`;