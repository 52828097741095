import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './WebsiteQRCode.html';
import Company from '../../../../core/services/Company';
import {Services} from '../../../../core/services/Services';
import APIResponse from '../../../../core/models/APIResponse';
import {UsesLink} from '../../../category/models/UsesLink';
import MessageList from '../../../../core/utils/MessageList';
import {QRCode, QRCodeType} from '../../models/QRCode';

class WebsiteQRCodeController extends VueComponent {
    @prop()
    uses: string;

    @prop()
    title: string;

    @data()
    qr_data: any;

    @data()
    company: Company;

    @data()
    products: APIResponse<UsesLink>;

    @data()
    step: number;

    @data()
    errors: MessageList;

    product;

    constructor(component) {
        super(component);

        this.qr_data = Object.create(null);
        this.company = Services.get<Company>('Company');
        this.errors = new MessageList();
        this.step = 1;
    }

    override mounted() {
        super.mounted();

        this.products = Services.get<typeof UsesLink>('UsesLink').objects.filter({
            category__slug: this.uses
        });
    }

    @method()
    async finalize() {
        this.errors = new MessageList();

        if (!this.qr_data.url || this.qr_data.url.trim() == '') {
            this.errors.add('url', 'This field is required.');
        }

        if (this.errors.length != 0) {
            return;
        }

        this.step = 3;

        let code = await this.createQRCode();

        window.location.href = `${this.product.url}?qr_id=${code.id}`;
    }

    @method()
    async setupProduct(product: UsesLink, $event) {
        this.step = 2;
        this.product = product;
    }

    async createQRCode(): Promise<QRCode> {
        const data_json = JSON.stringify(this.qr_data);

        let code = new (Services.get<typeof QRCode>('QRCode'))({
            data_json: data_json,
            qrcode_type: QRCodeType.WEBSITE
        });

        code.save();

        code.$promise.catch(() => {
            this.step = 2;
        });

        await code.$promise;

        return code;
    }
}

export default function WebsiteQRCode(): IVueComponent {
    return {
        tag: 'website-qr-code',
        controller: WebsiteQRCodeController,
        template: html
    }
}
