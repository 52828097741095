export const html: string = `
<div id="show-related-products-modal" class="modal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" @click="close()"><span>&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel">Design Tool</h4>
            </div>
            <div class="modal-body clearfix">
                <ul class="item-list col-xs-12 col-full">
                    <li v-for="template in product_service.similar_templates.items" class="col-xs-6 col-sm-4 select-file-option">
                        <div class="user-file-container">
                            <div @click="selectDesign(template)">
                                <image-shadow :image="template.thumbnail_url"></image-shadow>
                            </div>
                            <div class="user-file-action-area">
                                <span class="block">{{ template.name }}</span>
                                <button class="btn btn-ghost btn-sm" @click="selectDesign(template, $event)">Use This Design</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
`;
