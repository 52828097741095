export const html:string = `
<div class="tool-tab">
    <label><i class="fa fa-image"></i> {{ element.model.label }}</label>
    <div class="marketplace-info clearfix" v-if="isSelectionMarketplace()">
        <b class="full-width">Licensed Graphic</b>
        <hr>
        <div class="attribution">
            <b>Artist:&nbsp;</b>
            <span>{{ element.model.artwork.artist.name }}</span>
        </div>
        <b>Powered By Shutterstock</b>
    </div>
    <div v-for="region in element.model.regions" :class="{'important-options-area': region.region.type == 1 && region.hasStroke}">
        <div class="right-aligned-options-area">
            <div class="tool-selection-set">
                <div class="left-side">
                    <span>{{ region.region.name }}</span>
                </div>
                
                <div class="right-side color-picker-set" v-if="!isSingleColor() && !region.use_material_colors">
                    <generic-color-dropdown :items="productService.colors_available" v-model:selected="region.regionColor" :none="'Custom Color: ' + region.custom_color" :none-color="region.custom_color" :hide-none="true"></generic-color-dropdown>
                    <color-picker v-model:color="region.custom_color"></color-picker>
                </div>
                <div class="right-side color-picker-set" v-if="!isSingleColor() && region.use_material_colors">
                    <generic-color-dropdown :items="productService.colors_available_for_material" v-model:selected="region.regionColor" :none="'Custom Color: ' + region.custom_color" :none-color="region.custom_color" :hide-none="true"></generic-color-dropdown>
                </div>
                <div class="right-side" v-if="isSingleColor()">
                    <generic-color-dropdown :items="productService.colors_available" v-model:selected="productService.configured.material_color"></generic-color-dropdown>
                </div>
            </div>
        </div>
        <div class="normal-options-area" v-show="region.region.type == 1 && region.hasStroke">
            <div class="tool-action-item">
                <button class="btn btn-light-tool btn-single" @click="changeStrokeWidth(region, 1, $event)">
                    <i class="fa fa-plus-circle"></i>
                </button>
                <button class="btn btn-light-tool btn-single" @click="changeStrokeWidth(region, -1, $event)">
                    <i class="fa fa-minus-circle"></i>
                </button>
                <span>Stoke Width</span>
            </div>
            <div class="tool-action-item">
                <input v-model="region.stroke_width">
                <span>Stroke Width</span>
            </div>
            <div class="tool-action-item">
                <button class="btn btn-light-tool" @click="region.removeStroke()">
                    <i class="fa fa-times"></i>
                </button>
                <span>Remove</span>
            </div>
        </div>
    </div>
    <div class="important-options-area" v-show="elementHasAdvancedOptions()">
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.centerVertically()">
                <img src="/static/img/vector-canvas/center-horizontally.svg">
            </button>
            <button class="btn btn-light-tool" @click="element.centerHorizontally()">
                <img src="/static/img/vector-canvas/center-vertically.svg">
            </button>
            <span>Alignment</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool btn-single" @click="scaleSize(0.05, $event)">
                <i class="fa fa-plus-circle"></i>
            </button>
            <button class="btn btn-light-tool btn-single" @click="scaleSize(-0.05, $event)">
                <i class="fa fa-minus-circle"></i>
            </button>
            <span>Clipart Size</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="moveElementUp($event)">
                <img src="/static/img/vector-canvas/layer-up-icon.svg">
            </button>
            <button class="btn btn-light-tool" @click="moveElementDown($event)">
                <img src="/static/img/vector-canvas/layer-down-icon.svg">
            </button>
            <span>Layering</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.model.maintain_proportion = !element.model.maintain_proportion">
                <span v-show="!element.model.maintain_proportion"><i class="fa fa-unlock"></i> Unlocked</span>
                <span v-show="element.model.maintain_proportion"><i class="fa fa-lock"></i> Locked</span>
            </button>
            <span>Proportions</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.model.x_scale_inverted = !element.model.x_scale_inverted">
                <span><i class="fa fa-exchange"></i> Invert</span>
            </button>
            <span>Invert Clipart</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="switchClipart($event)">
                <span><i class="fa fa-sync"></i> Replace</span>
            </button>
            <span>Replace Clipart</span>
        </div>
    </div>
    <div class="normal-options-area" v-if="isSelectionMarketplace() && !productService.base.disable_remove_background">
        <b>Remove Image Background</b>
        <button @click="toggleBackground($event)" class="btn btn-light-tool full-width">
            <span v-if="!element.model.removed_background"><i class="fa fa-cut"></i> Remove</span>
            <span v-if="element.model.removed_background"><i class="fa fa-undo"></i> Undo</span>
        </button>
    </div>
    <div class="right-aligned-options-area margin-set" v-if="element.can_offset_path">
        <b>Cutpath Options</b>
        <div class="tool-selection-set" v-show="element.offset_path_enabled">
            <div class="left-side">
                <span>Border Width</span>
            </div>
            <div class="right-side">
                <div class="col-xs-6 col-full-left">
                    <input type="range" min="7" max="30" v-model="element.model.offset_width" @change="element.queueOffsetPath()">
                </div>
                <div class="col-xs-6 input-button-group">
                    <input type="number" min="7" max="30" v-model="element.model.offset_width" @change="element.queueOffsetPath()">
                    <button class="btn btn-light-tool" @click="element.removeOffsetPath()"><i class="fa fa-times"></i></button>
                </div>
            </div>
        </div>
        <div class="tool-selection-set" v-show="element.offset_path_enabled && !productService.base.offset_path_uses_material_color">
            <div class="left-side">
                <span>Border Color</span>
            </div>
            <div class="right-side color-picker-set">
                <generic-color-dropdown :items="productService.colors_available" v-model:selected="productService.configured.offset_path_fill_color" 
                    none="Custom Color" :none-color="productService.configured.offset_path_custom_fill_color" :hide-none="true" :trigger-function="on_path_color_change">    
                </generic-color-dropdown>
                
                <color-picker v-model:color="productService.configured.offset_path_custom_fill_color" :change="on_path_color_change"></color-picker>
            </div>
        </div>
        <div class="right-side" v-show="!element.offset_path_enabled">
            <button class="btn btn-light-tool" @click="element.addOffsetPath()">Add Border</button>
        </div>
    </div>
    <slot></slot>
</div>
`;
