import {html} from './RelatedTemplates.html';
import {ModalController} from '../../../../../../core/adapters/ModalComponent';
import CustomService from '../../../../services/CustomService';
import IVueComponent from '../../../../../../core/adapters/IVueComponent';
import {data, method} from '../../../../../../core/adapters/VueComponent';

class VectorCanvasModalsRelatedTemplatesController extends ModalController {
    static override $inject = [
        'CustomService',
    ];

    @data()
    public product_service;

    constructor(component, ps: CustomService) {
        super(component, 'show-related-products-modal')

        this.product_service = ps;
    }

    @method()
    selectDesign(template, $event) {
        this.close();
        this.product_service.useDesign(template, $event);
    }
}

export default function VectorCanvasModalsRelatedTemplates(): IVueComponent {
    return {
        controller: VectorCanvasModalsRelatedTemplatesController,
        template: html,
        tag: 'vector-canvas-modals-related-templates'
    };
}
