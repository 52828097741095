export const html:string = `
<div class="single-step-qr-code-component">
    <steps-indicator :one="'QR Code Info'" :two="'Design'" :three="'Customize'" :step="step"></steps-indicator>
    <div v-if="step == 1">
        <div class="responsive-grid-md-5 responsive-grid-lg-5" v-if="products">
            <div v-for="product in products.items" class="gc-xs-6 gc-sm-4 gc-md-1 qr-product-tile" @click="setupProduct(product, $event)">
                <img :src="product.image_url">
                <span>{{ product.name }}</span>
            </div>
        </div>
    </div>
    <div v-if="step == 2" class="data-collection-section">
        <div class="form-group col-xs-12">
            <label>{{ (title ? title : 'Website URL') + ':' }}</label>
            <error-list :errors="errors" field="url"></error-list>
            <input class="form-control" name="url" :placeholder="'Example: ' + company.short_url" v-model="qr_data.url">
        </div>
        
        <div class="col-xs-12 text-center">
            <button class="btn btn-ghost" @click="finalize()">Continue</button>
        </div>
    </div>
    <div v-if="step == 3" class="text-center">
        <h2>Setting Up Product...</h2>
    </div>
</div>
`;
