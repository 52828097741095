export const html: string = `
<ul class="clearfix">
    <li v-for="item in product_service.clipart.items" @click="addClipart(item, $event)">
        <div class="ddb-preview-item">
            <img :style="{ width: item.selectorWidth + 'px', height: item.selectorHeight + 'px' }" :src="item.clipart.clipart " :alt="item.clipart.name"/>
            <span>{{ item.name }}</span>
        </div>
    </li>
</ul>
`;
