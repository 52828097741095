import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './EditText.html';
import {data, method} from '../../../../../core/adapters/VueComponent';
import {SymbolType} from '../../../models/enums';

class DesignToolEditTextController extends AbstractDesignToolController {

    @data()
    show_stroke: boolean;

    @data()
    in_advanced: boolean;

    @data()
    show_text_type_option: boolean;

    @method()
    textTypeDisplay() {
        if (!this.element) {
            return 'Custom Text';
        }

        if (this.element.model.symbol == SymbolType.CSV_LIST) {
            return 'Comma Separated List';
        }
        else {
            return 'Custom Text';
        }
    }

    @method()
    elementIsRange() {
        if (!this.element) {
            return false;
        }

        return this.element.model.symbol == SymbolType.NUMBER_RANGE;
    }
}


export default function DesignToolEditText(): IVueComponent {

    return {
        controller: DesignToolEditTextController,
        template: html,
        tag: 'design-tool-edit-text'
    };
}
