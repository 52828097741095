import {html} from './TemplateSetupOptionsShapes.html';
import VueComponent, {computed, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {TemplateShape} from '../../models/TemplateShape';
import {Services} from '../../../../core/services/Services';
import {CustomShape} from '../../models/CustomShape';
import CustomEditorService from '../../services/CustomEditorService';
import PaperCanvasService from '../../services/PaperCanvasService';

export class ShapesController extends VueComponent {

    @prop()
    productService: CustomEditorService;

    @prop()
    side: number;

    @method()
    setNewShape(shape: TemplateShape) {
        if (this.productService.configured.custom_shapes.find(v => v.template_shape.id == shape.id)) {
            return;
        }

        let to_remove = this.productService.configured.custom_shapes.find(v => v.template_shape.side?.id == shape.side?.id);
        let index = this.productService.configured.custom_shapes.indexOf(to_remove);
        this.productService.configured.custom_shapes.splice(index, 1);
        to_remove.trigger('remove');


        let custom_shape = new (Services.get<typeof CustomShape>('CustomShape'))();
        custom_shape.template_shape = shape;
        custom_shape.bindToConfigured(this.productService.configured);
        custom_shape.loadDefaultsFromTemplate(to_remove.carryOverData());

        this.productService.configured.custom_shapes.push(custom_shape);
        this.productService.setupShapeElement(custom_shape);
        this.productService.fitShape(custom_shape);
        this.productService.checkSharedShapeSides(custom_shape);
        this.productService.checkSharedShapeAttributes(custom_shape);

        if (Services.isRegistered('PaperCanvasService')) {
            Services.get<PaperCanvasService>('PaperCanvasService').trigger('sync');
        }
    }

    @method()
    templateShapes() {
        let selected_side = this.side;
        if (this.side == 0) {
            selected_side = undefined;
        }

        return this.productService.base.template_shapes.filter(v => v.side?.id == selected_side);
    }

    @method()
    customShapes() {
        let selected_side = this.side;
        if (this.side == 0) {
            selected_side = undefined;
        }

        return this.productService.configured.custom_shapes.filter(v => v.template_shape.side?.id == selected_side);
    }

    @computed()
    viewbox() {
        return `0 0 ${this.productService.width || 0} ${this.productService.height || 0}`;
    }

    @method()
    isShapeSelected(shape: TemplateShape) {
        return this.productService.configured.custom_shapes.find(v => v.template_shape?.id == shape.id)
    }

    @method()
    svgContent(shape: CustomShape) {
        let x = 0;
        let y = 0;
        let w = this.productService.width || 0;
        let h = this.productService.height || 0;
        let r = shape.clip_radius || 0;

        let arcW, arcH;

        if (w > h) {
            arcW = w * (r/100.00);
            arcH = arcW;
            if (arcW > h / 2)
                arcH = h / 2
        }
        else {
            arcH = h * (r/100.00);
            arcW = arcH;
            if (arcH > w / 2)
                arcW = w / 2
        }

        return "M" + (x + arcW) + "," + y
            + " h " + (w - (arcW * 2))
            + " a " + arcW + "," + arcH + " 0 0 1 " + arcW + "," + arcH
            + " v " + (h - 2 * arcH)
            + " a " + arcW + "," + arcH + " 0 0 1 " + -arcW + "," + arcH
            + " h " + (arcW * 2 - w)
            + " a " + arcW + "," + arcH + " 0 0 1 " + -arcW + "," + -arcH
            + " v " + (-(h - 2 * arcH))
            + " a " + -arcW + "," + -arcH + " 0 0 1 " + arcW + "," + -arcH + ' z';
    }
}

export default function TemplateSetupOptionsShapes(): IVueComponent {
    return {
        controller: ShapesController,
        template: html,
        tag: 'template-setup-options-shapes'
    }
};
