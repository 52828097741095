import {html} from './AddQrCode.html';
import VueComponent, {data, method, prop} from '../../../../../core/adapters/VueComponent';
import Company from '../../../../../core/services/Company';
import CustomEditorService from '../../../services/CustomEditorService';
import {BarCodeTypeDisplayMap, CodeType, QRCode} from '../../../models/QRCode';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {Services} from '../../../../../core/services/Services';
import MessageList from '../../../../../core/utils/MessageList';

export class AddQrCodeComponentController extends VueComponent {
    @data()
    qr_data;

    @data()
    steps;

    @data()
    company: Company;

    @data()
    step: number;

    @data()
    active: number;

    @prop()
    productService: CustomEditorService;

    @data()
    show_select_product: boolean;

    @data()
    bar_code_types = [];

    @data()
    bar_code_type;

    @data()
    errors: MessageList = new MessageList();

    constructor(component) {
        super(component);

        this.company = Services.get<Company>('Company');
        this.qr_data = Object.create(null);
        this.step = 1;
        this.show_select_product = false;

        for (const item of Object.keys(BarCodeTypeDisplayMap)) {
            this.bar_code_types.push({
                id: item,
                name: BarCodeTypeDisplayMap[item]
            })
        }

        this.steps = [
            {title: 'PICK A TYPE', help: '(see above types)', index: 1},
            {title: 'ENTER YOUR INFO', index: 2},
            {title: 'CUSTOMIZE!', index: 3}
        ];
    }

    @method()
    reset() {
        this.step = 1;
        this.qr_data = Object.create(null);
        this.errors.reset()
    }

    @method()
    async next() {
        this.errors = new MessageList();

        const data_json = JSON.stringify(this.qr_data);

        const code = new (Services.get<typeof QRCode>('QRCode'))({
            data_json: data_json,
            qrcode_type: this.active - 1
        });

        if (this.active <= 4) {
            code.qrcode_type = this.active - 1;
            code.code_type = CodeType.QR_CODE;
        }
        else {
            if (!this.bar_code_type) {
                this.errors.add('bar_code_type', 'This field is required.');
            }

            if (!this.qr_data.value) {
                this.errors.add('value', 'This field is required.')
            }

            if (this.errors.length > 0) {
                return;
            }

            code.barcode_type = this.bar_code_type.id;
            code.code_type = CodeType.BAR_CODE;
        }

        await code.save().catch(() => {
            this.errors.merge(code.errors.list);
        });

        this.productService.createQRCodeElement({
            qrcode: code
        });
    }

    @method()
    back(index) {
        if (this.step > index) {
            this.step = index;
        }
    }

    @method()
    selected(item) {}
}

export default function DesignToolAddQrCode(): IVueComponent {

    return {
        controller: AddQrCodeComponentController,
        template: html,
        tag: 'design-tool-add-qr'
    };
}
