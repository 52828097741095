import {ProductTypeEnum} from '../../models/enums';

export const html: string = `
<div id="vector-canvas-modals-setup-upload-modal" class="modal setup-upload">
    <div class="modal-dialog modal-lg" v-if="isOpen" :class="{'modal-fit': method == 'size-dpi-checks'}"> 
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" aria-label="Close" @click="close()"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="gridSystemModalLabel">Upload Graphic</h4>
            </div>
            
            <!-- Upload or select -->
            <div class="modal-body clearfix" v-if="method != 'size-dpi-checks'">
                <div class="list-group col-xs-12 col-sm-4 no-padd-xs">
                    <a class="list-group-item" :class="{active: method == 'upload'}" @click="method = 'upload'">
                        <h4 class="list-group-item-heading">Upload File</h4>
                        <p class="list-group-item-text">Upload a new file for this product</p>
                    </a>
                    <a class="list-group-item" :class="{active: method == 'my-files'}" @click="method = 'my-files'" v-if="user_files.items.length > 0">
                        <h4 class="list-group-item-heading">Use Previously Uploaded File</h4>
                        <p class="list-group-item-text">Already upload a file? Use it again for this product.</p>
                    </a>
                    <a class="list-group-item" :class="{active: method == 'my-orders'}" @click="method = 'my-orders'" v-if="processed_files.items.length > 0">
                        <h4 class="list-group-item-heading">Reorder A Product</h4>
                        <p class="list-group-item-text">Looking to reorder a product? Select it from the list.</p>
                    </a>
                </div>
                <div class="col-xs-12 col-sm-8">
                    <!-- Upload New File -->
                    <div class="panel panel-primary row" v-if="method == 'upload'">
                        <div class="panel-heading">
                            <h3 class="panel-title">
                                <i class="fa fa-upload"></i> Upload New File
                            </h3>
                        </div>
                        <div class="panel-body">
                            <div v-show="!show_raster_warning && !file_to_upload">
                                <div class="upload-file-types">Upload one of the following file formats: eps, ai, pdf, psd, tif, bmp, png, jpg, gif, heif or avif</div>
                                <div class="drop-image-container" @drop.prevent="fileDrop($event)" @dragover.prevent="">
                                    <i class="fa fa-image"></i>
                                    <p> Drag files here to upload <br> or </p>
                                    <div class="form-group" v-if="!upload" id="user-file-input">
                                        <input class="btn-ghost" id="file-upload" type="file" @change="fileDrop($event)" ref="file_to_upload">
                                        <label for="file-upload">Choose file</label>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-show="show_raster_warning && !uploading">
                                <div class="yield-error clearfix raster-warning-box text-center">
                                    <h3><b>You have uploaded a <i>raster file</i>.</b></h3>
                                    <div>
                                        This sticker typically needs a vector file to produce. If you have a vector file (EPS, AI, PDF) 
                                        please upload it. If not, you may be contacted later to fix your graphic or change the sticker type.
                                    </div>
                                    
                                    <div class="col-xs-12">
                                        <table class="table" v-show="show_raster_warning">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Raster Files</th>
                                                    <th class="text-center">Vector Files</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        These files are made up of a grid of colors called pixels or dots.
                                                        The design can become blurry when enlarged too much. 
                                                        Common formats are JPEG, PNG and GIF.
                                                    </td>
                                                    <td>
                                                        These files are made up of lines, shapes and curves. 
                                                        The design can be scaled to any size without loss in quality.
                                                        Common formats are PDF, EPS, and AI.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="raster-warning-file-upload-container">
                                    <h3>Would you like to upload a new file?</h3>
                                    
                                    <div class="flex-group upload-new-files-container">
                                        <button class="btn btn-ghost full-width" @click="show_raster_warning = false; clearErrors(); file_to_upload = null">
                                            Yes, Upload New File
                                        </button>
                                        <b style="margin: 15px; text-transform: uppercase; color: black">Or</b>
                                        <button class="btn btn-ghost inverted full-width" @click="uploadFile(true)">
                                            No, Use Current File
                                        </button>
                                    </div>
                                    
                                    <div class="selected-file-name" v-if="file_to_upload">
                                        File Selected: <strong>{{ file_to_upload.name }}</strong>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-if="file_to_upload && (uploading || productService.saving)">
                                <div class="form-group email-file-attachment"> 
                                    {{ file_to_upload.name }} 
                                    <p class="note block">Do not navigate away from this page or close your browser. Your file is being processed.</p>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" :aria-valuenow="percent_loaded" aria-valuemin="0" aria-valuemax="100" :style="{'width': percent_loaded + '%'}">
                                        {{ $filters.floatformat(percent_loaded) }}%
                                    </div>
                                </div>
                                <h4>Processing graphic file - Please do not close this window</h4>
                            </div>
                           
                            <error-list field="file" :errors="errors"></error-list>
                        </div>
                    </div>
                    
                    <!-- Select a already uploaded file -->
                    <div class="panel panel-primary row" v-if="method == 'my-files'">
                        <div class="panel-heading">
                            <h3 class="panel-title"><i class="fa fa-file"></i> My Files</h3>
                        </div>
                        <div class="panel-body">
                            <div class="input-group col-xs-12 user-file-search-area">
                                <input type="text" class="form-control search-input" v-model="search_param" @keydown.enter="getFiles()">
                                <a @click="getFiles()" class="input-group-addon"><i class="fa fa-search"></i> Search</a>
                            </div>
                            <error-list field="file" :errors="errors"></error-list>
                            <ul class="item-list col-xs-12 no-padd">
                                <li v-for="file in user_files.items" class="col-xs-12 col-sm-6 col-md-4 no-padd-xs">
                                    <div class="user-file-container">
                                        <i class="fa fa-times" @click="removeUserFile(file)"></i>
                                        <div class="user-uploads-img-container">
                                            <img :src="file.preview" @click="selectUserFile(file)"/>
                                        </div>
                                        <div class="user-file-action-area">
                                            <span class="block">{{ file.file_name }}</span>
                                            <a class="btn btn-ghost" @click="selectUserFile(file, $event)">Use This File</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <callback-paginator :total="user_files.totalCount" :limit="limit" v-model:page="page" :callback="userFilesCallback"></callback-paginator>
                        </div>
                    </div>
                    
                    <!-- Select a processed file -->
                    <div class="panel panel-primary row" v-if="method=='my-orders'">
                        <div class="panel-heading">
                            <h3 class="panel-title"><i class="fa fa-repeat"></i> Reorder</h3>
                        </div>
                        <div class="panel-body">
                            <div class="input-group col-xs-12 user-file-search-area">
                                <input type="text" class="form-control search-input ng-pristine ng-untouched ng-valid ng-empty" v-model="search_param_reorder" @keydown.enter="getFiles()">
                                <a @click="getReorderFiles()" class="input-group-addon"><i class="fa fa-search"></i> Search</a>
                            </div>
                            <ul class="item-list reorder-list col-xs-12 no-padd">
                                <li v-for="file in processed_files.items" class="col-xs-12 col-sm-6 col-md-4 no-padd-xs">
                                    <div class="reorder-tile user-file-container">
                                        <div class="user-uploads-img-container">
                                            <img :src="file.raster" @click="reorder(file, $event)"/>
                                        </div>
                                        <div class="reorder-details user-file-action-area">
                                            <div class="reorder-detail">
                                                <b>Name:</b>
                                                <span class="block">{{ file.name }}</span>
                                            </div>
                                            <div class="reorder-detail" v-show="file.height && file.width">
                                                <b>Size:</b>
                                                <span class="block">{{ file.width }} x {{ file.height }}</span>
                                            </div>
                                            <div class="reorder-detail" v-if="file.products && file.products.length > 0">
                                                <b>Material:</b>
                                                <span>{{ file.products[0].material }}</span>
                                            </div>
                                            <error-list :errors="file.errors" field="reorder"></error-list>
                                            <a class="btn btn-ghost" @click="reorder(file, $event)">Select</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <callback-paginator :total="processed_files.totalCount" :limit="limit" v-model:page="page" :callback="reordersCallback"></callback-paginator>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Warnings -->
            <div class="modal-body clearfix upload-size-dpi-warning" v-if="method == 'size-dpi-checks'">
                <div class="col-xs-12 setup-error-box-yield size-warning-box" v-if="show_size_fit"> 
                    <h2>Sticker Size</h2>
                    <div>
                        The actual size of your design is {{ $filters.floatformat(recommended_width) }}{{ ups.size_notation }} x {{ $filters.floatformat(recommended_height) }}{{ ups.size_notation }}. You can adjust your size below.
                    </div>
                    <div class="setup-upload-size-inputs">
                        <template-setup-options-size id="template-setup-options-size" class="col-xs-12 no-padd" :product-service="productService"></template-setup-options-size>
                    </div>
                    <div class="col-xs-12 no-padd">
                        <h2>Special Instructions</h2> 
                        <textarea v-model="productService.configured.instructions" class="form-control" name="textarea" rows="5" placeholder="Enter changes, corrections, or special instructions"></textarea> 
                    </div>
                </div>
                
                <div class="col-xs-12 setup-error-box dpi-warning-box" v-if="productService.base.option_vectorize && showDPI">
                    <h2>Image Quality</h2> 
                    <error-list field="lowquality" :errors="errors"></error-list>
                    <div class="form-group">
                        <span v-show="productService.base.type == ${ProductTypeEnum.PRINTED}">
                            Your graphic is lower than 300 dpi and may look blurry at the selected size.
                        </span>
                        <span v-show="productService.base.type != ${ProductTypeEnum.PRINTED}">
                            Your graphic is lower than 300 dpi and we cannot make a transfer sticker with a low quality print.
                        </span>
                    </div>
                    
                    <div class="dpi-bar-container form-group">
                        <div class="col-xs-6 quality-bar no-padd">
                            <div class="quality-bar-text" :style="{'color': imageQualityText}">{{ imageQualityDescription }}</div>
                            <div class="quality-bar-fill" :style="{'background-color': imageQualityColor, 'width' : widthPercentage}"></div>
                        </div>
                        <div class="out-of">{{ dpi }}/300 dpi</div>
                    </div>
                    
                    <div v-if="!productService.base.disable_raster_warnings && productService.base.type == ${ProductTypeEnum.PRINTED}">
                        <span>
                            Optionally, we will fix your graphic for a extra charge or you can approve a low quality graphic.
                        </span>
                        <div class="option-group">
                            <div class="options" v-for="item in productService.configured.vectorizationOptions"> 
                                <input :id="'vectorize-' + item.id" type="radio" name="vectorizeOption" :value="item.id" v-model="productService.configured.option_vectorize" /> 
                                <label :for="'vectorize-' + item.id" >{{ item.label }}</label> <span class="charge-fee" v-if="item.id">+{{ $filters.currency(productService.configured.optionVectorizePrice, productService.configured.currency) }}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="!productService.base.disable_raster_warnings && productService.base.type != ${ProductTypeEnum.PRINTED}">
                        <span>
                            Optionally, we will fix your graphic for an extra charge, or you can upload a higher quality image.
                        </span>
                        <div class="option-group">
                            <div class="options"> 
                                <input id="vectorize-transfer" type="checkbox" name="vectorizeOption" :value="false" v-model="productService.configured.option_vectorize" /> 
                                <label for="vectorize-transfer">Fix My Graphic</label> <span class="charge-fee">+{{ $filters.currency(productService.configured.optionVectorizePrice, productService.configured.currency) }}</span>  
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="productService.base.disable_raster_warnings">
                        <span>
                            You may be contacted by customer service if this graphic is too low quality to print.
                        </span>
                    </div>
                </div>
                
                <div class="corrections-footer">
                    <div class="col-xs-12 col-sm-6 no-padd price-group price-group-half">
                        <div v-if="productService.configured.quantity > 1 && !productService.maxReached">
                            <b>Unit Price:</b>
                            <span class="unit-price">
                                {{ $filters.currency(productService.price.unitPrice, productService.configured.currency) }} Each
                            </span>
                        </div>
                        <div>
                            <b>Total:</b>
                            <span class="price-text">
                                {{ $filters.currency(productService.price.price + productService.configured.calcOptionsPrice(productService.price.price, productService.show_retail), productService.configured.currency) }}
                            </span> 
                        </div>
                    </div>
                    
                    <div class="col-xs-12 col-sm-6 no-padd">
                        <a class="pull-right btn-ghost inverted" :class="{'has-errors':productService.hasErrors}" @click="validateDPI($event)" :disabled="productService.saving">
                            <span v-show="!productService.saving">Continue</span>
                            <span v-show="productService.saving">Saving...</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
`;
