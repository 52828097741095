import {field, link} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {Model} from '../../../core/models/Model';
import {Price} from '../../../core/utils/Price';
import {ShippingMethod} from '../../delivery/models/ShippingMethod';
import {PurchaseOrderFile} from '../../purchase_order/models/PurchaseOrderFile';
import {UserAddress} from '../../userprofile/models/UserAddress';
import {OrderStatus} from './OrderStatus';
import {APIResource} from '../../../core/models/APIResource';
import APIResponse from '../../../core/models/APIResponse';
import {OrderedItem} from './OrderedItem';
import {OrderHistoryEntry} from './OrderHistoryEntry';
import {Services} from '../../../core/services/Services';
import {Http} from '../../../core/services/Http';

export enum OrderPaymentState {
    UNPAID = 0,
    PAID = 1,
    PARTIALLY_PAID = 2,
    OVERPAID = 3,
    PAYMENT_PENDING = 4
}

export enum OrderColorState {
    WARNING = '#baa601',
    ERROR = '#ba0000',
    GOOD = '#007322'
}

export class Order extends Model {
    static override uri: string = '/order/api/user/order/:id/';
    static override objects: APIResource<Order>;

    @field()
    prefixed_id: string;

    @field(ForeignKeyField, {
        model: 'UserAddress'
    })
    shipping_address: UserAddress;

    @field(ForeignKeyField, {
        model: 'UserAddress'
    })
    billing_address: UserAddress;

    @field()
    date_created: string;

    @field()
    last_status_change: string;

    @field()
    line_item_number_last: number;

    @field()
    payment_state: OrderPaymentState;

    @field()
    payment_state_display: string;

    @field(ForeignKeyField, {
        model: 'ShippingMethod'
    })
    shipping_method: ShippingMethod;

    @field()
    shipping_method_display: string;

    @field(ForeignKeyField, {
        model: 'OrderStatus'
    })
    status: OrderStatus;

    @field()
    currency: string;

    @field()
    total_discounts: number;

    @field()
    total_gross: number;

    @field()
    total_net: number;

    @field()
    total_tax: number;

    @field()
    total_shipping: number;

    @field()
    total_rush: number;

    @field()
    is_rush: boolean;

    @field()
    delivery_date: string;

    @field()
    ship_date: string;

    @field()
    total_balance: number;

    @field()
    total_payments: number;

    @field()
    total_refunds: number;

    @field()
    is_redo: boolean;

    @field()
    was_redone: boolean;

    @field()
    comments: string;

    @field()
    discounts: any;

    @field()
    instore_credit_total: number;

    @field()
    adjustment_account_payments: any;

    @field()
    redo: any;

    @field()
    share_approval: any;

    @field()
    blind_ship: boolean;
  
    @field()
    purchase_order_number: boolean;

    @field(ForeignKeyField, {
        model: 'PurchaseOrderFile'
    })
    purchase_order_file: PurchaseOrderFile;

    @field()
    order_name: string;

    @field()
    email: string;

    @field()
    total_without_discounts: number;

    @field()
    quote_ship: boolean;

    @link('OrderedItem', 'order', 'multiple')
    ordered_items: APIResponse<OrderedItem>;

    @link('OrderHistoryEntry', 'order')
    history: APIResponse<OrderHistoryEntry>;

    @field()
    reviewed: boolean;

    @field()
    invoice_download: string;

    @field()
    contact_email: string;

    @field()
    cell_number: string;

    @field()
    twitter: string;

    @field()
    instagram: string;

    @field()
    facebook: string;

    @field()
    tiktok: string;

    get sub_total() {
        const net = new Price(this.total_net)
        const discounts = new Price(this.total_discounts);
        const shipping = new Price(this.total_shipping)
        const rush = this.is_rush ? new Price(this.total_rush) : Price.zero;

        return net.add(discounts).add(shipping).add(rush).value;
    }

    get order_total() {
        const net = new Price(this.total_net)
        const shipping = new Price(this.total_shipping)
        const rush = this.is_rush ? new Price(this.total_rush) : Price.zero;

        return net.add(shipping).add(rush).value;
    }

    get paymentStateIcon() {
        if (this.payment_state === OrderPaymentState.PAID) {
            return 'fa-money-bill';
        } else if (this.payment_state === OrderPaymentState.UNPAID) {
            return 'fa-times';
        } else if (this.payment_state === OrderPaymentState.PARTIALLY_PAID) {
            return 'fa-dot-circle-o';
        } else if (this.payment_state === OrderPaymentState.OVERPAID) {
            return 'fa-balance-scale-right';
        }
    }

    paymentStateColor() {
        if (this.payment_state === OrderPaymentState.PAID) {
            return OrderColorState.GOOD;
        } else if (this.payment_state === OrderPaymentState.UNPAID) {
            return OrderColorState.ERROR;
        } else if (this.payment_state === OrderPaymentState.PARTIALLY_PAID) {
            return OrderColorState.WARNING;
        } else if (this.payment_state === OrderPaymentState.OVERPAID) {
            return OrderColorState.WARNING;
        }
    }

    get isComplete() {
        if (!this.status) {
            return false;
        }

        return this.status.isComplete;
    }

    get shipping_with_rush() {
        if (this.is_rush) {
            return parseFloat(`${this.total_shipping}`) + parseFloat(`${this.total_rush}`);
        }

        return parseFloat(`${this.total_shipping}`);
    }

    getDaysOverdue() {
        if(!this.isComplete) {
            return 'N/A'
        }

        if(this.payment_state === OrderPaymentState.PAID || this.payment_state === OrderPaymentState.OVERPAID) {
            return 'PAID'
        }

        let start = new Date(this.last_status_change);
        let end = new Date();

        return Math.floor((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
    }

    updateShareStatus() {
        return Services.get<Http>('$http').request({
            url: '/order/api/user/update-share-status/',
            method: 'POST',
            data: {
                order: this.id,
                share: this.share_approval
            }
        });
    }

    get has_vector(): boolean {
        return !!this.ordered_items.items.find(v => v.option_vectorize);
    }
}
