import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './CustomQRCode.html';
import Company from '../../../../core/services/Company';
import {Services} from '../../../../core/services/Services';
import APIResponse from '../../../../core/models/APIResponse';
import {UsesLink} from '../../../category/models/UsesLink';
import MessageList from '../../../../core/utils/MessageList';
import {QRCode, QRCodeType} from '../../models/QRCode';

class CustomQRCodeController extends VueComponent {

    @prop()
    uses: string;

    @data()
    qr_data: any;

    @data()
    company: Company;

    @data()
    products: APIResponse<UsesLink>;

    @data()
    step: number;

    @data()
    errors: MessageList;

    constructor(component) {
        super(component);

        this.qr_data = Object.create(null);
        this.company = Services.get<Company>('Company');
        this.errors = new MessageList();
        this.step = 1;
    }

    override mounted() {
        super.mounted();

        this.products = Services.get<typeof UsesLink>('UsesLink').objects.filter({
            category__slug: this.uses
        });
    }

    @method()
    pickProduct() {
        this.errors = new MessageList();

        if (!this.qr_data.custom || this.qr_data.custom.trim() == '') {
            this.errors.add('custom', 'This field is required.');
        }

        if (this.errors.length != 0) {
            return;
        }

        this.step = 2;
    }

    @method()
    async setupProduct(product: UsesLink, $event) {
        this.step = 3;

        const data_json = JSON.stringify(this.qr_data);

        let code = new (Services.get<typeof QRCode>('QRCode'))({
            data_json: data_json,
            qrcode_type: QRCodeType.CUSTOM
        });

        code.save();

        code.$promise.catch(() => {
            this.step = 2;
        });

        await code.$promise;

        window.location.href = `${product.url}?qr_id=${code.id}`;
    }
}

export default function CustomQRCode(): IVueComponent {
    return {
        tag: 'custom-qr-code',
        controller: CustomQRCodeController,
        template: html
    }
}
