import {html} from './AddToCart.html';
import DragDropBuyService from '../../services/DragDropBuyService';
import VueComponent, {data} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';

export class DragDropBuyAddToCartController extends VueComponent{
    static override $inject = [
        'DragDropBuyService'
    ];

    @data()
    product_service: DragDropBuyService

    constructor(component, private productService: DragDropBuyService) {
        super(component)
        this.product_service = productService
    }
}

export default function DragDropBuyAddToCart(): IVueComponent {
    return {
        controller: DragDropBuyAddToCartController,
        template: html,
        tag: 'drag-drop-buy-add-to-cart'
    }
};
