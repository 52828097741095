import VueComponent, {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import APIResponse from '../../../../core/models/APIResponse';
import {Clipart, EClipartColorChoiceEnum} from '../../models/Clipart';
import CustomEditorService from '../../services/CustomEditorService';
import Company from '../../../../core/services/Company';
import {Http} from '../../../../core/services/Http';
import {ClipartCategory} from '../../models/ClipartCategory';
import {ProductTypeEnum} from '../../models/enums';
import {ClipartCategoryGraphic} from '../../models/ClipartCategoryGraphic';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './AddClipartComponent.html';
import {Services} from '../../../../core/services/Services';


class AddClipartComponentController extends VueComponent {
    static override $inject = [
        '$http',
        'Company'
    ];

    @prop()
    productService: CustomEditorService;

    @data()
    company: Company;

    @data()
    search_query: string;

    @data()
    searched_query: string;

    @data()
    shape_categories: ClipartCategory[];

    @data()
    clipart: APIResponse<ClipartCategoryGraphic>;

    @data()
    limit: number;

    @data()
    page : number;

    @data()
    category: ClipartCategory;

    @data()
    category_breadcrumbs: any[];

    @data()
    page_change_callback;

    @data()
    searching: boolean;

    protected $http: Http;
    private searched: boolean;
    private error: boolean;

    constructor(component, $http: Http, company: Company) {
        super(component);

        this.$http = $http;
        this.company = company;
        this.page_change_callback = this.paginationFunction.bind(this);

        this.searched = false;
        this.error = false;

        this.search_query = '';
        this.searched_query = '';
        this.shape_categories = [];
        this.category_breadcrumbs = [];

        this.productService.setup_complete.then(() => {
            this.productService.clipart_categories.$promise.then(() => {
                for (const category of this.productService.clipart_categories.items) {
                    if (category.shapes) {
                        this.shape_categories.push(category);
                    }
                }
            });
        });

        this.page = 1;
        this.limit = 20;

        this.clipart = null;
        this.reset();
    }

    @method()
    search(page?): void {
        this.page = page || this.page || 1;
        this.limit = this.limit || 20;
        this.search_query = this.search_query || '';
        this.searched_query = this.search_query;
        this.searching = true;

        const q = {
            query: this.search_query,
            limit: this.limit,
            offset: this.limit * (this.page - 1)
        };

        if (this.category) {
            q['search_category'] = this.category.id;
        }

        if (this.productService.base.type === ProductTypeEnum.CUTOUT) {
            q['colors'] = EClipartColorChoiceEnum.SINGLE_COLOR;
        }

        q['show_licensed'] = this.productService.configured.marketplace_elements.length == 0;

        if (window.location.href.indexOf('/dashboard/') != -1) {
            q['show_licensed'] = false;
        }

        this.error = false;
        this.clipart = null;
        this.$http.request({
            url: '/custom/api/v1/clipart-category-graphic-search/',
            method: 'GET',
            params: q
        }).then((response) => {
            this.clipart = Services.get<typeof ClipartCategoryGraphic>('ClipartCategoryGraphic').objects.from(response.data.data);
            this.searching = false;
        }, (err) => {
            this.searching = false;
            return err;
        });

        this.searched = true;
    }

    @method()
    addClipart(clipart): void {
        if (clipart.graphic.resource_uri.indexOf('/marketplace/api/v1/artwork/') != -1) {
            this.productService.createMarketplaceElement({
                name: clipart.name,
                artwork: clipart.graphic,
                selectable: true,
                maintain_proportion: true
            });
        }
        else {
            this.productService.createClipartElement({
                name: clipart.name,
                clipart: clipart.graphic,
                selectable: true,
                maintain_proportion: true
            });
        }
    }

    @method()
    reset() {
        this.search_query = '';
        this.searched_query = '';
        this.clipart = null;
        this.category = null;
        this.page = 1;
    }

    paginationFunction(page){
        this.page = page;
        this.search();
        this.updateCategoryBreadcrumbs();
    }

    @method()
    onCategoryChange() {
        this.updateCategoryBreadcrumbs();
        if (this.subCategories(this.category).length == 0) {
            this.search();
        }
    }

    @method()
    selectCategory(category) {
        this.category = category;
        this.page = 1;

        this.onCategoryChange();
    }

    @method()
    removeCategory() {
        this.category = null;
        this.search();
        this.updateCategoryBreadcrumbs();
    }

    public updateCategoryBreadcrumbs() {
        this.category_breadcrumbs = [];

        if (!this.category) {
            return;
        }

        let current_category = this.category;
        while (current_category && current_category.parent != null) {
            this.category_breadcrumbs.push(current_category);

            current_category = this.productService.clipart_categories.items.getItemFromURI(current_category.parent);

            if (this.category_breadcrumbs.indexOf(current_category) != -1) {
                this.category_breadcrumbs.reverse();
                return;
            }
        }

        this.category_breadcrumbs.push(current_category);
        this.category_breadcrumbs.reverse();
    }

    @method()
    subCategories(parent) {
        let items = [];
        let uri_check = null;

        if (!this.productService.clipart_categories) {
            return items;
        }

        if (parent) {
            uri_check = parent.buildResourceURI();
        }

        for (const item of this.productService.clipart_categories.items) {
            if (item.parent == uri_check) {
                items.push(item);
            }
        }

        return items;
    }
}

export default function AddClipartComponent(): IVueComponent {

    return {
        controller: AddClipartComponentController,
        template: html,
        tag: 'add-clipart-component'
    };
}
