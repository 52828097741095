import {html} from './Options.html';
import VueComponent, {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import {TemplateOptionSet} from '../../models/TemplateOptionSet';
import {CustomOption} from '../../models/CustomOption';
import {TemplateOption, TemplateOptionChargeOption} from '../../models/TemplateOption';
import VueApp from '../../../../core/adapters/VueApp';
import ConfiguredProductServiceAbstract from '../../../products_shared/services/ConfiguredProductServiceAbstract';

export class TemplateFinalizeOptionsController extends VueComponent {
    @data()
    infoTitle;

    @data()
    showInfoBool;

    @data()
    optionItemNameCallback;

    @data()
    show_border_description: boolean;

    @data()
    show_lamination_description: boolean;

    @prop()
    productService: ConfiguredProductServiceAbstract;

    @prop()
    priceQuote;

    @prop()
    readOnly;

    @prop()
    hideRetailPackaging: boolean;

    @prop()
    reverseCut: boolean;

    @method()
    getOptionName(templateOptionSet, index, customService){
        if (customService.custom.options && customService.custom.options[index] && customService.custom.options[index].option) {
            if (customService.custom.options[index].option.name == undefined) {
                customService.custom.options[index].option = templateOptionSet.options[0];
            }

            return templateOptionSet.name;
        }

        return null;
    }

    @method()
    getOptionItemNameCallback(customService, option_set: TemplateOptionSet) {
        // Curry the custom service object because we won't have access to it in the dropdown
        return (item) => {
            return this.getOptionItemName(customService, option_set, item);
        }
    }

    @method()
    getOptionItemName(customService, option_set: TemplateOptionSet, item: TemplateOption) {
        if (item) {
            if (item.charge_option == TemplateOptionChargeOption.CHARGE_PERCENTAGE && item.percent == 0) {
                return item.name;
            }
            if (item.charge_option != TemplateOptionChargeOption.CHARGE_PERCENTAGE && item.amount == 0) {
                return item.name;
            }

            if (item.charge_option === TemplateOptionChargeOption.CHARGE_UNIT) {
                let formatted = VueApp.GLOBAL_FILTERS.currency(item.display_value(customService.show_retail, option_set.quantityBreakIndex(customService.quantity)), this.productService.configured.currency);
                return item.name + "   (+" + formatted + " each)";
            }
            else if (item.charge_option === TemplateOptionChargeOption.CHARGE_TOTAL) {
                let formatted = VueApp.GLOBAL_FILTERS.currency(item.display_value(customService.show_retail, option_set.quantityBreakIndex(customService.quantity)), this.productService.configured.currency);
                return item.name + "   (+" + formatted + ")";
            }
            else if (item.charge_option === TemplateOptionChargeOption.CHARGE_PERCENTAGE){
                return item.name + "   (+" +  Number(item.display_value(true, option_set.quantityBreakIndex(customService.quantity))).toString() + "%)";
            }
        }

        return item.name
    }

    @computed()
    hasOptions() {
        let laminate = this.productService.base['option_laminate'] && this.productService.configured.material.can_laminate;
        let border = this.productService.base['option_border'];
        let options = this.productService.configured.options.length > 0;

        return laminate || border || options;
    }

    @method()
    idForOptionSet(custom_option, item) {
        return `${custom_option.option_set.name}-${item.id}`
    }

    @method()
    toggleOption(custom_option: CustomOption, item: TemplateOption) {
        if (custom_option.option == item) {
            custom_option.option = null;
        }
        else {
            custom_option.option = item;
        }

        custom_option.errors.reset();
    }
}

export default function TemplateFinalizeOptions() {
    return {
        controller: TemplateFinalizeOptionsController,
        template: html,
        tag: 'template-finalize-options'
    }
}
