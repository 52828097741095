export const html:string = `
<div class="tool-tab">
    <label><i class="fa fa-font"></i> Edit Custom Text</label>
    <error-list :errors="element.model.errors" field="size"></error-list>
    <variable-data-selector v-model:element="element" :product-service="productService"></variable-data-selector>
    <div class="right-aligned-options-area">
        <div class="tool-selection-set" v-show="!elementIsRange()">
            <div class="left-side expanding-item">
                <span>{{ textTypeDisplay() }}</span>
            </div>
            <div class="right-side">
                <textarea v-model="element.model.text"></textarea>
            </div>
        </div>
        <error-list field="range" :errors="element.model.errors"></error-list>
        <div class="tool-selection-set" v-show="elementIsRange()">
            <div class="left-side expanding-item">
                <span>Number Range Start</span>
            </div>
            <div class="right-side">
                <textarea v-model="element.model.symbol_range_start"></textarea>
            </div>
        </div>
        <div class="tool-selection-set" v-show="elementIsRange()">
            <div class="left-side expanding-item">
                <span>Number Range End</span>
            </div>
            <div class="right-side">
                <textarea v-model="element.model.symbol_range_end"></textarea>
                <span class="secondary-info">Quantity: {{ element.model.range ? element.model.range : 'Not a number range' }}</span>
            </div>
        </div>
        <div class="tool-selection-set" v-show="element.model.multipleRangeSegments()">
            <ul class="error-list warning">
                <li class="errors">
                    Multiple numbers detected. Please verify the quantity.
                </li>
            </ul>
        </div>
        <div class="tool-selection-set">
            <div class="left-side">
                <span>Font</span>
            </div>
            <div class="right-side">
                <generic-image-dropdown :items="productService.font_service.fonts.items" v-model:selected="element.model.font"></generic-image-dropdown>
            </div>
        </div>
        <div class="tool-selection-set">
            <div class="left-side">
                <span>Text Color</span>
            </div>
            <div class="right-side color-picker-set" v-if="!isSingleColor()">
                <generic-color-dropdown :items="productService.colors_available" v-model:selected="element.model.color" none="Custom Color" :none-color="element.model.custom_color" :hide-none="true"></generic-color-dropdown>
                <color-picker v-model:color="element.model.custom_color"></color-picker>
            </div>
            <div class="right-side" v-if="isSingleColor()" v-cloak>
                <generic-color-dropdown :items="productService.colors_available" v-model:selected="productService.configured.material_color"></generic-color-dropdown>
            </div>
        </div>
    </div>
    <div class="important-options-area">
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.centerVertically()">
                <img src="/static/img/vector-canvas/center-horizontally.svg">
            </button>
            <button class="btn btn-light-tool" @click="element.centerHorizontally()">
                <img src="/static/img/vector-canvas/center-vertically.svg">
            </button>
            <span>Alignment</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool btn-single" @click="scaleSize(0.05, $event)">
                <i class="fa fa-plus-circle"></i>
            </button>
            <button class="btn btn-light-tool btn-single" @click="scaleSize(-0.05, $event)">
                <i class="fa fa-minus-circle"></i>
            </button>
            <span>Text Size</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="moveElementUp($event)">
                <img src="/static/img/vector-canvas/layer-up-icon.svg">
            </button>
            <button class="btn btn-light-tool" @click="moveElementDown($event)">
                <img src="/static/img/vector-canvas/layer-down-icon.svg">
            </button>
            <span>Layering</span>
        </div>
        <!-- advanced -->
        <div class="tool-action-item" v-show="in_advanced">
            <generic-dropdown :items="productService.text_paths.items" v-model:selected="element.model.path" none="Straight"></generic-dropdown>
            <span>Text Arc</span>
        </div>
        <div class="tool-action-item" v-show="in_advanced && !element.model.path">
            <button class="btn btn-light-tool" @click="toggleFontScaling($event)">
                <span v-show="!element.model.maintain_proportion"><i class="fa fa-unlock"></i> Unlocked</span>
                <span v-show="element.model.maintain_proportion"><i class="fa fa-lock"></i> Locked</span>
            </button>
            <span>Proportions</span>
        </div>
        <div class="tool-action-item" v-show="in_advanced && element.model.path">
            <button class="btn btn-light-tool btn-single" @click="element.model.path_scale = element.model.path_scale * 1.05">
                <i class="fa fa-plus-circle"></i>
            </button>
            <button class="btn btn-light-tool btn-single" @click="element.model.path_scale = element.model.path_scale * 0.95">
                <i class="fa fa-minus-circle"></i>
            </button>
            <span>Arc Size</span>
        </div>
        <div class="tool-action-item" v-show="in_advanced">
            <button class="btn btn-light-tool btn-light-thinner" @click="element.model.text_alignment = 0" :class="{'active-state': element.model.text_alignment == 0}">
                <i class="fa fa-align-left"></i>
            </button>
            <button class="btn btn-light-tool btn-light-thinner" @click="element.model.text_alignment = 1" :class="{'active-state': element.model.text_alignment == 1}">
                <i class="fa fa-align-center"></i>
            </button>
            <button class="btn btn-light-tool btn-light-thinner" @click="element.model.text_alignment = 2" :class="{'active-state': element.model.text_alignment == 2}">
                <i class="fa fa-align-right"></i>
            </button>
    
            <span>Text Alignment</span>
        </div>
        <hr v-show="in_advanced || element.model.strokeColor" v-if="!isSingleColor() && !element.model.definesQuantity">
        <div v-if="!productService.base.disable_stroke && !element.model.definesQuantity">
            <button class="btn btn-light-tool btn-full" v-if="!isSingleColor()" v-show="in_advanced && !show_stroke && !element.model.strokeColor" @click="show_stroke = true">
                <span>
                    <i class="fa fa-plus"></i>
                    Add Stroke Outline To Text
                </span>
            </button>
            <div class="tool-action-item half color-picker-set" v-show="show_stroke || element.model.strokeColor">
                <generic-color-dropdown :items="productService.colors_available" v-model:selected="element.model.strokeColor" none="Custom Color" :none-color="element.model.custom_stroke_color" :hide-none="true"></generic-color-dropdown>
                <color-picker side="bottom" v-model:color="element.model.custom_stroke_color"></color-picker>
                <span>Stroke Color</span>
            </div>
            <div class="tool-action-item stroke-size" v-show="show_stroke || element.model.strokeColor">
                <button class="btn btn-light-tool btn-single" @click="changeStrokeWidth(element.model, 1, $event)">
                    <i class="fa fa-plus-circle"></i>
                </button>
                <button class="btn btn-light-tool btn-single" @click="changeStrokeWidth(element.model, -1, $event)">
                    <i class="fa fa-minus-circle"></i>
                </button>
                <span>Stroke Size</span>
            </div>
            <div class="tool-action-item remove-stroke" v-show="show_stroke || element.model.strokeColor">
                <button class="btn btn-light-tool" @click="element.model.removeStroke()">
                    <i class="fa fa-times"></i>
                </button>
                <span>Remove</span>
            </div>
        </div>
    </div>
    <span class="show-advanced-options subtle-action" v-show="!in_advanced" @click="in_advanced = true">
        <i class="fa fa-plus"></i>
        Show Advanced Options
    </span>
    <div class="right-aligned-options-area margin-set" v-if="element.can_offset_path">
        <b>Cutpath Options</b>
        <div class="tool-selection-set" v-show="element.offset_path_enabled">
            <div class="left-side">
                <span>Border Width</span>
            </div>
            <div class="right-side">
                <div class="col-xs-6 col-full-left">
                    <input type="range" min="7" max="30" v-model="element.model.offset_width" @change="element.queueOffsetPath()">
                </div>
                <div class="col-xs-6 input-button-group">
                    <input type="number" min="7" max="30" v-model="element.model.offset_width" @change="element.queueOffsetPath()">
                    <button class="btn btn-light-tool" @click="element.removeOffsetPath()"><i class="fa fa-times"></i></button>
                </div>
            </div>
        </div>
        <div class="tool-selection-set" v-show="element.offset_path_enabled && !productService.base.offset_path_uses_material_color">
            <div class="left-side">
                <span>Border Color</span>
            </div>
            <div class="right-side color-picker-set">
                <generic-color-dropdown :items="productService.colors_available" v-model:selected="productService.configured.offset_path_fill_color" 
                    none="Custom Color" :none-color="productService.configured.offset_path_custom_fill_color" :hide-none="true" :trigger-function="on_path_color_change">    
                </generic-color-dropdown>
                
                <color-picker v-model:color="productService.configured.offset_path_custom_fill_color" :change="on_path_color_change"></color-picker>
            </div>
        </div>
        <div class="right-side" v-show="!element.offset_path_enabled">
            <button class="btn btn-light-tool" @click="element.addOffsetPath()">Add Border</button>
        </div>
    </div>
    <slot></slot>
</div>
`;
