import {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import {Http} from '../../../../core/services/Http';
import {UserFile} from '../../models/UserFile';
import {ProcessedFile} from '../../../processing/models/ProcessedFile';
import UserProfileService from '../../../userprofile/services/UserProfileService';
import APIResponse from '../../../../core/models/APIResponse';
import CustomService from '../../services/CustomService';
import {ProductTypeEnum, ToolTypeEnum} from '../../models/enums';
import {ModalController} from '../../../../core/adapters/ModalComponent';
import MessageList from '../../../../core/utils/MessageList';
import {OrderColorState} from '../../../order/models/Order';
import {eventToFiles, fitToArea, getClosestQuarter} from '../../../../core/utils/utils';
import ButtonProcessingState from '../../../../core/utils/ButtonProcessingState';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './SetupUpload.html';
import {FileInputRef} from '../../../../core/utils/Refs';

class VectorCanvasModalsSetupUploadController extends ModalController {
    static override $inject = [
        '$http',
        'UserFile',
        'ProcessedFile',
        'UserProfileService'
    ];

    public userFile: typeof UserFile;
    public processedFile: typeof ProcessedFile;
    public $http: Http;

    @data()
    user_files: APIResponse<UserFile>;

    @data()
    processed_files: APIResponse<ProcessedFile>;

    @data()
    ups: UserProfileService;

    @prop()
    productService: CustomService;

    @data()
    errors: MessageList;

    @data()
    method;

    @data()
    show_size_fit: boolean;

    @data()
    showDPI: boolean;

    @data()
    dpi: number;

    @data({
        ref: FileInputRef
    })
    file_to_upload: File;

    @data()
    uploading: boolean;

    @data()
    search_param: string;

    @data()
    search_param_reorder: string;

    @data()
    recommended_height;

    @data()
    recommended_width;

    @data()
    percent_loaded: number;

    @data()
    limit;

    @data()
    page;

    @data()
    show_raster_warning: boolean;

    @data()
    userFilesCallback;

    @data()
    reordersCallback;

    @data()
    sizeOption: any;

    constructor(component, $http: Http, userFile: typeof UserFile, processedFile: typeof ProcessedFile, ups: UserProfileService) {
        super(component, 'vector-canvas-modals-setup-upload-modal');

        this.$http = $http;
        this.userFile = userFile;
        this.processedFile = processedFile;

        // Paginator callback
        this.userFilesCallback = this.getUserFiles.bind(this);
        this.reordersCallback = this.getReorderFiles.bind(this);

        this.ups = ups;

        this.reset();
    }

    override reset() {
        super.reset();

        this.errors = new MessageList();
        this.limit = 6;
        this.method = 'upload';
        this.productService.configured.option_vectorize = false;
        this.page = 1;
        this.search_param = '';
        this.show_raster_warning = false;
        this.file_to_upload = null;
    }

    /*
    get metricNotation() {
        if(this.ups.user) {
            return this.ups.user.getMetricNotation();
        }
        return '"';
    }
     */

    @method()
    fileDrop($event) {
        this.file_to_upload = eventToFiles($event)[0];
        this.uploadFile();
    }

    @method()
    uploadFile(force?) {
        if (force) {
            this.show_raster_warning = false;
        }

        if (!this.file_to_upload) {
            return;
        }

        if (!this.productService.base.disable_raster_warnings) {
            if (!force && ['application/postscript', 'application/pdf'].indexOf(this.file_to_upload.type) == -1 && this.productService.base.option_vectorize) {
                this.show_raster_warning = true;
                return;
            }
        }
        if (this.productService.base.disable_raster_warnings) {
            this.show_raster_warning = false;
        }

        let form = new FormData();
        form.append('file', this.file_to_upload);

        this.uploading = true;

        this.$http.request({
            url: '/custom/api/v1/upload/',
            data: form,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    this.percent_loaded = (progressEvent.loaded / progressEvent.total) * 100;
                }
                else {
                    this.percent_loaded = 0
                }
            }
        }).then(this.success.bind(this), this.failure.bind(this));
    }

    async success(response) {
        const userFile = UserFile.objects.get({id: response.data.user_file});
        await userFile.$promise;

        this.uploading = false;

        this.selectUserFile(userFile);
    }

    failure(error) {
        this.uploading = false;

        if (!error.response) {
            return;
        }

        let response = error.response;

        this.errors = new MessageList();
        if (response.data) {
            if (response.data.errors) {
                this.errors.merge(response.data.errors);
            }
            if (response.data.error) {
                this.errors.add('file', response.data.error);
            }
        }

        this.file_to_upload = null;

        return response;
    }

    @method()
    clearErrors() {
        this.errors = new MessageList();
    }

    @method()
    selectUserFile(userFile, $event?) {
        this.productService.configured.tool_type = ToolTypeEnum.UPLOAD;
        this.productService.configured.user_file = userFile;

        this.imageSizeFit(this.productService.configured.user_file);
        this.passingDPI(this.productService.configured.user_file);

        if ((!this.show_size_fit && !this.showDPI) || !this.productService.base.option_vectorize) {
            this.goToFinalize($event);
        }
        else {
            this.method = 'size-dpi-checks';
        }
    }

    goToFinalize($event?) {
        this.productService.configured.dpi = this.dpi;
        this.productService.configured.tool_type = ToolTypeEnum.UPLOAD;

        if (this.productService.base.auto_background_removal) {
            this.productService.remove_background = true;
        }

        this.productService.save($event);
    }

    @method()
    validateDPI() {
        this.goToFinalize();
    }

    @computed()
    imageQualityColor(): string {
        if (this.dpi < 150) {
            return OrderColorState.ERROR;
        }
        else if (this.dpi < 300) {
            return OrderColorState.WARNING;
        }
        else {
            return OrderColorState.GOOD;
        }
    }

    @computed()
    imageQualityText() {
        if (this.dpi < 150) {
            return 'white';
        }
        return 'black';
    }

    @computed()
    imageQualityDescription(): string {
        if (this.dpi < 150) {
            return 'Bad';
        }
        else if (this.dpi < 300) {
            return 'Okay';
        }
        else {
            return 'Good';
        }
    }

    @computed()
    widthPercentage(): string{
        if (this.dpi > 300)
            return '100%';
        else
            return String((this.dpi / 300) * 100) + '%';
    }

    passingDPI(userFile?: UserFile) {
        if (!userFile){
            userFile = this.productService.configured.user_file;
        }
        if (userFile.is_vector || !userFile.width || !userFile.height) {
            this.dpi = 300;
            this.showDPI = false;
            return true;
        }

        const wdpi = userFile.width / this.productService.width;
        const hdpi = userFile.height / this.productService.height;

        if (wdpi < hdpi) {
            this.dpi = Math.ceil(wdpi);
        }
        else {
            this.dpi = Math.ceil(hdpi);
        }
        this.productService.configured.dpi = this.dpi;
        this.showDPI = this.dpi < 300;
    }

    imageSizeFit(userFile?: UserFile) {
        if (!userFile){
            userFile = (this.productService.configured.user_file as UserFile);
        }

        if (this.productService.base.lock_ratio || !this.productService.base.allow_custom_size){
            this.show_size_fit = false;
            return;
        }

        if (userFile.is_vector || !userFile.height || !userFile.width){
            this.show_size_fit = false;
            return;
        }

        //1% Margin of Difference Before flags as wrong size
        const sizeProp = Number((userFile.width / userFile.height).toFixed(3));
        const error = Number((userFile.width / userFile.height).toFixed(3)) * 0.1;
        const customProp = Number((this.productService.width / this.productService.height).toFixed(3));

        if ((sizeProp + error) >= customProp && (sizeProp - error) <= customProp) {
            this.show_size_fit = false;
            return;
        }

        if (this.productService.width > this.productService.height) {
            this.recommended_height = (Math.round(Number(userFile.height) / Number(userFile.width)) * Number(this.productService.configured.width)).toFixed(3);
            this.recommended_width = this.productService.width;
        }
        else {
            this.recommended_height = this.productService.height;
            this.recommended_width = (Math.round(Number(userFile.width) / Number(userFile.height)) * Number(this.productService.configured.height)).toFixed(3);
        }

        const wh = fitToArea(this.recommended_width, this.recommended_height, this.productService.width * this.productService.height);
        let width, height;

        if (wh.width > wh.height) {
            width = getClosestQuarter(wh.width);
            height = Number((width * (wh.height / wh.width)).toFixed(3));
        }
        else {
            height = getClosestQuarter(wh.height);
            width = Number((height * (wh.width / wh.height)).toFixed(3));
        }

        this.recommended_width = width;
        this.recommended_height = height;

        this.productService.width = this.recommended_width;
        this.productService.height = this.recommended_height;
        this.productService.setLockedProportions(true);

        this.show_size_fit = true;
    }

    @method()
    reorder(file: ProcessedFile, $event): void {
        let processing = new ButtonProcessingState($event);
        processing.process();

        this.$http.request({
            method: 'POST',
            url: '/order/api/user/reorder-product/',
            data: {
                ordered_item_id: file.products[0].ordered_items[0]
            }
        }).then((response: any): void => {
            if (response.data.url) {
                window.location.href = response.data.url;
            }
            processing.resolved();
        }, (error) => {
            processing.resolved();
            file.errors.add('reorder', 'Unable to reorder this product');

            return error;
        });
    }

    @method()
    getFiles(){
        if (this.method == 'my-orders') {
            this.getReorderFiles();
        }
        if (this.method == 'my-files') {
            this.getUserFiles();
        }
    }

    getUserFiles(page?) {
        if (page) {
            this.page = page;
        }
        this.user_files = this.userFile.objects.filter({
            limit: this.limit,
            offset: this.limit * (this.page - 1),
            name__icontains: this.search_param,
            hidden: false
        });
    }

    @method()
    getReorderFiles(page?) {
        if (page) {
            this.page = page;
        }
        this.processed_files = this.processedFile.objects.filter({
            limit: this.limit,
            offset: this.limit * (this.page - 1),
            product: this.productService.base.id,
            search: this.search_param_reorder,
            order_by: '-date_created'
        });
    }

    paginationFunction(){
        this.getFiles();
    }

    override onOpen() {
        this.reset();
        super.onOpen();

        this.getUserFiles();
        this.getReorderFiles();
    }

    override onClose() {
        super.onClose();
        this.reset();
    }

    @method()
    removeUserFile(file: UserFile, $event) {
        file.remove($event).then(() => {
            this.user_files.items.removeItemFromID(file.id);
            this.getUserFiles();
        });
    }
}

export default function VectorCanvasModalsSetupUpload(): IVueComponent {
    return {
        controller: VectorCanvasModalsSetupUploadController,
        template: html,
        tag: 'vector-canvas-modals-setup-upload'
    };
}
