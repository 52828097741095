import {html} from './ClipartSelector.html';
import DragDropBuyService from '../../services/DragDropBuyService';
import VueComponent, {data, method} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';

export class DragDropBuyClipartSelectorController extends VueComponent{
    static override $inject = [
        'DragDropBuyService'
    ];

    @data()
    product_service: DragDropBuyService

    constructor(component, private productService: DragDropBuyService) {
        super(component)
        this.product_service = productService
    }

    @method()
    addClipart(clipart, $event?) {
        if($event)
            $event.preventDefault();

        this.product_service.addElement(clipart);
    }
}

export default function DragDropBuyClipartSelector(): IVueComponent {
    return {
        controller: DragDropBuyClipartSelectorController,
        template: html,
        tag: 'drag-drop-buy-clipart-selector'
    }
};
