import {html} from './StandaloneAddQRCode.html'
import VueComponent, {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import Company from '../../../../core/services/Company';
import {QRCode} from '../../models/QRCode';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {AddQrCodeComponentController} from '../DesignTool/AddQRCode/AddQrCode';
import MessageList from '../../../../core/utils/MessageList';
import {Services} from '../../../../core/services/Services';

class StandaloneAddQRCodeController extends AddQrCodeComponentController {

    code: QRCode;

    constructor(component) {
        super(component);

        this.show_select_product = true;
    }

    @method()
    override async next(type?: string) {
        this.errors = new MessageList();

        if (type == 'url') {
            if (!this.qr_data.url || this.qr_data.url.trim() == '') {
                this.errors.add('url', 'This field is required.')
            }
        }

        if (this.errors.length > 0) {
            return;
        }

        const data_json = JSON.stringify(this.qr_data);

        this.code = new (Services.get<typeof QRCode>('QRCode'))({
            data_json: data_json,
            qrcode_type: this.active - 1
        });

        await this.code.save().catch(() => {
            this.errors.merge(this.code.errors.list);
        });

        if (this.errors.length == 0) {
            this.step = 3;
        }
    }

    @method()
    override async selected(slug) {
        await this.code.$promise;

        window.location.href = `/products/stickers/custom-stickers/qr-code-stickers/personalize/${slug}/?qr_id=${this.code.id}`
    }
}

export default function StandaloneAddQRCode(): IVueComponent {
    return {
        controller: StandaloneAddQRCodeController,
        template: html,
        tag: 'standalone-add-qr-component'
    };
}
