import VueComponent, {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import CustomEditorService from '../../services/CustomEditorService';
import {html} from './QualityWarning.html';
import {ElementManager} from '../../services/ElementManager';
import {Services} from '../../../../core/services/Services';
import {OrderColorState} from '../../../order/models/Order';

class QualityWarningComponent extends VueComponent {

    @prop()
    productService: CustomEditorService;

    @data()
    element_manager: ElementManager;

    @data()
    dpi: number;
    
    constructor(component) {
        super(component);

        this.element_manager = Services.get<ElementManager>('ElementManager');
    }

    override mounted() {
        super.mounted();
    }

    @method()
    hasLowDPIFlag(): boolean {
        if (this.productService.hasLowDPIFlag()) {
            this.dpi = this.productService.uploadDPI();
            return true;
        }
        return false;
    }

    @method()
    imageQualityDescription(dpi): string {
        if (dpi < 150) {
            return 'Bad';
        }
        else if (dpi < 300) {
            return 'Okay';
        }
        else {
            return 'Good';
        }
    }

    @method()
    imageQualityColor(dpi): string {
        if (dpi < 150) {
            return OrderColorState.ERROR;
        }
        else if (dpi < 300) {
            return OrderColorState.WARNING;
        }
        else {
            return OrderColorState.GOOD;
        }
    }

    @method()
    widthPercentage(dpi): string {
        if (dpi > 300)
            return '100%';
        else
            return String((dpi / 300) * 100) + '%';
    }
}

export default function QualityWarning(): IVueComponent {
    return {
        controller: QualityWarningComponent,
        tag: 'quality-warning',
        template: html,
    }
}