export const html:string = `
<div class="add-clipart-component">
    <div class="input-group col-xs-12">
        <input type="text" class="form-control search-input" v-model="search_query" @keydown.enter="search()"/>
        <a class="input-group-addon inner-input" v-if="category"><span @click="removeCategory()">{{ category.name }} Clipart <i class="fa fa-times"></i></span></a>
        <a @click="search(1)" class="input-group-addon"><i class="fa" :class="{'fa-search': !searching, 'fa-spinner fa-spin': searching}"></i> Search</a>
    </div>                          
    <div v-show="search_query && clipart && clipart.$resolved && clipart.items.length == 0"> 
        <h2 class="results">No Results for "{{ searched_query }}"</h2>   
    </div>
    <div class="col-xs-12 col-full" v-show="searched_query != '' && !searching">
        <span v-if="category">
            Showing results for "{{ searched_query }}" in the {{ category.name }} category. <i class="fa fa-times" @click="reset()"></i>
        </span> 
        <span v-if="!category">
            Showing results for "{{ searched_query }}". <i class="fa fa-times" @click="reset()"></i>
        </span>
    </div>
    <div class="col-xs-12 col-full clipart-search-breadcrumbs" v-show="category_breadcrumbs.length > 0">
        <span><a @click="selectCategory(null)">Categories</a></span>
        <span v-for="item in category_breadcrumbs"> &gt; <a @click="selectCategory(item)">{{ item.name }}</a></span>
    </div>
    <div class="col-xs-12 col-full" v-show="shape_categories.length > 0 && searched_query == '' && category == null">
        <h3>Designs</h3>
        <ul class="item-list">
            <li v-for="shape_category in $filters.orderBy(shape_categories, 'name')" @click="selectCategory(shape_category)" class="col-xs-6 col-sm-4 clipart-preview category-preview">
                <a>
                    <div class="img-wrapper smaller-wrapper">
                        <img class="clipart-art" :src="shape_category.image"/>
                    </div>
                </a>
                <div class="clipart-name">{{ categoryshape_category }}</div>
            </li>
       </ul>
    </div>
    <div class="col-xs-12 col-full" v-show="subCategories(category).length > 0 && searched_query == ''">
        <h3>Categories</h3>
        <ul class="item-list">
            <li v-for="sub_category in $filters.orderBy(subCategories(category), 'name')" @click="selectCategory(sub_category)" class="col-xs-6 col-sm-4 clipart-preview category-preview">
                <a>
                    <div class="img-wrapper smaller-wrapper">
                        <img class="clipart-art" :src="sub_category.image"/>
                    </div>
                </a>
                <div class="clipart-name">{{ sub_category.name }}</div>
            </li>
        </ul>
    </div>
    <div class="col-xs-12 col-full" v-show="subCategories(category).length == 0 || searched_query != ''">
        <div class="col-xs-12 col-full">
            <h3>Clipart</h3>
            <callback-paginator :total="clipart ? clipart.totalCount : 0" :limit="limit" v-model:page="page" :callback="page_change_callback"></callback-paginator>
        </div>
        <ul class="item-list" v-if="clipart">
            <li v-for="clipart in clipart.items" class="col-xs-6 col-sm-4 clipart-preview">
                <a>
                    <div class="img-wrapper" @click="addClipart(clipart, $event)">
                        <img class="clipart-art" :src="clipart.preview"/>
                    </div>
                </a>
                <div class="clipart-name">{{ clipart.name }}</div>
                <button class="btn-ghost full-width btn-sm" type="button" name="button" @click="addClipart(clipart, $event)">Add Clipart</button>
            </li>
        </ul>
        <div class="col-xs-12 col-full">
            <callback-paginator class="col-xs-12 no-padd clip-art-modal-paginator" :total="clipart ? clipart.totalCount : 0" :limit="limit" v-model:page="page" :callback="page_change_callback"></callback-paginator>
        </div>
    </div>
</div>
`;
