import VueApp from '../../../../core/adapters/VueApp';
import VueComponent, {prop} from '../../../../core/adapters/VueComponent';
import {html} from './TemplateSetupOptions.html';
import IVueComponent from '../../../../core/adapters/IVueComponent';

class TemplateSetupOptionsController extends VueComponent {
    @prop()
    productService;

    @prop()
    noShape: boolean;

    @prop()
    designTool;
}

export default function TemplateSetupOptions(): IVueComponent {
    return {
        controller: TemplateSetupOptionsController,
        tag: 'template-setup-options',
        template: html,
    }
}