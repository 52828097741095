import {Template} from '../models/Template';
import {Custom} from '../models/Custom';
import PricingTier from '../../../core/utils/PricingTier';
import {Services} from '../../../core/services/Services';
import {Collection} from '../../../core/models/Collection';
import {TemplateSize} from '../models/TemplateSize';
import {fitToArea, getClosestQuarter, processRequestError} from '../../../core/utils/utils';
import {CustomOption} from '../models/CustomOption';
import APIResponse from '../../../core/models/APIResponse';
import {SimpleTemplate} from '../models/SimpleTemplate';
import {ClipTypeEnum, CustomLamination, DisplayTypeEnum, DynamicBackgroundShape, ProductTypeEnum, ToolTypeEnum} from '../models/enums';
import ConfiguredProductServiceAbstract from '../../products_shared/services/ConfiguredProductServiceAbstract';
import {Color} from '../models/Color';
import ButtonProcessingState from '../../../core/utils/ButtonProcessingState';
import {CustomShape} from '../models/CustomShape';
import {TemplateShape} from '../models/TemplateShape';
import {TemplateSide} from '../models/TemplateSide';
import {ShapeElement} from '../tool/ShapeElement';

export default class CustomService extends ConfiguredProductServiceAbstract {
    public override base: Template;
    public override configured: Custom;

    public override base_class: typeof Template;
    public override configured_class: typeof Custom;
    public similar_templates: APIResponse<SimpleTemplate>;

    public show_size_recommendation: boolean;
    public text_too_small: boolean;
    public show_sizes: boolean;
    public remove_background: boolean; // remove background on save

    public recommended_sizes: Collection<TemplateSize>;

    configured_option_model = 'CustomOption';

    constructor() {
        super(Services.get('Template'), Services.get('Custom'), 'template');
        this.show_size_recommendation = false;
        this.show_sizes = false;
        this.add_to_cart = false;
        this.recommended_sizes = new Collection();
    }

    override setupExtraData(all_data) {
        super.setupExtraData(all_data);

        if (all_data['similar_templates']) {
            this.similar_templates = Services.get<typeof SimpleTemplate>('SimpleTemplate').objects.from(all_data['similar_templates']);
        }
    }

    override get optionsUnitValue() {
        return this.configured.calcOptionsPrice(this.price.price, this.show_retail) / this.configured.quantity;
    }

    override get show_wholesale_prices(): boolean {
        return this.base.showWholesalePrices;
    }

    override checkMaterialColor() {
        let color_set = this.product_color_set;

        if (!this.configured.material_color && this.base.cut_out_color && color_set.getItemFromID(this.base.cut_out_color.id)) {
            this.configured.material_color = this.base.cut_out_color;
        }

        if (!this.configured.material_color || !color_set.getItemFromID(this.configured.material_color.id)) {
            this.configured.material_color = color_set.find(v => v.default) || color_set[0];
        }
    }

    override get product_color_set(): Collection<Color> {
        let color_set: Collection<Color> = this.base.show_material_color ? this.configured.material.material_colors : this.configured.material.design_colors;

        if (this.configured.size && this.configured.size.disabled_colors) {
            color_set = color_set.reduce((items, current) => {
                if (!this.configured.size.disabled_colors.getItemFromID(current.id)) {
                    items.push(current);
                }

                return items
            }, new Collection<Color>());
        }

        return color_set;
    }

    override get price(): PricingTier {
        if (this.configured.size && this.configured.size.price && this.configured.size.price.value > 0 && !this.configured.size.material_price) {
            return new PricingTier(this.configured.quantity, this.configured.size.price.value);
        }

        if (this.price_tier)
            return this.price_tier;

        // Can't calculate the price without pricing list
        if (!this.pricing) {
            if (this.configured.size && this.configured.size.price && this.configured.size.price.value) {
                return new PricingTier(this.configured.quantity, this.configured.size.price.value);
            }
            else {
                return new PricingTier(0, 0);
            }
        }

        return super.price;
    }

    override setupConfiguredFromBase(needs_additional_setup=false) {
        super.setupConfiguredFromBase(true);

        // Pick the recommended sizes to use
        /*
        if (this.configured.template_shape?.sizes?.length > 0) {
            this.recommended_sizes = this.configured.template_shape.sizes;
        }
        */
        if (this.base.sizes?.length > 0) {
            this.recommended_sizes = this.base.sizes;
        }

        if (this.base.option_border && this.configured.option_border == null) {
            if (this.base.type == ProductTypeEnum.PRINTED) {
                this.configured.option_border = true;
            }
            else {
                this.configured.option_border = false;
            }
        }
        if (this.base.option_laminate && this.configured.option_laminate == null) {
            if (this.configured.material && this.configured.material.default_lamination != null && this.configured.material.default_lamination != CustomLamination.NONE) {
                this.configured.option_laminate = this.configured.material.default_lamination;
            }
            else {
                this.configured.option_laminate = CustomLamination.GLOSS;
            }
        }

        // Set the size to the same item in the list so equality checks will work
        if (this.configured.size && this.recommended_sizes.getItemFromID(this.configured.size.id)) {
            this.configured.size = this.recommended_sizes.getItemFromID(this.configured.size.id);
        }

        if (!this.configured.size && this.recommended_sizes.length > 0) {
            this.configured.size = this.recommended_sizes.find((v) => v.width == this.configured.width && v.height == this.configured.height) || this.configured.size;
        }

        this.show_sizes = this.base.allow_custom_size || this.recommended_sizes.length > 0;

        // If there is no default width/height but there are recommended sizes then set the height/width based on the default
        if ((!this.configured.width || !this.configured.height) && this.recommended_sizes.length > 0) {
            for (const size of this.recommended_sizes) {
                if (size.default) {
                    this.configured.height = size.height;
                    this.configured.width = size.width;
                    break
                }
            }

            // No default set to first
            if (!this.configured.width || !this.configured.height) {
                this.configured.height = this.recommended_sizes[0].height;
                this.configured.width = this.recommended_sizes[0].width;
            }
        }

        this.setupShapes();

        this.configured.bind('change:size', () => {
            this.checkMaterialColor();
        });

        if (!needs_additional_setup) {
            this.setup_complete_deferred.resolve();
        }

        if (this.base.show_material_color) {
            this.configured.bind('change:material_color', this.updateShapeFromMaterialColors.bind(this));
        }
    }

    updateShapeFromMaterialColors() {
        if (this.base.show_material_color) {
            for (const shape of this.configured.custom_shapes) {
                if (shape.background_shape_fill != this.configured.material_color) {
                    shape.background_shape_fill = this.configured.material_color;
                }
            }
        }
    }

    setupShapes() {
        let to_remove = []
        for (const item of this.configured.custom_shapes) {
            if (!item.template_shape || !this.base.template_shapes.find(v => v.id == item.template_shape.id)) {
                console.warn(`Found mismatched shape #${item.template_shape?.id}`);
                to_remove.push(item);
            }
        }
        for (const item of to_remove) {
            this.configured.custom_shapes.splice(this.configured.custom_shapes.indexOf(item), 1);
        }

        for (const item of this.configured.custom_shapes) {
            item.bindToConfigured(this.configured);
        }

        // Unique set of sides, vue will prevent equality working so we cant use [... new Set(data)]
        let side_data = {};
        for (const item of this.base.template_shapes) {
            side_data[item.side?.id] = item.side;
        }
        let template_shape_sides: TemplateSide[] = Object.values(side_data);

        for (const side of template_shape_sides) {
            let existing = this.configured.custom_shapes.find(v => v.template_shape.side?.id == side?.id);
            if (existing) {
                continue;
            }

            let template_shape = this.templateShapesForSide(side)[0];
            let custom_shape = this.configured.custom_shapes.find(v => v.template_shape?.id == template_shape.id);

            if (!custom_shape) {
                custom_shape = new (Services.get<typeof CustomShape>('CustomShape'))();
                custom_shape.template_shape = template_shape;
                custom_shape.bindToConfigured(this.configured);
                custom_shape.loadDefaultsFromTemplate();
                this.configured.custom_shapes.push(custom_shape);
            }
        }

        if (this.base.show_material_color) {
            this.updateShapeFromMaterialColors();
        }
    }

    templateShapesForSide(side: TemplateSide) {
        return this.base.template_shapes.filter(
            v => v.side?.id == side?.id
        ).sort((a, b) => {
            if (a.default && b.default) {
                return 0;
            }
            if (a.default) {
                return -1;
            }
            if (b.default) {
                return 1
            }
            if (a.order == b.order) {
                return 0;
            }
            return a.order > b.order ? 1 : -1;
        });
    }

    public checkSharedShapeAttributes(shape: CustomShape) {
        if (this.configured.custom_shapes.indexOf(shape) == -1) {
            return;
        }

        for (const item of this.configured.custom_shapes) {
            if (item == shape) {
                continue;
            }
            if (!item.template_shape.share_attributes || item.template_shape.tag != shape.template_shape.tag) {
                continue;
            }

            for (const key of Services.get<typeof CustomShape>('CustomShape').SHARED_ATTRIBUTES) {
                if (item[key] != shape[key]) {
                    item[key] = shape[key];
                }
            }
        }
    }

    get size_by_id(): number|null {
        if (this.configured.size) {
            if (this.configured.size.width == this.configured.width && this.configured.size.height == this.configured.height) {
                return this.configured.size.id;
            }
        }

        for (const size of this.recommended_sizes) {
            if (size.width == this.configured.width && size.height == this.configured.height) {
                this.configured.size = size;
                return size.id;
            }
        }

        return null;
    }

    set size_by_id(v: number|null) {
        this.configured.size = this.recommended_sizes.getItemFromID(v);

        if (this.configured.size) {
            this.width = this.configured.size.width;
            this.height = this.configured.size.height;
        }
    }

    override get allow_custom_sizes() {
        return this.base.allow_custom_size;
    }

    fitShape(shape: CustomShape|TemplateShape) {
        if (!shape) {
            return;
        }

        if (shape.fixed_size) {
            this.configured.width = shape.fixed_width;
            this.configured.height = shape.fixed_height;
        }

        if (shape.standardSizeRatio || shape.locked_shape_size) {
            let width, height, size;
            if (shape.standardSizeRatio)
                size = fitToArea((shape.standardSizeRatio), 1, (this.width * this.height));

            if (shape.locked_shape_size)
                size = fitToArea(shape.clip_width, shape.clip_height, (this.width * this.height));

            if (size.width > size.height) {
                width = getClosestQuarter(size.width);
                height = Number((width * (size.height / size.width)).toFixed(3));
            } else {
                height = getClosestQuarter(size.height);
                width = Number((height * (size.width / size.height)).toFixed(3));
            }

            this.configured.width = width;
            this.configured.height = height;
            this.setLockedProportions(true);
        }
    }

    setupShapeElement(custom_shape: CustomShape): ShapeElement {
        // Allow this function to be called on both the CustomEditorService and CustomService
        // Do nothing, modifies the design tool

        return null
    }
    public checkSharedShapeSides(shape: CustomShape): void {
        // Allow this function to be called on both the CustomEditorService and CustomService
        // Do nothing, modifies the design tool
    }

    directToFinalize() {
        window.location.assign(this.configured.getFinalizeURL());
    }

    useDesign(template: Template, $event?) {
        let state = new ButtonProcessingState($event);
        state.process();

        this.configured.tool_type = ToolTypeEnum.ADVANCED;
        this.configured.configure = true;

        this.validate();
        if (this.hasErrors) {
            state.resolved();
            return;
        }

        this.configured.template = new (Services.get<typeof Template>('Template'))({
            id: template.id
        })

        state.resolved();
        this.save($event, true);
    }

    checkOptionsSet() {
        for (let option of this.configured.options) {
            if (!option.option_set?.optionEligible(this.configured.width, this.configured.height)) {
                this.configured.options.splice(this.configured.options.indexOf(option), 1)
            }
        }
    }

    override preValidate() {
        super.preValidate();
        this.checkOptionsSet();
    }

    override async preSave() {
        await super.preSave();

        if (this.remove_background) {
            await this.removeUserFileBackground();
        }

        if (this.configured.retail_package) {
            if (!this.configured.retail_package.package.getValidSizeFor(this.configured.width, this.configured.height)) {
                this.configured.retail_package = null;
            }
        }
    }

    async removeUserFileBackground() {
        if (!this.configured.user_file.original_background_file) {
            this.configured.user_file = await this.configured.user_file.removeBackground().catch((error) => {
                 this.errors.add('file', error.data['error']);
            });
        }
    }

    fetchTaggedDesigns(filterParams) {
        filterParams = filterParams || {};
        if (!this.configured) return;

        this.configured.$promise.then( () => {
            if (this.similar_templates) {
                return;
            }

            if (this.base.tags && this.base.tags.length > 0) {
                filterParams['tags__slug__in'] = this.base.tags.map(v => v.slug).join(',');
                filterParams['tool_type__in'] = [
                    ToolTypeEnum.PREVIEW,
                    ToolTypeEnum.LETTERING,
                    ToolTypeEnum.ADVANCED,
                    ToolTypeEnum.EASY
                ];
                filterParams['limit'] = 4;
            }
            this.similar_templates = Services.get<typeof SimpleTemplate>('SimpleTemplate').objects.filter(
                filterParams
            );
        });
    }

    override onSaveFailure(response) {
        if (response && response.data && response.data.custom) {
            this.errors.merge(response.data.custom);
        }
    }

    get size() {
        return this.configured.size;
    }
    set size(v) {
        this.configured.size = v;
    }
}
