import {uuid4} from '../../../core/utils/uuid';
import {Http} from '../../../core/services/Http';
import {IPromise, SimplePromise} from '../../../core/utils/SimplePromise';
import PricingTier from '../../../core/utils/PricingTier';

export default class PriceQuote {
    static $inject = [
        '$http'
    ];

    public $http: Http;

    constructor($http) {
        this.$http = $http;
    }

    uuid() {
        return uuid4();
    }

    // Raw quote request
    getPriceQuote(width, height, material_id, uuid, show_retail=false) {
        return this.$http.request({
            url: '/custom/api/v1/price-quote/',
            method: 'POST',
            data: {
                uuid: uuid,
                width: width,
                height: height,
                material_id: material_id,
                show_retail: show_retail
            }
        });
    }

    // Processed quote
    quoteRetailPackage(package_id, size_id, show_retail=false): IPromise<{pricing: PricingTier[], currency: string}> {
        const deferred = SimplePromise.defer<{pricing: PricingTier[], currency: string}>();

        this.$http.request({
            url: '/packaging/api/v1/price-quote/',
            method: 'POST',
            data: {
                package_id: package_id,
                size_id: size_id,
                show_retail: show_retail
            }
        }).then((response) => {
            let prices = response.data.prices;
            if (!prices) {
                deferred.reject(null);
                return;
            }

            let quantities = response.data.quantities;
            quantities.unshift(1);

            // shift sq inches breakpoint
            const sqInch = prices.shift();

            let pricing = [];
            for (let i = 0; i < prices.length; i++) {
                pricing.push(new PricingTier(quantities[i], prices[i]));
            }
            deferred.resolve({
                'pricing': pricing,
                'currency': response.data.currency
            });
        }, (error) => {
            deferred.reject(null);
        })

        return deferred.promise;
    }
}
