import VueComponent, {prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './ColorPicker.html';

class ColorPickerComponent extends VueComponent {
    @prop({
        emit: true
    })
    color: string;

    @prop()
    change;

    @prop()
    side: string;

    private vanilla_picker;
    private picker;
    override async mounted() {
        super.mounted();

        this.vanilla_picker = (await import('vanilla-picker')).default;
        this.picker = new this.vanilla_picker({
            parent: this.$el,
            popup: this.side ? this.side : 'left',
            color: this.color,
            alpha: false,
            editorFormat: 'hex',
            onChange: (color) => {
                this.color = color.hex.slice(0, 7);

                if (this.change) {
                    this.change(this.color);
                }
            }
        });
    }

    override unmounted() {
        this.picker?.destroy();
    }
}

export default function ColorPicker(): IVueComponent {
    return {
        controller: ColorPickerComponent,
        tag: 'color-picker',
        template: html
    }
}
