import {html} from './SwapTemplate.html';
import {uuid4} from '../../../../../core/utils/uuid';
import VueComponent, {computed, data, method, prop} from '../../../../../core/adapters/VueComponent';
import CustomEditorService from '../../../services/CustomEditorService';
import {Http} from '../../../../../core/services/Http';
import IVueComponent from '../../../../../core/adapters/IVueComponent';

class TemplateSwapComponentController extends VueComponent {
    static override $inject = [
        '$http'
    ];

    @prop()
    productService: CustomEditorService;

    @data()
    query;

    @data()
    categories: any[];

    @data()
    items: any[];

    @data()
    all_categories: boolean;

    @data()
    open_category_selection: boolean;

    @data()
    close_dropdown_callback;

    protected timeout_promise;
    protected search_uuid;
    protected loading: boolean;

    constructor(component, protected $http: Http) {
        super(component);

        this.query = '';
        this.close_dropdown_callback = this.closeDropdown.bind(this);
    }

    override mounted() {
        super.mounted();

        this.getMaterials();
        this.search();
    }

    @computed()
    allCategoriesSelected() {
        if (!this.categories) {
            return true;
        }

        for (const item of  this.categories) {
            if (item.selected) {
                return false;
            }
        }

        return true;
    }

    setAllCategories(v) {
        if (v && this.categories) {
            for (const item of this.categories) {
                item.selected = false;
            }
        }
    }

    get selected_categories() {
        let selected_items = [];

        if (this.categories) {
            for (const item of this.categories) {
                if (item.selected) {
                    selected_items.push(item.id);
                }
            }
        }

        return selected_items;
    }

    getMaterials() {
        this.$http.request({
            method: 'GET',
            params: {
                template: this.productService.base.id,
            },
            url: '/custom/api/v1/swap-template-categories/',
        }).then((response) => {
            this.categories = response.data.items;
        }).catch((response: any) => {
            console.error(response.data);
            return response
        });
    }

    @method()
    search() {
        if (this.timeout_promise) {
            clearTimeout(this.timeout_promise);
        }

        this.timeout_promise = setTimeout(() => {
            let local_uuid = uuid4();
            this.search_uuid = local_uuid;
            this.loading = true;

            this.$http.request({
                method: 'GET',
                params: {
                    template: this.productService.base.id,
                    categories: this.selected_categories.join(','),
                    search: this.query
                },
                url: '/custom/api/v1/swap-template-search/',
            }).then((response) => {
                if (local_uuid == this.search_uuid) {
                    this.items = response.data.items;
                }
                this.loading = false;
            }, (response: any) => {
                console.error(response.data);

                return response
            })
        }, 300);
    }

    @method()
    swapTo(item) {
        this.productService.swapToTemplate(item.id);
    }

    @method()
    closeDropdown() {
        this.open_category_selection = false;
    }
}

export default function DesignToolSwapTemplate(): IVueComponent {
    return {
        controller: TemplateSwapComponentController,
        template: html,
        tag: 'design-tool-swap-template'
    };
}
