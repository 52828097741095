import VueComponent, {method, prop} from '../../../../core/adapters/VueComponent';
import {html} from './TemplateSetupMaterial.html';
import {ProductTypeEnum} from '../../models/enums';

class TemplateSetupMaterialController extends VueComponent {
    @prop()
    productService;

    @method()
    showSelectColor(): boolean {
        // Make sure the colors don't double up
        let show_material_color = this.productService.base.show_material_color && this.productService.element_manager.editableBackgroundClipartElements.length == 0;
        return (this.productService.base.type == ProductTypeEnum.CUTOUT || show_material_color) && !this.productService.base.hide_material
    }
}

export default function TemplateSetupMaterial() {
    return {
        controller: TemplateSetupMaterialController,
        tag: 'template-setup-material',
        template: html,
    }
}
