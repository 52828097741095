export const html:string = `
<div class="tool-tab">
    <label><i class="fa fa-crop"></i> Crop</label>
    <div class="full-width">
        <span>Crop Shapes</span>
        <div class="important-options-area">
            <div class="crop-selection">
                <div class="crop-item" :class="{'active-mask': element.mask.model.clipart.id == mask.id}" @click="changeClipMask($event, mask)" v-for="mask in productService.clip_mask_elements.items">
                    <img :src="mask.clipart">
                    <span>{{ mask.name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
`;