export const html: string = `
<div class="row qr-setup">
    <div class="container-col text-center standalone-qr-code">
        <div class="col-xs-12 qr-title-area" v-show="step == 1">
            <h2>QR Code Stickers</h2>
            <h5 class="tight">Create a custom QR Code sticker with your text or logo</h5>
            
            <span class="back-a-step" style="float: right" @click="reset()" v-show="step == 2 || step == 3"><i class="fa fa-arrow-left"></i> Back To Types</span>
        </div>
        <div class="col-xs-12 qr-title-area" v-show="step == 2">
            <h2>Select QR Code Type</h2>
            <h5 class="tight">Select the type of QR Code</h5>
            
            <span class="back-a-step" style="float: right" @click="reset()" v-show="step == 2 || step == 3"><i class="fa fa-arrow-left"></i> Back To Types</span>
        </div>
        <div class="col-xs-12 qr-title-area" v-show="step == 3">
            <h2>Select Product</h2>
            <h5 class="tight">Select the product to start customizing</h5>
            
            <span class="back-a-step" style="float: right" @click="reset()" v-show="step == 2 || step == 3"><i class="fa fa-arrow-left"></i> Back To Types</span>
        </div>
        <div v-show="step == 1" class="type-setup">
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img src="/static/img/qr/qr_code_website.png" alt="URL QR Code"/>
                <button class="btn btn-ghost btn-full" @click="active = 1; step = 2;">Website URL</button>
                <p class="padded">
                    With a website URL code, people that scan it will be taken to whatever site that you enter. This sticker is great for marketing, or directing customers to a menu at restaurants.
                </p>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img src="/static/img/qr/qr_code_contact.png" alt="Contact Card QR Code"/>
                <button class="btn btn-ghost btn-full" @click="active = 2; step = 2;">Contact Info</button>
                <p class="padded">
                    With a contact information code, people will be able to add you as a contact in their phone without having to type anything. This is a great networking option for freelancers and small businesses.
                </p>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img src="/static/img/qr/qr_code_text.png" alt="Text QR Code"/>
                <button class="btn btn-ghost btn-full" @click="active = 3; step = 2;">Text Message</button>
                <p class="padded">
                    SMS text message codes are great for contests, deals and coupons. They work best with an automated text message system that creates a list of customers and allow you to reach out to them whenever you have a new sale or coupon!
                </p>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img src="/static/img/qr/qr_code_custom.png" alt="Custom QR Code"/>
                <button class="btn btn-ghost btn-full" @click="active = 4; step = 2;">Custom</button>
                <p class="padded">
                    With a custom QR code, you can do anything from pulling up an app in the iTunes store to requesting a PayPal payment from the person scanning it. This is an advanced option.
                </p>
            </div>
        </div>
      
        <div class="data-setup col-xs-12">
            <div id="website" v-show="active == 1 && step == 2" class="qr-url">
                <label class="sub-label">Website</label>
                
                <div class="form-group col-xs-12">
                    <h5>Website URL:</h5>
                    <error-list :errors="errors" field="url"></error-list>
                    <input class="form-control" name="url" :placeholder="'Example: https://www.' + company.short_url" v-model="qr_data.url">
                </div>
                
                <div class="col-xs-12">
                    <button class="btn btn-ghost pull-right" @click="next('url')">Continue</button>
                </div>   
            </div>
            <div id="contact" v-show="active == 2 && step == 2">
                <div class="personal-info clearfix">
                    <label class="sub-label">Personal Information</label>
                    
                    <div class="form-group col-xs-12">
                        <h5>Name:</h5>
                        <input class="form-control" name="name" v-model="qr_data.name">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Email:</h5>
                        <input class="form-control" name="email" v-model="qr_data.email">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Address:</h5>
                        <input class="form-control" name="address" v-model="qr_data.home_address_1">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>State:</h5>
                        <input class="form-control" name="state" v-model="qr_data.home_state">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>Zip:</h5>
                        <input class="form-control" name="zip" v-model="qr_data.home_zip">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Title:</h5>
                        <input class="form-control" name="title" v-model="qr_data.title">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Telephone:</h5>
                        <input class="form-control" name="phone" v-model="qr_data.phone_home">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>City:</h5>
                        <input class="form-control" name="city" v-model="qr_data.home_city">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Country</h5>
                        <input class="form-control" name="country" v-model="qr_data.home_country">
                    </div>
                </div>
                <div class="personal-info clearfix">
                    <label class="sub-label">Work Information</label>
                    
                    <div class="form-group col-xs-12">
                        <h5 class="first-label">Company Name:</h5>
                        <input class="form-control" name="company_name" v-model="qr_data.company">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Telephone:</h5>
                        <input class="form-control" name="phone" v-model="qr_data.phone_work">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Address:</h5>
                        <input class="form-control" name="address" v-model="qr_data.work_address_1">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>City:</h5>
                        <input class="form-control" name="city" v-model="qr_data.work_city">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>State:</h5>
                        <input class="form-control" name="state" v-model="qr_data.work_state">
                    </div>
                    <div class="form-group col-xs-6">
                        <h5>Zip:</h5>
                        <input class="form-control" name="zip" v-model="qr_data.work_zip">
                    </div>
                    <div class="form-group col-xs-12">
                        <h5>Country</h5>
                        <input class="form-control" name="country" v-model="qr_data.work_country">
                    </div>
                </div>
                <div class="col-xs-12">
                    <button class="btn btn-ghost pull-right" @click="next()">Continue</button>
                </div>
            </div>
            <div id="text" v-show="active == 3 && step == 2">
                <label class="sub-label">Text Message</label>
                
                <div class="form-group col-xs-12">
                    <h5>Cell Phone Number:</h5>
                    <input class="form-control" name="country" placeholder="Example: 555-555-5555" v-model="qr_data.sms">
                    <span class="helper-text">This is the cell phone number the text is coming from.</span>
                </div>
                
                <div class="form-group col-xs-12">
                    <h5>Message:</h5>
                    <textarea class="form-control" rows="10" name="message" placeholder="Type your message here" v-model="qr_data.smsm"></textarea>
                </div>
                
                <div class="col-xs-12">
                    <button class="btn btn-ghost pull-right" @click="next()">Continue</button>
                </div>  
            </div>
            <div id="custom" v-show="active == 4 && step == 2">
                <label class="sub-label">Custom</label>
                
                <div class="form-group col-xs-12">
                    <h5>Personal Information</h5>
                    <textarea class="form-control" rows="10" name="info" v-model="qr_data.custom"></textarea>
                </div>
                
                <div class="col-xs-12">
                    <button class="btn btn-ghost pull-right" @click="next()">Continue</button>
                </div> 
            </div>
            <div v-show="step == 3" class="pick-template">
                <div class="col-md-2 col-sm-4 col-xs-12">
                    <a @click.prevent="selected('qr-code-gift-template')">
                        <img src="/static/img/qr/qr_code_gift.png"/>
                    </a>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12">
                    <a @click.prevent="selected('qr-code-house-template')">
                        <img src="/static/img/qr/qr_code_house.png"/>
                    </a>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12">
                    <a @click.prevent="selected('qr-code-shopping-bag-template')">
                        <img src="/static/img/qr/qr_code_bag.png"/>
                    </a>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12">
                    <a @click.prevent="selected('qr-code-speech-bubble-template')">
                        <img src="/static/img/qr/qr_code_word_baloon.png"/>
                    </a>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12">
                    <a @click.prevent="selected('qr-code-rounded-rectangle-template')">
                        <img src="/static/img/qr/qr_code_custom_rectangle.png"/>
                    </a>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12">
                    <a @click.prevent="selected('circle-qr-code-template')">
                        <img src="/static/img/qr/qr_code_circle.png"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="qr-step-block col-xs-12 col-full">
        <label>Creating a QR Code is Easy!</label>
        <div class="customize-steps light">
            <ul>
                <li v-for="item in steps" :class="{'completed': step >= item.index}" @click="back(item.index)">
                    {{item.title}}
                    <h5>{{item.help}}</h5>
                </li>
            </ul>
            <ul class="graphic">
                <li :class="{'completed': step > 1}" @click="back(1)">
                    <i class="fa fa-check"></i>
                </li>
                <li :class="{'completed': step > 2}" @click="back(2)">
                    <span></span>
                    <i class="fa fa-check"></i>
                </li>
                <li class="{'completed': step > 3}" @click="back(3)">
                    <span></span>
                    <i class="fa fa-check"></i>
                </li>
            </ul>
        </div>
        <p v-show="active" class="helper-text">
            Please keep in mind that not all QR scanners can read every type of QR code.
            <br>
            {{ company.name }} does not provide any support and will not refund sticker orders if your custom QR code does not work.
            <br>
            Please try your QR code before you purchase it.
        </p>
    </div>
</div>
`;
