import AbstractDesignToolController from '../AbstractDesignToolController';
import {computed, method} from '../../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './FooterActions.html';
import {ClipMaskElement} from '../../../tool/ClipMaskElement';

class FooterActionsController extends AbstractDesignToolController {

    @computed()
    showFooterActions() {
        let tab_check = this.activeTab != null &&
                this.activeTab != 'layers' &&
                this.activeTab != 'background' &&
                this.activeTab != 'type' &&
                this.activeTab != 'product' &&
                this.activeTab.indexOf('-new') == -1;

        if (!this.element) {
            return tab_check;
        }

        let element_check = this.elementHasAdvancedOptions();

        return tab_check && element_check;
    }

    @method()
    doneEditingClipping($event) {
        let _element = this.element as ClipMaskElement
        _element.edit_state = false;
        this.selection_manager.select(null);

        setTimeout(() => {
            this.selection_manager.select(_element);
        })
    }

    @method()
    removeClipping($event) {
        let _element = this.element as ClipMaskElement
        _element.remove(false);
    }

    @method()
    duplicateItem($event) {
        if ($event) {
            $event.preventDefault();
        }

        let clone = null;
        if (this.isSelectionClipart()) {

            clone = this.productService.createClipartElement(this.element.model);
        }
        else if (this.isSelectionRaster()) {
            clone = this.productService.createUserFileElement(this.element.model);
        }
        else if (this.isSelectionQRCode()) {
            clone = this.productService.createQRCodeElement(this.element.model);
        }
        else if (this.isSelectionText()) {
            clone = this.productService.createTextElement(this.element.model);
        }

        if (clone) {
            clone.x -= 20;
            clone.y -= 20;
        }

        this.element_manager.fixZ();
        this.element_manager.sort();
    }
}

export default function DesignToolFooterActions(): IVueComponent {

    return {
        controller: FooterActionsController,
        template: html,
        tag: 'design-tool-footer-actions'
    };
}
