import * as paper from 'paper';
import {EventDispatcher} from 'simple-ts-event-dispatcher';
import PaperCanvasService from '../services/PaperCanvasService';
import {ElementTypeEnum, IElement} from './IElement';
import {Services} from '../../../core/services/Services';
import {ElementManager} from '../services/ElementManager';
import {SelectionManager} from '../services/SelectionManager';
import {IDeferred, IPromise, SimplePromise} from '../../../core/utils/SimplePromise';
import ClipperService from '../services/ClipperService';

export class ElementFrameTask {
    constructor(
        public readonly fnc: any,
        public readonly context: any,
        public readonly args?: any[]
    ) {}

    public call(): void {
        this.fnc.apply(this.context, this.args);
    }
}

export abstract class BaseElement<T extends paper.Item = paper.Item> extends EventDispatcher implements IElement<T> {
    initialized: IPromise<any>;
    initialized_deferred: IDeferred<any>;
    _layer: paper.Layer;
    _item: T;
    _model: any;
    type: ElementTypeEnum;
    debugItems: paper.Item[];
    handleClear: boolean;
    frameTasks: ElementFrameTask[];
    protected _removed: boolean = false;
    protected _locked: boolean;
    private current_frame_task;
    protected _isSelected: boolean;
    protected _isDragging: boolean;
    public disable_selection: boolean;
    public canvasService: PaperCanvasService;
    private canvasPositionCheckCallback
    block_frame_tasks: boolean;
    offset: paper.CompoundPath;
    offset_waiting: boolean;
    offset_changed: boolean;
    disable_offset_path: boolean;

    // Idk why the offset path breaks so badly in the export state
    // It doesn't need to be updated so lets just prevent it from updating
    disable_offset_path_in_export: boolean = false;

    element_manager: ElementManager;
    static cached_textures = {};

    constructor(model: any, layer?: paper.Layer) {
        super();

        this.element_manager = Services.get<ElementManager>('ElementManager');
        this.canvasService = Services.get<PaperCanvasService>('PaperCanvasService');
        this.setupModel(model);

        this.frameTasks = [];
        this.canvasService.paperScope.activate();
        this.initialized_deferred = SimplePromise.defer<any>();
        this.initialized = this.initialized_deferred.promise;
        this._locked = false;

        this.offset_waiting = false;
        this.offset_changed = false;

        this.canvasPositionCheckCallback = this.checkCanvasPosition.bind(this);

        // On the first successful update, fire off the initialized event.
        this.once('updatePaper', this.initialize, this);
        this.canvasService.bind('flushFrameTasks', this.flushFrameTasks.bind(this));
        this.debugItems = [];

        if (model.z == null) {
            this.z = this.element_manager.nextZ;
        }

        // Paperjs setup
        this.setupPaper(layer);

        // Bind events
        this.bindEvents();
    }

    get offset_path_enabled() {
        return !this.disable_offset_path && this.canvasService.offset_path_enabled && !!this.model.offset_width && (!this.model.side || !this.model.side.disable_offset_path);
    }

    get can_offset_path() {
        return !this.disable_offset_path && this.canvasService.offset_path_enabled && (!this.model.side || !this.model.side.disable_offset_path);
    }

    removeOffsetPath() {
        this.model.offset_width = 0;
        this.hideOffsetPath();
        this.canvasService.queueFlattenCutpathLayer();
    }

    hideOffsetPath() {
        if (this.offset) {
            this.canvasService.removeOffset(this.offset);
            this.offset = null;
        }
    }

    addOffsetPath() {
        this.model.offset_width = 12;
        this.offsetPath();
    }

    async offsetPath() {
        let paths = [];
        for (const path of this.findPaths(this.item)) {
            path.strokeJoin = 'round';
            let items = await Services.get<ClipperService>('ClipperService').offset(path as paper.Path, Number(this.model.offset_width));
            for (const item of items) {
                path.remove();
                paths.push(item);
            }
        }

        return paths;
    }

    async queueOffsetPath() {
        if (!this.model.offset_width) {
            if (this.offset) {
                this.removeOffsetPath();
            }
            return;
        }

        if ((this.canvasService.inExportState && this.disable_offset_path_in_export) || this.canvasService.in_save_state) {
            return;
        }

        if (this.model.offset_width < 7) {
            this.model.offset_width = 7;
        }
        if (this.model.offset_width > 50) {
            this.model.offset_width = 50;
        }

        if (!this.offset_path_enabled) {
            return;
        }

        this.canvasService.queueFlattenCutpathLayer();

        // If we are waiting on the current offset path task to finish
        if (this.offset_waiting) {
            // Flat that the item we are offsetting has changed and that the path being generated can no longer be used
            this.offset_changed = true;
            return;
        }

        // Signal that we are waiting for the task to finish and no future calls should be made until its done
        this.offset_waiting = true;

        let paths = await this.offsetPath();

        if (!paths || paths.length == 0) {
            this.offset_waiting = false;
            this.offset_changed = false;
            return;
        }

        // If the element was removed while waiting for the path update, dont all the cut path
        if (this._removed) {
            this.offset_waiting = false;
            this.offset_changed = false;
            return;
        }

        if (this.offset_changed) {
            // If the offset changed while we were waiting, discard results and try again
            this.offset_waiting = false;
            this.offset_changed = false;

            return this.queueOffsetPath();
        }
        else {
            if ((this.canvasService.inExportState && this.disable_offset_path_in_export) || this.canvasService.in_save_state) {
                return;
            }
            this.canvasService.withSideActive(() => {
                let new_path = new this.paper.CompoundPath(paths);

                // If the paths are the same or close enough then don't update further
                if (this.offset) {
                    let width_difference = Math.abs(this.offset.bounds.width - new_path.bounds.width);
                    let height_difference = Math.abs(this.offset.bounds.height - new_path.bounds.height);
                    let x_difference = Math.abs(this.offset.position.x - new_path.position.x);
                    let y_difference = Math.abs(this.offset.position.y - new_path.position.y);

                    if (this.offset.compare(new_path) || (width_difference < 0.0001 && height_difference < 0.0001 && x_difference < 0.0001 && y_difference < 0.0001)) {
                        new_path.remove();
                        this.offset_waiting = false;
                        return;
                    }
                }

                // Offset task finished and the element has not been modified so we can use it
                this.setupOffsetPath(new_path);

                this.canvasService.addOffset(this.offset, this.model.side?.id);
                this.offset_waiting = false;
            }, this.side)
        }
    }

    forceOffsetPath() {
        let deferred = SimplePromise.defer();

        if (!this.model.offset_width) {
            deferred.resolve(null);
            return deferred.promise;
        }

        this.offsetPath().then((paths) => {
            let new_path = new this.paper.CompoundPath(paths);
            this.setupOffsetPath(new_path);
            this.canvasService.addOffset(this.offset, this.model.side?.id,false);
            deferred.resolve(null);
        });

        return deferred.promise;
    }

    setupOffsetPath(compound_path) {
        // Discard the old path
        if (this.offset) {
            this.canvasService.removeOffset(this.offset);
        }

        // Setup and add the new one
        this.offset = compound_path;
        this.offset.fillColor = new this.paper.Color(this.canvasService.offset_path_color);
    }

    findPaths(item, paths=[]) {
        if (!item) {
            return paths;
        }

        if (['Group', 'Layer', 'CompoundPath'].indexOf(item.className) > -1) {
            for (const child of item.children) {
                this.findPaths(child, paths);
            }
        }
        else if (item instanceof this.paper.Path) {
            const clone = item.clone({deep: true});
            clone.fillColor = null;
            clone.strokeColor = null;
            clone.remove();
            paths.push(clone);
        }
        return paths;
    }

    get paper() {
        return this.canvasService.paper;
    }

    get elevation_layer() {
        return this.layer;
    }

    get layer(): paper.Layer {
        return this._layer;
    }
    set layer(v: paper.Layer) {
        this._layer = v;
    }

    get item(): T {
        return this._item;
    }
    set item(v: T){
        this._item = v;
    }

    get model() {
        return this._model;
    }
    set model(v) {
        this._model = v;
    }

    protected addDebugCircle(point, color, width=10) {
        const debugCircle = new this.paper.Path.Circle(
            point,
            width
        );
        debugCircle.strokeWidth = 2;
        debugCircle.strokeColor = color;
        debugCircle.fillColor = color;

        this.debugItems.push(debugCircle);
    }

    protected setupModel(model) {
        this.model = model;
        if (this.model.x_scale_inverted === undefined) {
            this.model.x_scale_inverted = false;
        }
        if (this.model.y_scale_inverted === undefined) {
            this.model.y_scale_inverted = false;
        }

        if (!this.model.side) {
            this.model.side = this.canvasService.activeLayerSide.model;
        }
        this.canvasService.addSide(this.model.side);

        this._removed = false;
        this.model.bind('remove', () => {
            if (!this._removed) {
                this.remove();
            }
        }, this);

        // Tell the element manager to update the controls when the model changes
        let element_manager = Services.get<SelectionManager>('SelectionManager');
        const onModelChange = () => {
            if (element_manager.selection == this) {
                element_manager.drawControls();
            }
        }
        this.model.bind('change', () => {
            if (!this.hasFrameTask(onModelChange, this)) {
                this.addFrameTask(onModelChange, this)
            }
        });
        this.model.bind('change:side', () => {
            this.canvasService.selectSide(this.side);
            this.canvasService.rootLayer.addChild(this.layer);
        });
        this.canvasService.bind('change:offset_path_color', () => {
            if (this.offset) {
                this.offset.fillColor = new this.paper.Color(this.canvasService.offset_path_color);
            }
        });
    }

    setupPaper(layer) {
        // If a layer is being passed to this element, the object that created
        // the layer must clear it
        this.handleClear = !layer;
        this.layer = layer || new this.paper.Layer();

        this.layer.activate();

        this.canvasService.addItem(this);
    }

    protected bindEvents(): void {}
    protected unbindEvents(): void {}

    // The item needs to be loaded into paper first
    fillInValuesFromPaper() {
        if (this.item && this.item.bounds) {
            let updated_size = this.model.width == null || this.model.height == null;

            this.model.width = this.model.width || this.item.bounds.width;
            this.model.height = this.model.height || this.item.bounds.height;
            this.model.x = this.model.x || this.item.bounds.x;
            this.model.y = this.model.y || this.item.bounds.y;
            this.model.z = this.model.z || 0;

            if (updated_size && this.model.width && this.model.height) {
                let width_scale = this.model.width / this.canvasService.workspace.artboard.width;
                let height_scale = this.model.height / this.canvasService.workspace.artboard.height;

                if (width_scale > 1 || height_scale > 1) {
                    let scale = width_scale > height_scale ? width_scale : height_scale;
                    this.model.width *= (1 / scale) * 0.75;
                    this.model.height *= (1 / scale) * 0.75;
                }
            }
        }
    }

    get side() {
        return this.model.side ? this.model.side.id : 0;
    }

    set side(v) {
        if (typeof v == 'number') {
            v = this.canvasService.sides.find(i => i.model.id == v);
        }

        this.model.side = v;
    }

    protected initialize(): void {
        this.initialized_deferred.resolve(null);
        this.trigger('initialized');
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    get isDragging(): boolean {
        return this._isDragging;
    }

    get x(): number {
        return this.model.cleaned.x;
    }

    set x(x: number) {
        this.model.x = x;
        this.queuePositionCheck();
    }

    get y(): number {
        return this.model.cleaned.y;
    }

    set y(y: number) {
        this.model.y = y;
        this.queuePositionCheck();
    }

    get z(): number {
        return this.model.cleaned.z;
    }

    set z(z: number) {
        this.model.z = z;
    }

    get width(): number {
        return this.model.cleaned.width;
    }

    set width(width: number) {
        this.model.width = width;
        this.queuePositionCheck();
    }

    get height(): number {
        return this.model.cleaned.height;
    }

    set height(height: number) {
        this.model.height = height;
        this.queuePositionCheck();
    }

    get rotation(): number {
        return this.model.cleaned.rotation;
    }

    set rotation(rotation: number) {
        this.model.rotation = rotation;
    }

    get maintain_proportion() {
        return this.model.maintain_proportion;
    }

    set maintain_proportion(v) {
        this.model.maintain_proportion = v;
    }

    get bounds(): paper.Rectangle {
        if (this.isImmutable() && this.item) {
            return this.item.bounds;
        }

        return this.layer.bounds;
    }

    get x_scale_inverted() {
        return !!this.model.x_scale_inverted;
    }

    set x_scale_inverted(value) {
        this.model.x_scale_inverted = !!value;
    }

    get y_scale_inverted() {
        return !!this.model.y_scale_inverted;
    }

    set y_scale_inverted(value) {
        this.model.y_scale_inverted = !!value;
    }

    get invert(): boolean {
        return this.x_scale_inverted && this.y_scale_inverted;
    }

    set invert(value) {
        this.x_scale_inverted = value;
        this.y_scale_inverted = value;
        this.updatePaper();
    }

    get flipX(){
        return this.x_scale_inverted;
    }

    set flipX(value: boolean){
        this.x_scale_inverted = value;
        this.updatePaper();
    }

    get flipY(){
        return this.y_scale_inverted;
    }

    set flipY(value: boolean){
        this.y_scale_inverted = value;
        this.updatePaper();
    }

    get dont_clip() {
        return this.model.dont_clip;
    }

    set dont_clip(value: boolean) {
        this.model.dont_clip = value;
    }

    /**
     * If element bounds are different than bounds, return them with this
     * method. This can be useful when an element is rotated and the bounds
     * are increased/decreased.
     */
    get elementBounds(): paper.Rectangle {
        return this.bounds;
    }

    queuePositionCheck() {
        if (!this.hasFrameTask(this.canvasPositionCheckCallback, this)) {
            this.addFrameTask(this.canvasPositionCheckCallback, this);
        }
    }

    rotatePoint(point: paper.Point, radians: number): paper.Point {
        const sin = Math.sin(radians),
            cos = Math.cos(radians),
            x = (cos * point.x) - (sin * point.y),
            y = (sin * point.x) + (cos * point.y),
            newPoint = new this.paper.Point(x, y);
        return newPoint;
    }

    sign(x: number): number {
        return typeof x === 'number' ? x ? x < 0 ? -1 : 1 : x === x ? 1 : NaN : NaN;
    }

    onMouseDown(event: paper.ToolEvent): void {

    }

    onMouseDrag(event: paper.ToolEvent): void {
        this._isDragging = true;
        const point = event.point.subtract(event.lastPoint);
        const newPosition = this.correctElementOffCanvasPosition(new this.paper.Point(
            this.x + point.x,
            this.y + point.y
        ));
        const newCenterPosition = this.correctElementOffCanvasPosition(new this.paper.Point(
            this.bounds.center.x + point.x,
            this.bounds.center.y + point.y
        ));

        if (!this.height) {
            this.y = newPosition.y;
        } else if (newCenterPosition.y > 0 && newCenterPosition.y < this.canvasService.workspace.artboard.height) {
            this.y = newPosition.y;
        }
        else if (newCenterPosition.y <= 0 && newCenterPosition.y > this.bounds.center.y) {
            this.y = newPosition.y;
        }
        else if (newCenterPosition.y > this.canvasService.workspace.artboard.height && newCenterPosition.y <= this.bounds.center.y) {
            this.y = newPosition.y;
        }

        if (!this.width) {
            this.x = newPosition.x;
        }
        else if (newCenterPosition.x > 0 && newCenterPosition.x < this.canvasService.workspace.artboard.width) {
            this.x = newPosition.x;
        }
        else if (newCenterPosition.x <= 0 && newCenterPosition.x > this.bounds.center.x) {
            this.x = newPosition.x;
        }
        else if (newCenterPosition.x > this.canvasService.workspace.artboard.width && newCenterPosition.x <= this.bounds.center.x) {
            this.x = newPosition.x;
        }

        this.onChange();
    }

    onMouseUp(event: paper.ToolEvent): void {
        this._isDragging = false;
        this.updatePaper();
    }

    onMouseMove(event: paper.ToolEvent): void {

    }

    onScaleControlDrag(event: paper.ToolEvent, control: paper.Path): void {
        this._isDragging = true;
        // If our model doesn't have width or height, populate them from
        // element.bounds
        if (!this.width)
            this.width = this.elementBounds.width;

        if (!this.height)
            this.height = this.elementBounds.height;

        // We need to figure out where the control is in relation to the
        // center of the element without the rotation. In order to do this, we
        // need to get the current angle of the control point to the center
        // and then subtract the element rotation. Once we have the un-rotated
        // angle of the control, we can calculate a point around the element
        // base on a circle that's radius is the distance from the control
        // to the center of the element.
        let elementRadians = (this.rotation || 0) * Math.PI / 180,
            rotatedControlAngle =  Math.atan2(
                control.position.y - this.bounds.center.y,
                control.position.x - this.bounds.center.x
            ),
            normalControlAngle = rotatedControlAngle - elementRadians,

            // Find the point on a circle where the control is without rotation
            radius = event.point.getDistance(this.bounds.center),
            realControlWithoutRotationX = this.bounds.center.x + radius * Math.cos(normalControlAngle),
            realControlWithoutRotationY = this.bounds.center.y + radius * Math.sin(normalControlAngle),
            controlPointWithoutRotation = new this.paper.Point(realControlWithoutRotationX, realControlWithoutRotationY),

            // Create a vector that points to the control from the center. This is
            // used to modify the direction of expansion/shrinking.
            centerToControlWithoutRotationNormal = controlPointWithoutRotation.subtract(this.bounds.center).normalize(),
            // Build the vector from the normalized point above
            centerToControlWithoutRotationVector = new this.paper.Point(
                centerToControlWithoutRotationNormal.x == 0 ? 0 : centerToControlWithoutRotationNormal.x > 0 ? 1 : -1,
                centerToControlWithoutRotationNormal.y == 0 ? 0 : centerToControlWithoutRotationNormal.y > 0 ? 1 : -1
            ),
            sizeChange = new this.paper.Point(0, 0),
            position = new this.paper.Point(0, 0);

        if (this.model.maintain_proportion) {
            let movement = this.rotatePoint(event.point.subtract(event.lastPoint), -normalControlAngle),
                lastMouseToCenterDistance = this.rotatePoint(
                        event.lastPoint,
                        -normalControlAngle
                    ).getDistance(this.rotatePoint(
                        this.bounds.center,
                        -normalControlAngle
                    )
                ),
                mouseToCenterDistance = this.rotatePoint(
                        event.point,
                        -normalControlAngle
                    ).getDistance(this.rotatePoint(
                        this.bounds.center,
                        -normalControlAngle
                    )
                ),
                polarity = lastMouseToCenterDistance > mouseToCenterDistance ? -1 : 1,
                mod = 1;

            sizeChange = new this.paper.Point(
                Math.abs(movement.x),
                Math.abs(movement.y)
            );

            // The sizeChange length needs to be the same after this equation,
            // stash it here so we can calculate the difference afterwards.
            const sizeChangeLength = sizeChange.length;

            // In order to maintain proportion, we need to adjust the
            // sizeChange to match the proportions of the shape
            if (sizeChange.x > sizeChange.y) {
                mod = this.height / this.width;
                sizeChange.y = mod * sizeChange.x;
                //sizeChange.x = maxSizeChange;
            } else {
                mod = this.width / this.height;
                sizeChange.x = mod * sizeChange.y;
            }

            // Calculate the difference in size change length and multiply
            // it so that it matches the length before the proportion
            // adjustment
            const sizeChangeModifier: number = sizeChangeLength / sizeChange.length;
            if (sizeChangeModifier)
                sizeChange = sizeChange.multiply(sizeChangeModifier);

            // Make sure x & y have the correct polarity(they're currently,
            // absolute values)
            sizeChange.x *= polarity;
            sizeChange.y *= polarity;

            position.x = sizeChange.x * .5;
            position.y = sizeChange.y * .5;
            position = this.rotatePoint(
                position.multiply(new this.paper.Point(
                    centerToControlWithoutRotationVector.x,
                    centerToControlWithoutRotationVector.y
                )),
                elementRadians
            );
        } else {
            // Negate the rotation in the size point so we can apply it
            // to model.width & model.height
            sizeChange = this.rotatePoint(
                event.point.subtract(event.lastPoint),
                -elementRadians
            )
            // Make sure we have the proper direction
                .multiply(new this.paper.Point(
                    centerToControlWithoutRotationVector.x,
                    centerToControlWithoutRotationVector.y
                ));

            // Since the position is from the center, we only need to adjust
            // the x and/or y position by 50%
            position = event.point.subtract(event.lastPoint)
                .multiply(.5);
        }

        // Prevent the user from making the area less than 25 px sq, they wont be able to select it again sense there is no zoom
        if (this.bounds.area < 25 && (sizeChange.x < 0 || sizeChange.y < 0)) {
            return;
        }

        let height, width;
        try {
            height = this.canvasService.workspace.artboard.height;
        }catch (e) {
            height = 700;
        }
        try {
            width = this.canvasService.workspace.artboard.width;
        }catch (e) {
            width = 700;
        }

        const maxSize = Math.sqrt((height * height) + (width * width));

        this.applyScaling(sizeChange, position, maxSize);
        this.onChange();
    }

    public applyScaling(sizeChange, position, maxSize) {
        if (this.model.maintain_proportion) {
            if (this.height + sizeChange.y > 1 && this.width + sizeChange.x > 1) {
                if ((this.width + sizeChange.x < maxSize && this.height + sizeChange.y < maxSize) || (sizeChange.x < 0 || sizeChange.y < 0)) {
                    this.width += sizeChange.x;
                    this.x += position.x;
                    this.height += sizeChange.y;
                    this.y += position.y;
                }
            }
        } else {
            if (this.width + sizeChange.x > 1) {
                if (this.width + sizeChange.x < maxSize || sizeChange.x < 0) {
                    this.width += sizeChange.x;
                    this.x += position.x;
                }
            }
            if (this.height + sizeChange.y > 1) {
                if (this.height + sizeChange.y < maxSize || sizeChange.y < 0) {
                    this.height += sizeChange.y;
                    this.y += position.y;
                }
            }
        }
    }

    public onChange() {}

    protected clampRotation(angle) {
        if (angle < 0) {
            return angle % 360 + 360;
        }
        else {
            return angle % 360
        }
    }

    onRotateControlDrag(event: paper.ToolEvent, control: paper.Path): void {
        this._isDragging = true;

        const point = event.point.subtract(event.lastPoint);
        if (this.rotation == null)
            this.rotation = 0;

        let mouseAngle =  Math.atan2(
                event.point.y - this.bounds.center.y,
                event.point.x - this.bounds.center.x
            ) * 180 / Math.PI,
            controlAngle = Math.atan2(
                control.bounds.point.y - this.bounds.center.y,
                control.bounds.point.x - this.bounds.center.x
            ) * 180 / Math.PI,
            base_rotation = this.clampRotation(mouseAngle - controlAngle),
            rotation = this.clampRotation(mouseAngle - this.clampRotation(controlAngle - this.rotation));

        rotation = parseFloat(rotation.toFixed(3));

        // Make anything between 3 and -3 snap to 0. This makes it easier to
        // reset the rotation back to the default position.
        // Don't do this for multi scaling because all of them are not guaranteed to hit the condition
        // at the same time and may bug out
        if (rotation < 3 && rotation > -3) {
            rotation = 0;
        }

        this.rotation = rotation;
        this.onChange();
    }

    rotate(deg, position) {
        this.rotation = this.clampRotation(this.rotation + deg);

        let pos = new this.paper.Point(this.x, this.y).rotate(deg, position);
        this.x = pos.x;
        this.y = pos.y;
    }

    /**
     * Called by SelectionManager when this element is unselected.
     */
    onUnselect(): void {
        this._isDragging = false;
        this._isSelected = false;
        this.updatePaper();
    }

    /**
     * Called by SelectionManager when this element is selected.
     */
    onSelect(): void {
        this._isSelected = true;
    }

    hoverTest(point: paper.Point): boolean {
        return this.hitTest(point);
    }

    hitTest(point: paper.Point): boolean {
        if (!this.elementBounds) return false;

        const boundingRect: paper.Path.Rectangle = new this.paper.Path.Rectangle(this.elementBounds);
        if (this.rotation)
            boundingRect.rotate(this.rotation);

        const result = boundingRect.contains(point);

        boundingRect.remove();

        return result;
    }

    getBoundsCenter() {
        return new this.paper.Rectangle(this.x, this.y, this.width, this.height)
    }

    correctElementOffCanvasPosition(point: paper.Point): paper.Point {
        if (!this.bounds || !this.canvasService.workspace)
            return point;

        let x: number = 0,
            y: number = 0,
            center: paper.Point = this.getBoundsCenter(),
            diff = point.subtract(center);

        if (center.x > this.canvasService.workspace.artboard.width) {
            x = this.canvasService.workspace.artboard.width - point.x + diff.x;
        }

        if (center.x < 0) {
            x = 0 - point.x + diff.x;
        }

        if (center.y > this.canvasService.workspace.artboard.height) {
            y = this.canvasService.workspace.artboard.height - point.y + diff.y;
        }

        if (center.y < 0) {
            y = 0 - point.y + diff.y;
        }

        point.x += x;
        point.y += y;

        return point;
    }

    /**
     * This method needs to be implemented on classes that extend BaseElement
     */
    abstract updatePaper();

    updatePaperOnFrame(){}

    centerHorizontally(): void {
        this.x = this.canvasService.workspace.artboard.width / 2;
    }

    centerVertically(): void {
        this.y = this.canvasService.workspace.artboard.height / 2;
    }

    /*
     * Removes and deletes all children on this element's layer. Also resets
     * the layer to the 1:1 scale and 0,0 position
     */
    clear() {
        if (!this.handleClear)
            return;

        this.layer.scale(1, 1);
        this.layer.position = new this.paper.Point(0, 0);
        this.layer.removeChildren();
    }

    safeClear() {
        // Makes sure that the canvas service is not in the export state before trying to modify it
        this.canvasService.withSideActive(() => {
            this.clear();
        }, this.side);
    }

    remove() {
        this._removed = true;
        
        if (this.canvasService.selection_manager.selection as any === this)
            this.canvasService.selection_manager.select(null);

        // Remove the element from the element manager
        this.element_manager.remove(this);

        // Tastypie won't remove the M2M relationship by removing it from the
        // *_elements list. We can either delete it here or add a custom
        // function to the resource that will figure out what to remove, add
        // and/or keep.
        if (this.model) {
            this.model.remove();
        }

        if (this.offset) {
            this.canvasService.removeOffset(this.offset);
            this.canvasService.flattenCutpaths();
        }

        // Clear this layer and then remove it from the project
        this.clear();
        this.layer.remove();

        // Unbind events
        this.unbindEvents();

        // Trigger design change to update the canvas preview for multi sided products
        Services.get<PaperCanvasService>('PaperCanvasService').trigger('design-change');

        this.trigger('remove');
    }

    softRemove() {
        this.element_manager.remove(this);

        this._removed = true;

        this.clear();
        this.layer?.remove();
        this.item?.remove();
        this.unbindEvents();
    }

    clearDebugItems(): void {
        if (!this.debugItems) {
            return
        }

        for (const item of this.debugItems) {
            item?.remove();
        }
        this.debugItems = [];
    }

    get locked(): boolean {
        return this._locked;
    }

    get lockedInPlace(): boolean {
        return this._locked;
    }

    set lockedInPlace(lockedInPlace: boolean){
        this._locked = lockedInPlace;
    }

    get showScaleControls(): boolean {
        return true;
    }

    get showRotateControl(): boolean {
        return true;
    }

    get movable(): boolean {
        return true;
    }

    isImmutable() {
        return !this.model.selectable && !this.model.can_remove;
    }

    get allow_clipping_when_locked(): boolean {
        return this.model.allow_clipping_when_locked;
    }

    protected addFrameTask(fnc: any, context: any, args?: any[]): void {
        // Don't allow adding the exact same frame task to prevent infinitely processing the same task
        if (this.current_frame_task) {
            if (this.current_frame_task.fnc === fnc && this.current_frame_task.context === context && this.current_frame_task.args === args) {
                return;
            }
        }
        this.frameTasks.push(new ElementFrameTask(fnc, context, args));
    }

    protected hasFrameTask(fnc: any, context: any): boolean {
        for (const t of this.frameTasks) {
            if (t.fnc === fnc && t.context === context)
                return true;
        }

        return false;
    }

    cloneItem(): paper.Item {
        return this.item.clone();
    }

    checkCanvasPosition() {
        const newPoint: paper.Point = this.correctElementOffCanvasPosition(new this.paper.Point(
            this.x,
            this.y
        ));

        if (this.x !== newPoint.x)
            this.x = newPoint.x;

        if (this.y !== newPoint.y)
            this.y = newPoint.y;
    }

    onFrame(event) {
        this.flushFrameTasks();
    }

    public flushFrameTasks() {
        if (this.frameTasks.length == 0) {
            return;
        }

        this.canvasService.withSideActive(() => {
            while ((this.current_frame_task = this.frameTasks.pop()) != null) {
                if (this.current_frame_task) {
                    this.current_frame_task.call();
                    delete this.current_frame_task;
                }
            }
            this.current_frame_task = null;
        }, this.side)
    }

    public runFrameTasks() {
        if (this.frameTasks.length == 0) {
            return;
        }

        this.canvasService.withSideActive(() => {
            for (const task of this.frameTasks) {
                if (task) {
                    task.call();
                }
            }
        }, this.side)
    }

    public hide(): void {
        this.item.visible = false;
    }

    public show(): void {
        this.item.visible = true;
    }

    public preSave(): void {
        return null;
    }

    public isTextType() {
        return false;
    }

    public isClipartType() {
        return false;
    }

    public isRasterType() {
        return false;
    }

    public isQRCodeType() {
        return false
    }

    public isShapeType() {
        return false;
    }

    public isMarketplaceType() {
        return false;
    }

    public isCropType() {
        return false;
    }

    public measurements(width, height) {
        let element_bounds = this.bounds;
        let used_size = this.element_manager.getUsedSize();

        let item_width = (element_bounds.width / used_size.width) * width;
        let item_height = (element_bounds.height / used_size.height) * height;

        return {'width': item_width, 'height': item_height};
    }

    get transformation_elements(): IElement[] {
        return [this];
    }

    get cropped() {
        return false;
    }
}
