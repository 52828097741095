import {ProductTypeEnum, ToolTypeEnum} from '../../models/enums';

export const html:string = `
<div v-if="productService && productService.configured && hasLowDPIFlag()">
    <div class="vectorize-box col-xs-12">
        <error-list field="lowquality" :errors="productService.configured.errors"></error-list>
        <p v-if="productService.base.type == ${ProductTypeEnum.PRINTED}">
            <i>Your sticker contains graphics that are lower than 300 dpi, and may look blurry at the selected size.</i>
        </p>
        <p v-if="productService.base.type != ${ProductTypeEnum.PRINTED}">
            <i>Your sticker contains graphics that are lower than 300 dpi, and we cannot make a transfer sticker with a low quality image.</i>
        </p>
        <div style="margin-bottom: 10px;" class="dpi-bar-container col-xs-12 no-padd" v-for="graphic in element_manager.lowDPIElements" v-if="productService.configured.tool_type != ${ToolTypeEnum.UPLOAD}">
            <div class="col-xs-12 no-padd">{{ graphic.model.label }}</div>
            <div  class="col-xs-12 no-padd">
                <div class="col-xs-6 quality-bar no-padd">
                    <div class="quality-bar-text">{{ imageQualityDescription(graphic.model.dpi) }}</div>
                    <div class="quality-bar-fill" :style="{'background-color': imageQualityColor(graphic.model.dpi), 'width' : widthPercentage(graphic.model.dpi)}"></div>
                </div>
                <div class="out-of">{{ graphic.model.dpi }}/300 dpi</div>
            </div>
        </div>
        <div style="margin-bottom: 10px;" class="dpi-bar-container col-xs-12 no-padd" v-if="productService.configured.tool_type == ${ToolTypeEnum.UPLOAD}">
            <div class="col-xs-12 no-padd">
                <div class="col-xs-6 quality-bar no-padd">
                    <div class="quality-bar-text">{{ imageQualityDescription(dpi) }}</div>
                    <div class="quality-bar-fill" :style="{'background-color': imageQualityColor(dpi), 'width' : widthPercentage(dpi)}"></div>
                </div>
                <div class="out-of">{{ dpi }}/300 dpi</div>
            </div>
        </div>
        <br>
        <p v-if="productService.base.type == ${ProductTypeEnum.PRINTED}"><i>Optionally, we will fix your graphics for an extra charge, or you can approve a low quality graphic.</i></p>
        <p v-if="productService.base.type != ${ProductTypeEnum.PRINTED}"><i>Optionally, we will fix your graphics for an extra charge, or you can upload a higher quality image.</i></p>
        <div class="options">
            <input id="vectorize-transfer" type="checkbox" name="vectorizeOption" :value="false" v-model="productService.configured.option_vectorize" />
            <label style="padding-left: 10px;" for="vectorize-transfer">Fix My Graphic</label> 
            <span class="charge-fee">+{{ $filters.currency(productService.configured.option_vectorize_price, productService.configured.currency) }}</span>
        </div>
    </div>
</div>
`;