import {ProductTypeEnum} from '../../models/enums';
import {TemplateSizeDisplayEnum} from '../../models/abstract/ProductSizeAbstract';

export const html: string = `
<div class="form-group template-setup-options-size" v-if="productService.base.size_display != ${TemplateSizeDisplayEnum.DISPLAY_NAME} && !productService.base.hide_size">
    <label class="setup-upload-hide options-label" for="id-width">{{ productService.showSizes ? 'Select ' : '' }}Size <sup>({{ ups.size_label }})</sup></label>
    <error-list field="size,max_size,width,height,max_sqin" :errors="productService.errors"></error-list>
    <div>
        <div v-if="productService.show_size_recommendation" class="yield-error size-warning">
            <p>The actual size of your design is <strong>{{ $filters.convertMeasurement(productService.recommended_width, true) }}</strong> x <strong>{{ $filters.convertMeasurement(productService.recommended_height, true) }}</strong>.  You can adjust your size below.</p>
        </div>
        <div v-if="productService.text_size_too_small && productService.base.type == ${ProductTypeEnum.CUTOUT}" class="error size-warning">
            <p>One or more text elements do not meet the minimum size of .25" (0.66cm) tall. Please increase the size of the text that is too small 
            or adjust the size of the design to be at least <strong>{{ $filters.convertMeasurement(productService.recommended_text_width, true) }}</strong> x <strong>{{ $filters.convertMeasurement(productService.recommended_text_height, true) }}</strong>.
            </p>
        </div>
        <div v-if="productService.text_size_too_small && productService.base.type == ${ProductTypeEnum.MULTICOLOR_CUTOUT}" class="yield-error size-warning">
            <p>One or more text elements do not meet the minimum size of .25" (0.66cm) tall. You may be asked to revise the design if the letters are too small to be cut out.
            </p>
        </div>
        <div class="size-input-group">
            <div class="width-group">
                <div class="label-group">
                    <label for="id-width" aria-label="Width">W</label>
                </div>
                <input aria-label="Width" :disabled="!productService.allow_custom_sizes" type="number" class="form-control" id="id-width" min="0" step="0.5" placeholder="Width" v-model="productService.unit_width">
            </div>
            <div class="height-group">
                <div class="label-group">
                    <label for="id-height" aria-label="Height">H</label>
                </div>
                <input aria-label="Height" :disabled="!productService.allow_custom_sizes" type="number" class="form-control" id="id-height" min="0" step="0.5" placeholder="Height" v-model="productService.unit_height">
            </div>

            <div v-if="productService.base.allow_custom_size" class="proportions" v-cloak>
                <div class="btn-group">
                    <button v-tooltip :tooltip-content="productService.proportions_ratio == null ? 'Lock Size Ratio' : 'Unlock Size Ratio'" aria-label="Lock Size Ratio" class="btn proportions-btn" @click="productService.toggleLockedProportions()" :class="{ 'disabled': !productService.allow_custom_sizes }">
                        <i class="fa fa-unlock" v-show="productService.proportions_ratio == null"></i>
                        <i class="fa fa-lock" v-show="productService.proportions_ratio != null" v-cloak></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="productService.show_custom_sizes" class="setup-upload-hide">
        <div v-if="productService.show_sizes">
            <table class="options table">
                <tr v-if="productService.base.allow_custom_size">
                    <td class="td-input"><input type="radio" :value="null" name="size" v-model="productService.size_by_id"/></td>
                    <td>
                        <label for="size-radio-custom">Custom Size</label>
                    </td>
                </tr>
                <tr v-for="(size, $index) in productService.recommended_sizes" v-show="($index < 5 || show_more_sizes || productService.size_by_id == size.id)">
                    <td>
                        <input class="td-input" :id="'size-radio-' + size.id" type="radio" :value="size.id" name="size" v-model="productService.size_by_id" />
                    </td>
                    <td>
                        <label :for="'size-radio-' + size.id">
                            {{ size.label }}
                        </label>
                    </td>
                </tr>
            </table>
        </div>
        <div v-show="productService.recommended_sizes.length > 5">
            <button class="more-price-breaks" @click="show_more_sizes = !show_more_sizes">
                <div class="icon-group">
                    <hr><i class="far fa-chevron-double-down" :class="{reverse: show_more_sizes}"></i><hr>
                </div>
            </button>
        </div>
    </div>
</div>
<div v-if="productService.base.size_display == ${TemplateSizeDisplayEnum.DISPLAY_NAME} && !productService.base.hide_size">
    <label class="setup-upload-hide options-label">Select Size</label>
    <error-list field="size,max_size,width,height,max_sqin" :errors="productService.errors"></error-list>
    <generic-dropdown :items="productService.recommended_sizes" v-model:selected="productService.size_by_id" :field="'id'"></generic-dropdown>
</div>
<div v-if="productService.base.hide_size">
    <div v-if="productService.text_size_too_small && productService.base.type == ${ProductTypeEnum.CUTOUT}" class="error size-warning">
        <p>
            One or more text elements do not meet the minimum size of .25" (0.66cm) tall. Please increase the size of the text that is too small.
        </p>
    </div>
    <div v-if="productService.text_size_too_small && productService.base.type == ${ProductTypeEnum.MULTICOLOR_CUTOUT}" class="yield-error size-warning">
        <p>
            One or more text elements do not meet the minimum size of .25" (0.66cm) tall. You may be asked to revise the design if the letters are too small to be cut out.
        </p>
    </div>
</div>
`;
