import {SymbolTypeName} from '../../../models/abstract/VariableDataElementAbstract';
import {html} from './VariableDataSelector.html';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import VueComponent, {data, prop} from '../../../../../core/adapters/VueComponent';
import CustomEditorService from '../../../services/CustomEditorService';

class VariableDataSelectorController extends VueComponent {

    @prop()
    productService: CustomEditorService;

    @prop()
    element: any;

    @data()
    show_type_option: boolean;

    @data()
    limited_types: SymbolTypeName[] = [
        SymbolTypeName.NORMAL_TEXT
    ];
}


export default function VariableDataSelector(): IVueComponent {
    return {
        controller: VariableDataSelectorController,
        template: html,
        tag: 'variable-data-selector'
    };
}
