import VueComponent, {data, emit, method, prop} from '../../../../core/adapters/VueComponent';
import {SymbolTypeName} from '../../models/abstract/VariableDataElementAbstract';
import CustomEditorService from '../../services/CustomEditorService';
import {ElementManager} from '../../services/ElementManager';
import PaperCanvasService from '../../services/PaperCanvasService';
import {Services} from '../../../../core/services/Services';
import {ProductTypeEnum} from '../../models/enums';
import {SelectionManager} from '../../services/SelectionManager';
import MessageList from '../../../../core/utils/MessageList';
import {TextElement} from '../../tool/TextElement';
import {Color} from '../../models/Color';

export default abstract class AbstractDesignToolController extends VueComponent {

    @emit()
    updateSelection: () => void;

    @prop()
    element: any;

    @prop()
    productService: CustomEditorService;

    @prop({
        emit: true
    })
    activeTab: string;

    @data()
    element_manager: ElementManager;

    @data()
    canvas_service: PaperCanvasService;

    @data()
    selection_manager: SelectionManager;

    @data()
    text_types = [
        SymbolTypeName.NORMAL_TEXT,
        SymbolTypeName.NUMBER_RANGE,
        SymbolTypeName.CSV_TEXT
    ];

    @data()
    limited_text_types = [
        SymbolTypeName.NORMAL_TEXT
    ];

    @data()
    errors: MessageList;

    @data()
    on_path_color_change;

    constructor(component) {
        super(component);

        this.element_manager = Services.get<ElementManager>('ElementManager');
        this.canvas_service = Services.get<PaperCanvasService>('PaperCanvasService');
        this.selection_manager = Services.get<SelectionManager>('SelectionManager');

        this.errors = new MessageList();

        this.on_path_color_change = this.onColorChange.bind(this);
    }

    onColorChange(item) {
        if (this.productService.configured.offset_path_fill_color) {
            this.canvas_service.offset_path_color = `#${this.productService.configured.offset_path_fill_color.code}`;

            if (this.productService.configured.offset_path_fill_color.texture) {
                this.canvas_service.offset_path_texture = this.productService.configured.offset_path_fill_color.texture;
            }
        }
        else if (this.productService.configured.offset_path_custom_fill_color) {
            this.canvas_service.offset_path_color = this.productService.configured.offset_path_custom_fill_color;
        }
        else {
            this.canvas_service.offset_path_color = '#ffffff';
        }

        if (item instanceof Color) {
            this.productService.configured.offset_path_custom_fill_color = null;
        }
        else {
            this.productService.configured.offset_path_fill_color = null;
        }
    }

    @method()
    isSingleColor() {
        return this.productService.base.type == ProductTypeEnum.CUTOUT;
    }

    @method()
    changeStrokeWidth(item: any, change: number, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        let new_width = item.stroke_width + change;
        if (new_width < 0) {
            new_width = 0;
        }
        if (new_width > 10) {
            new_width = 10;
        }

       item.stroke_width = new_width;
    }

    @method()
    scaleSize(percentage: number, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        if (this.element.width && this.element.height && !this.element.model.path) {
            // Scale based on percentage of the canvas so the button will always increase the same amount
            let canvas_width = this.canvas_service.rootLayer.bounds.width * percentage;
            let scale = 1 + (canvas_width / this.element.width);

            if (scale > 1.15) {
                scale = 1.15;
            }
            if (scale < 0.85) {
                scale = 0.85
            }

            this.element.width = this.element.width * scale;
            this.element.height = this.element.height * scale;
        }
        else {
            if (percentage > 0) {
                this.element.model.size += 10;
            } else {
                this.element.model.size -= 10;
            }
        }
    }

    @method()
    elementHasAdvancedOptions() {
        if (!this.element) {
            return false;
        }

        return (this.element.model.can_remove == true || this.element.model.can_remove == undefined) && !this.element.isImmutable();
    }

    @method()
    public isSelectionText() {
        return !this.selection_manager.isMultiSelection && this.selection_manager.selection && this.selection_manager.selection.isTextType();
    }

    @method()
    public isSelectionRaster() {
        return !this.selection_manager.isMultiSelection && this.selection_manager.selection && this.selection_manager.selection.isRasterType();
    }

    @method()
    public isSelectionQRCode() {
        return !this.selection_manager.isMultiSelection && this.selection_manager.selection && this.selection_manager.selection.isQRCodeType();
    }

    @method()
    public isSelectionClipart() {
        return !this.selection_manager.isMultiSelection && this.selection_manager.selection && this.selection_manager.selection.isClipartType();
    }

    @method()
    public isSelectionMarketplace() {
        return !this.selection_manager.isMultiSelection && this.selection_manager.selection && this.selection_manager.selection.isMarketplaceType();
    }

    @method()
    public isSelectionShape() {
        return !this.selection_manager.isMultiSelection && this.selection_manager.selection && this.selection_manager.selection.isShapeType();
    }

    @method()
    public isSelectionCrop() {
        return !this.selection_manager.isMultiSelection && this.selection_manager.selection && this.selection_manager.selection.isCropType();
    }

    @method()
    public isSelectionNone() {
        return !this.selection_manager.selection;
    }

    @method()
    toggleFontScaling($event?) {
        if ($event) {
            $event.preventDefault();
        }

        let to_lock = !this.selection_manager.selection.model.maintain_proportion;

        if (to_lock) {
            this.selection_manager.selection.model.maintain_proportion = true;
            (this.selection_manager.selection as TextElement).font_scale = true;
        }
        else {
            this.selection_manager.selection.model.maintain_proportion = false;
            (this.selection_manager.selection as TextElement).font_scale = false;
        }

        (this.selection_manager.selection as TextElement).auto_scale = false;
    }

    @method()
    moveElementDown($event, selected_element?) {
        if ($event) {
            $event.preventDefault();
        }

        setTimeout(() => {
            if (selected_element) {
                this.element_manager.moveElementDown(selected_element, $event);
            }
            else {
                this.element_manager.moveElementDown(this.element, $event);
            }
        }, 200);
    }

    @method()
    moveElementUp($event, selected_element?) {
        if ($event) {
            $event.preventDefault();
        }

        setTimeout(() => {
            if (selected_element) {
                this.element_manager.moveElementUp(selected_element, $event);
            }
            else {
                this.element_manager.moveElementUp(this.element, $event);
            }
        }, 200);
    }

    @method()
    sides() {
        if (!this.canvas_service.sides) {
            return [];
        }

        return this.canvas_service.sides.map(v => v.model).filter(v => v);
    }
}
