import {html} from './Canvas.html';
import DragDropBuyService from '../../services/DragDropBuyService';
import VueComponent, {data} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {Services} from '../../../../core/services/Services';

export class DragDropBuyCanvasController extends VueComponent {

    @data()
    product_service: DragDropBuyService

    constructor(component) {
        super(component)
        this.product_service = Services.get<DragDropBuyService>('DragDropBuyService');
    }
}

export default function DragDropBuyCanvas(): IVueComponent {
    return {
        controller: DragDropBuyCanvasController,
        template: html,
        tag: 'drag-drop-buy-canvas'
    }
};
