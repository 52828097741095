export const html = `
<div v-for="element in elementSet()">
    <div v-for="region in element.model.regions" :class="{'important-options-area': region.region.type == 1 && region.hasStroke}">
        <div class="right-aligned-options-area">
            <div class="tool-selection-set">
                <div class="left-side">
                    <label class="options-label">{{ region.region.name }}</label>
                </div>
                <div class="right-side" v-if="!region.use_material_colors">
                    <generic-color-dropdown :items="productService.colors_available" v-model:selected="region.regionColor" :trigger-function="updateBackgroundColorCallback" :trigger-scope="region" :none="'Custom Color: ' + region.custom_color" :none-color="region.custom_color" :hide-none="true"></generic-color-dropdown>
                </div>
                <div class="right-side" v-if="region.use_material_colors">
                    <generic-color-dropdown :items="productService.colors_available_for_material" v-model:selected="region.regionColor" :trigger-function="updateBackgroundColorCallback" :trigger-scope="region"></generic-color-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>
`
