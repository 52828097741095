import {ClipTypeEnum} from '../../models/enums';

export const html:string = `
<div class="template-setup-options-shapes clearfix" 
    v-if="productService.base.template_shapes.length > 0">
    <div class="form-group options-shapes clearfix col-xs-12 col-full-left" v-if="templateShapes().length > 1" >
        <label class="options-label">Select Shape</label> 
        <div class="select-shapes-list">
            <div v-for="shape in $filters.orderBy(templateShapes(), 'order')" 
                 class="shape-button-container" 
                 :class="{'show-selected': isShapeSelected(shape)}" 
                 @click="setNewShape(shape)">
                 
                <img class="shape-unselected" :src="shape.button_unselected">
                <img class="shape-hover" :src="shape.button_hover"> 
                <img class="shape-selected" :src="shape.button_selected">
            </div>
        </div>
    </div>
    <div class="form-group options-shapes clearfix col-xs-6 col-full-left" v-for="shape in customShapes().filter(v => v.template_shape.clip_type == ${ClipTypeEnum.ROUNDED_CORNER})" :key="shape.template_shape.id">
        <div class="radius-container">
            <label class="options-label">Roundness</label> 
            <input placeholder="Radius" type="range" min="1" max="40" class="slider" id="myRange" v-model="shape.clip_radius">
        </div>
        <div class="radius-icon">
            <div id="round-sample" class="pull-left">
                <svg xmlns="http://www.w3.org/2000/svg"
                     :view-box.camel="viewbox" width="100%" height="100%"> 
                     <path :d="svgContent(shape)" fill="#1a74bb"></path>
                </svg>
            </div>
        </div>
    </div>
</div>
`;
