import {DisplayTypeEnum} from '../../models/enums';
import {TemplateOptionSet} from '../../models/TemplateOptionSet';
import {TemplateOptionChargeOption} from '../../models/TemplateOption';

export const html:string = `
<div class="responsive-grid col-full template-finalize-options template-finalize-options-grid" v-if="hasOptions">
    <!---LAMINATION--->
    <div class="gc-xs-12 gc-xs-full gc-md-6" :class="{'gc-sm-6': productService.full_width_options}" v-show="productService.base.option_laminate && productService.configured.material.can_laminate">
        <div class="form-group">
            <label class="options-label">
                Select Lamination <i class="far fa-info-circle" @click="show_lamination_description = !show_lamination_description"></i>
            </label>
            <div class="option-help-text" v-show="show_lamination_description">
                Having your stickers laminated will add an additional 1-3 year lifespan to it as well as making it resistant to scratches and acid rain. Lamination is free.
            </div>
            <div class="options" v-for="item in productService.configured.laminationOptions">
                <input :aria-label="item.label" :id="'lamination-' + item.id" type="radio" name="laminationOption" :value="item.id" v-model="productService.configured.option_laminate" />
                <label style="padding-left: 10px;"  :for="'lamination-' + item.id">{{ item.label }}</label>
            </div>
        </div>
    </div>
    
    <div class="gc-xs-12 gc-xs-full gc-md-6" :class="{'gc-sm-6': productService.full_width_options}" v-if="reverseCut && productService.base.option_reverse_cut">
        <div class="form-group">
            <label class="options-label">Invert Design</label>
            <div class="invert-image">
                <input aria-label="Invert Design Option" type="checkbox" v-model="productService.configured.option_reverse_cut">&nbsp;
                <span class="text-input-ghost" v-show="!productService.configured.option_reverse_cut" @click="productService.configured.option_reverse_cut = true">Not Inverted</span>
                <span class="text-input-ghost" v-show="productService.configured.option_reverse_cut" @click="productService.configured.option_reverse_cut = false">Inverted</span>
            </div>
        </div>
    </div>
    
    <!---BORDER--->  
    <div class="gc-xs-12 gc-xs-full gc-md-6 gc-full-left" :class="{'gc-sm-6': productService.full_width_options}" v-show="productService.base.option_border && !productService.base.offset_path_uses_material_color">
        <div class="form-group">
            <label class="options-label">
                Select Border <i class="far fa-info-circle" @click="show_border_description = !show_border_description"></i>
            </label>
            <div class="option-help-text" v-show="show_border_description">
                The Add Border option increases the space between the design and the edge of the sticker. The size of the border is typically around .25 inches, but can vary depending on the design.
            </div>
            <div class="options" v-for="item in productService.configured.borderOptions">
                <input :aria-label="item.label" :id="'border-' + item.id" type="radio" name="borderOption" :value="item.id" v-model="productService.configured.option_border"/>
                <label style="padding-left: 10px;" :for="'border-' + item.id">
                    {{ item.label }}
                </label>
            </div>
        </div>
    </div> 
    
    <!---CUSTOM ITEMS--->
    <div :class="{'gc-xs-12': !custom_option.option_set.small_option, 'gc-md-6 gc-xs-12': custom_option.option_set.small_option}" v-for="custom_option in productService.configured.options">
        <div class="form-group">
            <div v-if="custom_option.option_set" :class="{'disabled-option': !custom_option.option_set.optionEligible(productService.configured.width, productService.configured.height) || custom_option.isDisabled(productService.configured.material)}">
                <label class="options-label">
                    {{ custom_option.option_set.display_type == ${DisplayTypeEnum.FIXED_VALUE} || readOnly ? '' : 'Select ' }}{{ custom_option.option_set.name }}
                    <i class="far fa-info-circle" @click="custom_option.show_help_text = !custom_option.show_help_text" v-if="custom_option.option_set.help_text"></i>
                </label>
                <div class="option-short-description" v-if="custom_option.option_set.short_description">{{ custom_option.option_set.short_description }}</div>
                
                <div class="option-help-text" v-show="custom_option.show_help_text" v-html="custom_option.option_set.help_text"></div>
                
                <div v-if="!custom_option.option_set.optionEligible(productService.configured.width, productService.configured.height)" class="disabled-option-reason">
                    This option is not available for the selected size.
                </div>
                <div v-if="custom_option.isDisabled(productService.configured.material)" class="disabled-option-reason">
                    This option is not available for the selected material.
                </div>
                
                <error-list field="option" :errors="custom_option.errors"></error-list>
                
                <div v-if="custom_option.option_set.optionEligible(productService.configured.width, productService.configured.height) && !custom_option.isDisabled(productService.configured.material)"> 
                    <div v-if="custom_option.option_set.display_type == ${DisplayTypeEnum.RADIO}">
                        <div class="options" v-for="item in custom_option.option_set.options">
                            <input :aria-label="item.name" :id="idForOptionSet(custom_option, item)" type="radio" v-model="custom_option.option_id" :value="item.id" :disabled="readOnly"/>
                            <label :for="idForOptionSet(custom_option, item)">
                                {{ item.name }}
                                <i class="option-price" v-if="item.percent != 0 && item.charge_option == ${TemplateOptionChargeOption.CHARGE_PERCENTAGE}">+{{ $filters.shortNumber(item.percent) }}%</i>
                                <i class="option-price" v-if="item.amount != 0 && item.charge_option == ${TemplateOptionChargeOption.CHARGE_TOTAL}">+{{ $filters.currency(item.amount, productService.configured.currency) }}</i>
                                <i class="option-price" v-if="item.amount != 0 && item.charge_option == ${TemplateOptionChargeOption.CHARGE_UNIT}">+{{ $filters.currency(item.display_value(productService.show_retail), productService.configured.currency) }} each</i>
                            </label>
                        </div>
                    </div> 
                    <div v-if="custom_option.option_set.display_type == ${DisplayTypeEnum.CHECKBOX}">
                        <div class="options" v-for="item in custom_option.option_set.options">
                            <input aria-label="item.name" :id="idForOptionSet(custom_option, item)" type="checkbox" @click="toggleOption(custom_option, item)" :checked="custom_option.option == item" :disabled="readOnly"/>
                            <label :for="idForOptionSet(custom_option, item)">
                                {{ item.name }}
                                <i class="option-price" v-if="item.percent != 0 && item.charge_option == ${TemplateOptionChargeOption.CHARGE_PERCENTAGE}">+{{ $filters.shortNumber(item.percent) }}%</i>
                                <i class="option-price" v-if="item.amount != 0 && item.charge_option == ${TemplateOptionChargeOption.CHARGE_TOTAL}">+{{ $filters.currency(item.amount, productService.configured.currency) }}</i>
                                <i class="option-price" v-if="item.amount != 0 && item.charge_option == ${TemplateOptionChargeOption.CHARGE_UNIT}">+{{ $filters.currency(item.display_value(productService.show_retail), productService.configured.currency) }} each</i>
                            </label>
                        </div>
                    </div>
                    <div style="position: relative;" v-if="custom_option.option_set.display_type == ${DisplayTypeEnum.DROPDOWN}">
                        <generic-dropdown v-show="!readOnly" :items="custom_option.option_set.options" v-model:selected="custom_option.option" :name-function="getOptionItemNameCallback(productService, custom_option.option_set)"></generic-dropdown>
                        <span v-show="readOnly">{{ getOptionItemName(productService, custom_option.option_set, custom_option.option) }}</span>
                    </div>
                    <div style="position: relative;" v-if="custom_option.option_set.display_type == ${DisplayTypeEnum.FIXED_VALUE}">
                        <span>{{ getOptionItemName(productService, custom_option.option_set, custom_option.option) }}</span>
                        <i class="option-price" v-if="custom_option.option.percent != 0 && custom_option.option_set.charge_option == ${TemplateOptionChargeOption.CHARGE_PERCENTAGE}">+{{ $filters.shortNumber(custom_option.option.percent) }}%</i>
                        <i class="option-price" v-if="custom_option.option.amount != 0 && custom_option.option_set.charge_option == ${TemplateOptionChargeOption.CHARGE_TOTAL}">+{{ $filters.currency(custom_option.option.amount, productService.configured.currency) }}</i>
                        <i class="option-price" v-if="custom_option.option.amount != 0 && custom_option.option_set.charge_option == ${TemplateOptionChargeOption.CHARGE_UNIT}">+{{ $filters.currency(custom_option.option.display_value(productService.show_retail), productService.configured.currency) }} each</i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group gc-xs-12 gc-full" v-if="!hideRetailPackaging && productService.configured.retail_package">
        <label class="options-label">
            Retail Package
        </label>
        <div v-show="productService.configured.retail_package.package.getValidSizeFor(productService.configured.width, productService.configured.height)">
            <span>{{ productService.configured.retail_package.package.name }}</span> 
            <i class="option-price">+{{ $filters.currency(productService.configured.retail_package.priceFor(productService.configured.quantity).unitPrice, productService.configured.currency) }} each</i>
        </div>
        <div v-show="!productService.configured.retail_package.package.getValidSizeFor(productService.configured.width, productService.configured.height)">
            <i>The product no longer fits the available package sizes</i>
        </div>
    </div>
</div>
`;