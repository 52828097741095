import {html} from './ClipartEditor.html';
import DragDropBuyService from '../../services/DragDropBuyService';
import {DragDropBuyElement} from '../../tool/DragDropBuyElement';
import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {IElement} from '../../tool/IElement';

export class DragDropBuyClipartEditorController extends VueComponent{
    static override $inject = [
        'DragDropBuyService'
    ];

    @data()
    element: IElement;

    @data()
    product_service: DragDropBuyService

    constructor(component, private productService: DragDropBuyService) {
        super(component)
        this.product_service = productService
        this.product_service.bind('activated:element', () => {
            this.focusSelection()
        }, this);
        this.productService.canvasService.selection_manager.bind('selection:change', () => {
            this.focusSelection()
        })
    }

    focusSelection() {
        this.fetchSelection()
        if(!this.element) {
            return;
        }

        setTimeout(() => {
            let x = window.scrollX, y = window.scrollY;
            let el = document.getElementById('ddbElementName');
            if (el) {
                el.focus();
                el.click();
            }
            window.scrollTo(x, y);
        }, 100)
    }

    @method()
    save() {
        this.product_service.canvasService.selection_manager.select(null);
        this.fetchSelection()
    }

    @method()
    remove() {
        this.element.remove();
    }

    fetchSelection() {
        this.element = this.productService.canvasService.selection_manager.selection
    }
}

export default function DragDropBuyClipartEditor(): IVueComponent {
    return {
        controller: DragDropBuyClipartEditorController,
        template: html,
        tag: 'drag-drop-buy-clipart-editor'
    }
};
