export const html:string = `
<div class="tool-tab">
    <label><i class="fa fa-font"></i> Add Custom Text</label>
    <div class="top-aligned-options-area">
        <div class="tool-selection-set">
            <!-- Single Input Text Types -->
            <div class="expanding-item" v-show="isSingleTextInput()">
                <span>Custom Text</span>
            </div>
            <div v-show="isSingleTextInput()">
                <textarea v-model="new_text"></textarea>
            </div>
    
            <!-- Multiple Input Text Types -->
            <div class="expanding-item" v-show="!isSingleTextInput()">
                <span>Number Range Start</span>
            </div>
            <div v-show="!isSingleTextInput()">
                <textarea class="shorted-text-types" v-model="new_text"></textarea>
            </div>
            <div class="expanding-item" v-show="!isSingleTextInput()">
                <span>Number Range End</span>
            </div>
            <div v-show="!isSingleTextInput()">
                <textarea class="shorted-text-types" v-model="new_text_end"></textarea>
            </div>
        </div>
    </div>
    <div class="top-aligned-options-area">
        <div class="tool-selection-set col-xs-6 col-full" v-show="!productService.defined_quantity">
            <span>Text Type</span>
            <div v-if="productService.base.show_variable_data">
                <generic-dropdown v-if="productService.configured.defined_quantity && (!element || !element.model.definesQuantity)" :items="limited_text_types" v-model:selected="new_text_type"></generic-dropdown>
                <generic-dropdown v-if="!(productService.configured.defined_quantity && (!element || !element.model.definesQuantity))" :items="text_types" v-model:selected="new_text_type"></generic-dropdown>
            </div>
            <div v-if="!productService.base.show_variable_data">
                <generic-dropdown :items="limited_text_types" v-model:selected="new_text_type"></generic-dropdown>
            </div>
        </div>
        <div class="tool-selection-set col-xs-6 col-full" :class="{'col-xs-12': productService.defined_quantity, 'col-xs-6': !productService.defined_quantity}">
            <span>&nbsp;</span>
            <button class="btn-ghost btn-sm pull-right" @click="addText()">Add Text</button>
        </div>
    </div>
</div>
`;