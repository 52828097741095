import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './BackgroundColors.html';
import {data, method} from '../../../../../core/adapters/VueComponent';

class DesignToolBackgroundColorsController extends AbstractDesignToolController {

    @data()
    update_product_color;

    constructor(props) {
        super(props);

        this.update_product_color = this.updateProductColor.bind(this);
    }


    updateProductColor(color) {
        this.productService.configured.material_color = color;
    }

    @method()
    removeBackgroundBorder(item) {
        item.model.background_shape_border = false;
        item.updatePaper();
    }

    @method()
    public scaleBorder(item, percentage: number, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        let new_value =  Math.ceil(item.model.background_shape_border_size * percentage);

        if (item.model.background_shape_border_size != new_value) {
            item.model.background_shape_border_size = new_value;
        }
        else {
            if (percentage > 1) {
                item.model.background_shape_border_size += 1;
            }
            else {
                item.model.background_shape_border_size -= 1;
            }
        }

        if (item.model.background_shape_border_size > 150) {
            item.model.background_shape_border_size = 150;
        }
        else if (item.model.background_shape_border_size < 0) {
            item.model.background_shape_border_size = 0;
        }

        if (item.model.background_shape_border_margin < item.model.background_shape_border_size) {
            item.model.background_shape_border_margin = item.model.background_shape_border_size;
        }
    }
}


export default function DesignToolBackgroundColors(): IVueComponent {

    return {
        controller: DesignToolBackgroundColorsController,
        template: html,
        tag: 'design-tool-background-colors'
    };
}
