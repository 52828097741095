export const html:string = `
<div class="autosave-component-container">
    <div class="autosave-component" v-show="is_ready && !productService.saving">
        <div class="input-group">
            <input type="text" placeholder="Untitled Design" v-model="autosave.save_name">
            <button class="save" @click="autosave.save(true)"><i class="fa fa-save"></i></button>
        </div>
        <div class="last-saved">
            <span v-show="autosave.last_updated">Last saved at {{ $filters.date(autosave.last_updated, 'short') }}</span> &nbsp;
        </div>
    </div>
</div>
`;