import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './Layers.html';
import {prop} from '../../../../../core/adapters/VueComponent';

class DesignToolLayersController extends AbstractDesignToolController {
    @prop()
    all: boolean;
}


export default function DesignToolLayers(): IVueComponent {

    return {
        controller: DesignToolLayersController,
        template: html,
        tag: 'design-tool-layers'
    };
}
