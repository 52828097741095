import {BaseElement} from './BaseElement';
import {IClipart} from '../models/IClipart';
import {Color} from '../models/Color';
import {RegionType} from '../models/enums';
import {CustomClipart} from '../models/CustomClipart';
import {EClipartColorChoiceEnum} from '../models/Clipart';

export class ClipartElement extends BaseElement {
    lastRotation: number;
    area: number;
    xScaleInvertedLast: boolean;
    yScaleInvertedLast: boolean;
    showOptions: boolean;
    url: string;
    loadedClipart: boolean;
    preventUpdate: boolean;
    is_masked: boolean;

    constructor(url: string, clipart: IClipart, layer?: paper.Layer) {
        super(clipart, layer);

        this.model = clipart;
        this.area = 0;

        this.xScaleInvertedLast = false;
        this.yScaleInvertedLast = false;
        this.preventUpdate = false;

        this.model.bind('change:x_scale_inverted', () => {
            if (this.x_scale_inverted !== this.model.x_scale_inverted) {
                this.x_scale_inverted = this.model.x_scale_inverted;
                this.updatePaper();
            }
        });
        this.model.bind('change:y_scale_inverted', () => {
            if (this.y_scale_inverted !== this.model.y_scale_inverted) {
                this.y_scale_inverted = this.model.y_scale_inverted;
                this.updatePaper();
            }
        });
        this.bind('change:x_scale_inverted', () => {
            if (this.x_scale_inverted !== this.model.x_scale_inverted) {
                this.model.x_scale_inverted = this.x_scale_inverted;
                this.updatePaper();
            }
        });
        this.bind('change:y_scale_inverted', () => {
            if (this.y_scale_inverted !== this.model.y_scale_inverted) {
                this.model.y_scale_inverted = this.y_scale_inverted;
                this.updatePaper();
            }
        });

        this.url = url;
        this.loadedClipart = false;
        this.setupClipart();

        this.bindModels();
    }

    protected setupClipart() {
        this.layer.activate();
        this.layer.importSVG(this.url, {
            onLoad: (item, content) => {
                /*
                    Paper js converts the viewBox into a rectangle in order to maintain the same look functionality
                    however this breaks things when using clipping masks so we will just remove it for ones that
                    support masks
                 */
                if (this.single_color && item.children.length > 1) {
                    if (item.children[0].type == 'rectangle') {
                        item.children[0].remove();
                    }
                }

                this.item = item;

                /*
                    SVG Clipping masks have issues with groups and can fail to render the element or the mask. So if we want to use
                    the material color feature reliably then we need to flatten all elements into a single compound path within a layer.

                    This won't work for multi-color clipart so limit the functionality to ones flagged as single color
                 */
                if (this.single_color) {
                    this.unite();
                    this.preventUpdate = false;
                }

                this.loadedClipart = true;
                this.fillInValuesFromPaper();
                this.updatePaper();
            },
            onError: (message, status) => {
                // This is needed to make sure the error propagates correctly
                throw message
            }
        });
    }

    get single_color() {
        return this.model.clipart && this.model.clipart.colors == EClipartColorChoiceEnum.SINGLE_COLOR;
    }

    public hasCutpathRegion() {
        return !!this.cutpathRegion;
    }

    get cutpathRegion() {
        for (const region of this.model.regions) {
            if ((region as any).region.cutpath) {
                return region;
            }
        }

        return null;
    }

    public buildClippingRegion() {
        if (!this.loadedClipart) {
            return [];
        }

        let paths = [];
        if (this.hasCutpathRegion()) {
            let region = this.cutpathRegion;

            let items: paper.Item[] = [];
            if (this.item) {
                items = this.single_color ? [this.item] : this.item.getItems({
                    match: (i) => {
                        return region.selectorId.indexOf(i.name) > -1 || region.selectorId.indexOf(i.className.toLowerCase()) > -1;
                    }
                });
            }

            for (const item of items) {
                this.findPaths(item, paths);
            }
        }
        else {
            this.findPaths(this.item, paths)
        }

        return paths;
    }

    override remove() {
        super.remove();
        this.canvasService.buildClippingMask();
    }

    bindModels(): void {
        this.model.bind('change', this.updatePaper, this);
        for (const region of this.model.regions) {
            region.bind('change', this.updatePaper, this);
        }
    }

    unbindModels(): void {
        this.model.unbindWithContext('change', this);
        for (const region of this.model.regions) {
            region.unbindWithContext('change', this);
        }
    }

    updatePaper(): void {
        // This task needs to be done in a frame task so it won't execute more than once per frame which
        // happens with drag events. Its very slow and needs to be optimized.
        const tsk: () => void = this.updatePaperOnFrame;
        if (!this.hasFrameTask(tsk, this)) {
            this.addFrameTask(tsk, this);
        }
    }


    override updatePaperOnFrame(): void {
        if (this.preventUpdate) {
            return;
        }

        this.layer.activate();
        this.clearDebugItems();

        /**
         * Nothing to update if we don't have a paper.Item
         * Changes to the model can cause this to be called before the clipart
         * has been loaded.
         */
        if (!this.item) {
            return;
        }

        // Idk what is causing this to happen but lets enforce it
        if (this.layer.children.length == 0) {
            this.layer.addChild(this.item);
        }

        // Reset rotation
        if (this.lastRotation) {
            this.item.rotate(-this.lastRotation);

            // Keep offset path rotation in sync while waiting for it to update
            if (this.offset) {
                this.offset.rotate(-this.lastRotation);
            }
        }

        let start_size = this.item.bounds.size;

        // Scale
        if (this.width && this.height) {
            let scaleXMod = this.x_scale_inverted === this.xScaleInvertedLast ? 1 : -1;
            let scaleYMod = this.y_scale_inverted === this.yScaleInvertedLast ? 1 : -1;

            this.xScaleInvertedLast = this.x_scale_inverted;
            this.yScaleInvertedLast = this.y_scale_inverted;

            this.item.bounds.width = this.width * scaleXMod;
            this.item.bounds.height = this.height * scaleYMod;

            if (this.offset) {
                this.offset.bounds.width *= scaleXMod;
                this.offset.bounds.height *= scaleYMod;
            }
        }

        // Update position
        // Clipart origin is it's center
        this.item.position = new this.paper.Point(
            this.x,
            this.y
        );

        // Generating a offset path takes too long for a frame task so we will have to manually apply
        // scaling and transformations so its hard to tell there was a change when the old offset path is swapped
        // for a updated one
        if (this.offset) {
            let width_change = this.item.bounds.width / start_size.width;
            let height_change = this.item.bounds.height / start_size.height;

            this.offset.scale(width_change, height_change);
            this.offset.position = this.item.position;
        }

        // Rotate layer --- Transforms are unsafe below this point (or just a pain) ---
        if (this.rotation != null) {
            this.item.rotate(this.rotation);

            // Keep offset path rotation in sync while waiting for it to update
            if (this.offset) {
                this.offset.rotate(this.rotation);
            }

            this.lastRotation = this.rotation;
        }

        this.updateColors();

        if (!this.canvasService.clippingDisabled) {
            this.canvasService.buildClippingMask();

            // setting item to not visible will cause the bounds to go to nan and break things
            this.item.opacity = this.model.use_as_mask ? 0 : 1;
        }

        this.queueOffsetPath();

        this.trigger('updatePaper', this);
        this.canvasService.trigger('design-change');

        if (!this.initialized_deferred.promise.isResolved()) {
            this.initialized_deferred.resolve();
        }
    }

    override get bounds(): paper.Rectangle {
        if (this.item) {
            return this.item.bounds;
        }

        return this.layer.bounds;
    }

    updateColors() {
        // Find cut contour
        this.item.getItems({
            match: (i) => {
                if (i.name === 'cut-contour') {
                    i.fillColor = null;
                    if (this.model.show_cut_contour) {
                        i.strokeColor = new this.paper.Color(0.5, 0.5, 0.5, 0.75);
                        i.strokeWidth = 1.5;
                    } else {
                        i.strokeColor = null;
                    }
                }
            }
        });

        this.is_masked = false;

        // Update regions
        for (const region of this.model.regions) {
            // Edge case if the regions have not loaded yet
            if (!region || !region.selectorId) {
                continue;
            }

            // Search for the item with the region ID
            const items: paper.Item[] = this.single_color ? [this.item] : this.item.getItems({
                match: (i) => {
                    return region.selectorId.indexOf(i.name) > -1 || region.selectorId.indexOf(i.className.toLowerCase()) > -1;
                }
            });

            // There could be multiple items, so let's update them all
            for (let item of items) {
                let _color = (region.color as Color);
                let color_code = region.color_code;
                let blend_mode = _color ? _color.blend_mode : 'normal';
                let _region = (region as any);

                let update_region = () => {
                    item.fillColor = new this.paper.Color(color_code);
                    item.visible = true;
                    item.clipMask = false;

                    if (_color && _color.texture) {
                        this.is_masked = true;

                        // If we already have a texture stored on the layer
                        if (item.parent['texture']) {
                            // If it doesn't match, remove it
                            if (item.parent['texture']['color_id'] != _color.id) {
                                item.parent['texture'].remove();
                                item.parent['texture'] = null;
                            }
                        }

                        // If the correct texture isn't set
                        if (!item.parent['texture']) {
                            let texture = null;

                            // Check the cache to see if we already have a fully loaded copy of it somewhere
                            // Loading the texture is very expensive we need to keep it in memory and reuse it
                            if (BaseElement.cached_textures[_color.id] && BaseElement.cached_textures[_color.id].loaded) {
                                texture = BaseElement.cached_textures[_color.id].clone();
                                let largest_size = Math.max(this.canvasService.canvasSize.width, this.canvasService.workspace.artboard.height);
                                texture.bounds = new this.paper.Rectangle(0, 0, largest_size, largest_size);
                            }
                            else {
                                // Texture is not cached or not loaded so we need to create a new raster
                                texture = new this.paper.Raster({
                                    source: _color.texture,
                                    crossOrigin: 'anonymous'
                                });
                                texture.on('load', () => {
                                    let largest_size = Math.max(this.canvasService.workspace.artboard.width, this.canvasService.workspace.artboard.height);
                                    texture.bounds = new this.paper.Rectangle(0, 0, largest_size, largest_size);

                                    if (!BaseElement.cached_textures[_color.id]) {
                                        BaseElement.cached_textures[_color.id] = texture.clone();
                                        BaseElement.cached_textures[_color.id].remove();
                                    }
                                });
                            }

                            texture['color_id'] = _color.id;
                            item.parent.addChild(texture);
                            item.parent['texture'] = texture;
                        }

                        let texture = item.parent['texture'];
                        texture.rotation = 0;
                        texture['color_id'] = _color.id;

                        // Place in the same location so it looks like its cutout of a single piece of vinyl
                        let largest_size = Math.max(this.canvasService.workspace.artboard.width, this.canvasService.workspace.artboard.height);
                        texture.bounds = new this.paper.Rectangle(0, 0, largest_size, largest_size);

                        item.clipMask = true;
                        item.sendToBack();
                        texture.bringToFront();
                    }
                    else if (item.parent['texture']) {
                        item.parent['texture'].remove();
                        item.parent['texture'] = null;
                    }

                    return item;
                }

                if (region.type === RegionType.FILL) {
                    if (!color_code) {
                        item.fillColor = null;
                        item.visible = false;
                    }
                    else {
                        item = update_region();

                        if (_region.region && _region.region.blend_mode_selector && blend_mode) {
                            const blend_items: paper.Item[] = this.single_color ? [this.item] : this.item.getItems({
                                match: (i) => {
                                    return _region.region.blendSelectorId.indexOf(i.name) > -1 || _region.region.blendSelectorId.indexOf(i.className.toLowerCase()) > -1;
                                }
                            });
                            for (const blend_item of blend_items) {
                                blend_item.blendMode = blend_mode;
                            }
                        }
                        else {
                            item.blendMode = 'normal'
                        }
                    }
                }
                else {
                    if (color_code) {
                        item.strokeColor = new this.paper.Color(color_code);
                        item.strokeWidth = region.stroke_width;
                        item.visible = true;
                    }
                    else {
                        item.visible = false;
                    }
                }
            }
        }
    }

    override isImmutable(): boolean {
        return super.isImmutable() && !this.model.forced_top_layer;
    }

    override get offset_path_enabled() {
        return super.offset_path_enabled && !this.isImmutable()
    }

    override get elementBounds(): paper.Rectangle {
        if (this.width && this.height) {
            return new this.paper.Rectangle(
                // Center of element, not top left
                new this.paper.Point(
                    this.x - this.width / 2,
                    this.y - this.height / 2
                ),
                new this.paper.Size(this.width, this.height)
            );
        } else {
            return this.bounds;
        }
    }

    override get locked() {
        if (!this.model.selectable) {
            this.showOptions = false;

            return !this.model.selectable;
        }
        this.showOptions = true;

        return this.lockedInPlace;
    }

    override get lockedInPlace() {
        return this._locked || (!this.canvasService.admin_options && this.model.use_as_mask);
    }

    public unite() {
        if (this.is_masked) {
            return;
        }

        const elementNames: string[] = ['Shape', 'Path', 'CompoundPath'];
        const hasClipMask = (obj: any): boolean => {
            if (elementNames.indexOf(obj.className) > -1 && obj.clipMask === true) {
                return true;
            } else if (obj.hasChildren && obj.hasChildren()) {
                for (const child of obj.children) {
                    if (hasClipMask(child))
                        return true;
                }
            }

            return false;
        };

        const getChildren = (obj: any, arr?: any[]) => {
            arr = arr || [];
            if (elementNames.indexOf(obj.className) > -1 && obj.visible && obj.area !== undefined && obj.clipMask === false) {
                arr.push(obj);
            }
            else if (obj.hasChildren && obj.hasChildren()) {
                for (const child of obj.children) {
                    getChildren(child, arr);
                }
            }

            return arr;
        };

        /*
        // Don't try to unify clipart with clip masks
        if (hasClipMask(this.item)) {
            return;
        } */

        this.item.remove();

        const items: any[] = getChildren(this.item);

        let i: any = null;
        for (const item of items) {
            if (!i) {
                i = item;
            } else {
                i = i.unite(item);
            }
        }

        this.item = i;
        this.layer.addChild(i);
        this.preventUpdate = true;
    }

    public override preSave() {
        if (!this.item) return null;

        if (!this.model.show_cut_contour) {
            // Remove cut paths
            const toRemove: paper.Item[] = [];
            this.item.getItems({
                match: (i: paper.Item): void => {
                    if (i.name === 'cut-contour') {
                        toRemove.push(i);
                    }
                }
            });
            for (const item of toRemove)
                item.remove();
        }

        if (this.model instanceof CustomClipart && this.model.clipart.colors === EClipartColorChoiceEnum.SINGLE_COLOR) {
            this.unite();
        }

        if (this.model.clipart.replaced_in_processed) {
            this.item.data['replace-in-processed-file'] = true;
            this.item.data['width'] = Number(this.bounds.width).toFixed(4);
            this.item.data['height'] = Number(this.bounds.height).toFixed(4);
            this.item.data['x'] = Number(this.bounds.x).toFixed(4);
            this.item.data['y'] = Number(this.bounds.y).toFixed(4);
        }

        // Rename all cut path ids to something else if it's not immutable or a background element
        if (!this.isImmutable()) {
            let cutpath_ids = [
                'CutContour',
                'cut-contour',
                'contour-cut',
                'CutPath',
                'cutpath',
                'cut-path',
                'oval-background',
                'template-background',
                'permit_outline',
                'permit-background',
                'outline',
                'rectangle-cut-path',
                'cut-contour_1_',
                'background'
            ];

            const items: paper.Item[] = [];
            this.item.getItems({
                match: (i: paper.Item): void => {
                    if (cutpath_ids.indexOf(i.name) != -1) {
                        items.push(i);
                    }
                }
            });

            for (const item of items) {
                item.data['ignore-id'] = true
            }
        }
    }

    public override isClipartType(): boolean {
        return true;
    }
}
