import {SymbolTypeName} from '../../../models/abstract/VariableDataElementAbstract';
import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './AddText.html';
import {data, method} from '../../../../../core/adapters/VueComponent';
import {SymbolType} from '../../../models/enums';

class DesignToolAddTextController extends AbstractDesignToolController {
    @data()
    new_text_type: string;

    @data()
    new_text: string;

    @data()
    new_text_end: string;

    constructor(component) {
        super(component);

        this.new_text_type = this.text_types[0];
    }

    @method()
    isSingleTextInput() {
        return this.new_text_type == SymbolTypeName.NORMAL_TEXT || this.new_text_type == SymbolTypeName.CSV_TEXT;
    }

    @method()
    addText($event?): void {
        if ($event) {
            $event.preventDefault();
        }

        let data;
        if (this.isSingleTextInput()) {
            data = {
                text: this.new_text,
            };
        }
        else {
            data = {
                text: 'Number Range',
                symbol_range_start: this.new_text,
                symbol_range_end: this.new_text_end,
            }
        }

        switch (this.new_text_type) {
            case SymbolTypeName.NORMAL_TEXT:
                data['symbol'] = SymbolType.NONE;
                break;
            case SymbolTypeName.CSV_TEXT:
                data['symbol'] = SymbolType.CSV_LIST;
                break;
            case SymbolTypeName.NUMBER_RANGE:
                data['symbol'] = SymbolType.NUMBER_RANGE;
                break;
            default:
                data['symbol'] = SymbolType.NONE;
                break;
        }

        let text_element = this.productService.createTextElement(data);
        text_element.maintain_proportion = false;

        this.toggleFontScaling();

        this.new_text = '';
        this.new_text_end = '';
        this.new_text_type = this.text_types[0];
    }
}


export default function DesignToolAddText(): IVueComponent {

    return {
        controller: DesignToolAddTextController,
        template: html,
        tag: 'design-tool-add-text'
    };
}
