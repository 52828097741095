import {html} from './AddFile.html';
import VueComponent, {data, prop, method} from '../../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import CustomEditorService from '../../../services/CustomEditorService';
import {UserFile} from '../../../models/UserFile';
import MessageList from '../../../../../core/utils/MessageList';
import {eventToFiles} from '../../../../../core/utils/utils';
import {Http} from '../../../../../core/services/Http';

class AddFileComponentController extends VueComponent {
    static override $inject = [
        '$http',
        'UserFile',
    ];

    @prop()
    productService: CustomEditorService;

    @data()
    errors: MessageList;

    @data()
    percent_loaded: number;

    @data()
    user_files: any;

    @data()
    method: string;

    @data()
    search: string;

    @data()
    limit;

    @data()
    page;

    @data()
    paginator_callback;

    @data()
    file: File;

    userFileModel: typeof UserFile;
    $http: Http;

    constructor(component, $http: Http, userFileModel: typeof UserFile) {
        super(component);

        this.userFileModel = userFileModel;
        this.$http = $http;

        this.page = 1;
        this.limit = 21;
        this.search = "";
        this.errors = new MessageList();
        this.paginator_callback = this.paginationFunction.bind(this);

        this.changeMethod('upload');
    }

    @method()
    uploadFile($event) {
        this.file = eventToFiles($event)[0];

        let form_data = new FormData()
        form_data.append('file', this.file);

        if (this.productService.configured.user_id) {
            form_data.append('user', `${this.productService.configured.user_id}`);
        }

        this.$http.request({
            url: '/custom/api/v1/upload/',
            data: form_data,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    this.percent_loaded = (progressEvent.loaded / progressEvent.total) * 100;
                }
                else {
                    this.percent_loaded = 0
                }
            }
        }).then(this.success.bind(this), this.failure.bind(this));
    }

    async success(response) {
        const user_file = UserFile.objects.get({
            id: response.data.user_file
        });

        await user_file.$promise;

        this.addFile(user_file);
    }

    @method()
    addFile(user_file) {
        this.productService.createUserFileElement({
            user_file: user_file
        });
    }

    failure(response: any) {
        this.errors = new MessageList();

        if (response.data) {
            if (response.data.error) {
                this.errors.add('file', response.data.error);
            }
            if (response.data.errors) {
                this.errors.merge(response.data.errors);
            }
        }
        else {
            this.errors.add('file', 'Failed to upload file');
        }
    }

    @method()
    changeMethod(method, $event?) {
        if($event)
            $event.preventDefault();

        this.method = method;

        if (this.method == 'my-files' && !this.user_files) {
            this.getFiles();
        }
    }

    @method()
    getFiles() {
        let q = {
            limit: this.limit,
            offset: this.limit * (this.page - 1),
            name__icontains: this.search,
            customer: this.productService.configured.user_id,
            hidden: false
        };

        this.user_files = this.userFileModel.objects.filter(q);
    }

    paginationFunction(page) {
        this.page = page;
        this.getFiles();
    }

    @method()
    removeUserFile(file, $event?) {
        file.remove($event).then(() => {
            this.user_files.items.removeItemFromID(file.id);
            this.getFiles();
        });
    }
}

export default function DesignToolAddFile(): IVueComponent {

    return {
        controller: AddFileComponentController,
        template: html,
        tag: 'design-tool-add-file'
    };
}
