import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './Sides.html';
import AbstractDesignToolController from '../AbstractDesignToolController';
import {data, method} from '../../../../../core/adapters/VueComponent';

class DesignToolSidesController extends AbstractDesignToolController {
    @data()
    updateBackgroundColorCallback;

    constructor(props) {
        super(props);

        this.updateBackgroundColorCallback = this.updateBackgroundColor.bind(this);
    }


    @method()
    updateBackgroundColor(color, clipart_region) {
        for (const item of this.element_manager.allBackgroundClipartElements) {
            for (const template_region of item.model.regions) {
                if ((template_region as any).region.selector == clipart_region.region.selector) {
                    template_region.color = color;
                }
            }
        }

        this.productService.configured.material_color = color;
        this.canvas_service.trigger('design-change');
    }
}


export default function DesignToolSides(): IVueComponent {

    return {
        controller: DesignToolSidesController,
        template: html,
        tag: 'design-tool-sides'
    };
}
