export const html:string = `
<div class="single-step-qr-code-component">
    <steps-indicator :one="'QR Code Info'" :two="'Design'" :three="'Customize'" :step="step"></steps-indicator>
    
    <div v-if="step == 1" class="data-collection-section">
        <div class="form-group col-xs-12">
            <label>Custom QR Code Data</label>
            <error-list :errors="errors" field="custom"></error-list>
            <textarea class="form-control" name="url" v-model="qr_data.custom"></textarea>
        </div>
        
        <div class="col-xs-12 text-center">
            <button class="btn btn-ghost" @click="pickProduct()">Continue</button>
        </div>
    </div>
    <div v-if="step == 2">
        <div class="responsive-grid-md-5 responsive-grid-lg-5">
            <div v-for="product in products.items" class="gc-xs-6 gc-sm-4 gc-md-1 qr-product-tile" @click="setupProduct(product, $event)">
                <img :src="product.image_url">
                <span>{{ product.name }}</span>
            </div>
        </div>
    </div>
    <div v-if="step == 3" class="text-center">
        <h2>Setting Up Product...</h2>
    </div>
</div>
`;