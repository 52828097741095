/*
    Custom.ts

    This file contains everything that's needed to load the design tool.
 */

import {Services} from '../../ts/core/services/Services';

import registerCustomServices from '../../ts/apps/svgcustom/services/setup.init';

import registerCoreComponents from '../../ts/core/components/init';
import registerCustomComponents from '../../ts/apps/svgcustom/components/custom.init';
import registerProductCoreAddonComponents from '../../ts/apps/products_shared/components/products.init';

import CustomProductSetupPageApp from '../../ts/apps/svgcustom/apps/CustomProductSetupPageApp';
import registerProductAddonServices from '../../ts/apps/products_shared/services/init';
import VueApp from '../../ts/core/adapters/VueApp';
import {IntegrationExtra} from '../../ts/core/utils/IntegrationExtra';
import registerDeliveryComponents from '../../ts/apps/delivery/components/init';

export default class SetupIntegration extends IntegrationExtra {
    override configureServices() {
        Services.resolve('loaded-page-app');
    }

    setupApps(): void {
        const setup_app = new CustomProductSetupPageApp();
        registerCoreComponents(setup_app);
        registerCustomComponents(setup_app);
        registerDeliveryComponents(setup_app);
        registerProductCoreAddonComponents(setup_app);
        setup_app.mount('#setup-app');

        const inline_qr = new VueApp()
        registerCoreComponents(inline_qr);
        registerCustomComponents(inline_qr);
        registerProductCoreAddonComponents(inline_qr);
        inline_qr.mount('#inline-qr-app');

    }

    setupModels(): void {
        // Done in CustomCommon.ts
    }

    setupServices(): void {
        registerProductAddonServices(Services);
        registerCustomServices(Services);
    }
}