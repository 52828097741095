export const html:string = `
<div class="tool-tab">
    <label><img src="/static/img/vector-canvas/layers-tab.svg" alt="Layers"> Layers</label>
    <div class="tool-layer" v-for="element_layer in $filters.orderBy(element_manager.elements, 'z', true)"
         :class="{'layer-errors': element_layer.model.errors && element_layer.model.errors.length > 0, 'selected-layer': selection_manager.isMultiSelected(element_layer), 'immutable': element_layer.isImmutable()}"
         v-show="all || !element_layer.isImmutable()">
        <div class="layer-type" @click="selection_manager.select(element_layer, $event)" style="user-select: none;">
            <i class="fa fa-font" v-if="element_layer.isTextType()" :style="{'color': element_layer.model.color ? '#' + element_layer.model.color.code : 'black' }"></i>
            <i class="fa fa-paint-brush" v-if="element_layer.isShapeType()"></i>

            <i class="fa fa-upload" v-if="(element_layer.isRasterType() || element_layer.isMarketplaceType()) && !element_layer.url"></i>
            <img v-if="(element_layer.isRasterType() || element_layer.isMarketplaceType()) && element_layer.url" :src="element_layer.url">

            <i v-if="element_layer.isClipartType() && !element_layer.url" class="fa fa-image"></i>
            <img v-if="element_layer.isClipartType() && element_layer.url" :src="element_layer.url">

            <i class="fa fa-qrcode" v-if="element_layer.isQRCodeType()"></i>
            <i class="fa fa-crop" v-if="element_layer.isCropType()"></i>
            <span>{{ element_layer.model.label }}</span>
        </div>
        <div class="layer-actions">
            <i class="fa fa-arrow-up" @click="moveElementUp($event, element_layer)"></i>
            <i class="fa fa-arrow-down" @click="moveElementDown($event, element_layer)"></i>
            <i class="fa fa-times" @click="element_layer.remove($event)"></i>
        </div>
    </div>
</div>
`;