import {html} from './SetupColor.html';
import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {Services} from '../../../../core/services/Services';
import PaperCanvasService from '../../services/PaperCanvasService';
import {ElementManager} from '../../services/ElementManager';

export class ColorController extends VueComponent {
    constructor(component) {
        super(component);

        this.updateBackgroundColorCallback = this.updateBackgroundColor.bind(this);
    }

    @prop()
    productService;

    @data()
    updateBackgroundColorCallback: any;

    @method()
    updateBackgroundColor(color, clipart_region) {
        for (const item of this.productService.element_manager.allBackgroundClipartElements) {
            for (const template_region of item.model.regions) {
                if ((template_region as any).region.selector == clipart_region.region.selector) {
                    template_region.color = color;
                }
            }
        }

        this.productService.configured.material_color = color;

        Services.get<PaperCanvasService>('PaperCanvasService').flushFrameTasks();

        this.productService.checkSides();
    }

    @method()
    elementSet() {
        if (!this.productService.base.show_material_color) {
            return this.productService.element_manager.allEditableBackgroundClipartElements;
        }
        return this.productService.element_manager.editableBackgroundClipartElements;
    }
}

export default function TemplateSetupColor(): IVueComponent {
    return {
        controller: ColorController,
        template: html,
        tag: 'template-setup-options-color'
    }
}
