import ProductPageLoaderAbstract from './ProductPageLoaderAbstract';
import {data, method} from '../../../core/adapters/VueComponent';
import {Services} from '../../../core/services/Services';
import CustomService from '../services/CustomService';
import {openModal} from '../../../core/utils/Modal';
import {ToolTypeEnum} from '../models/enums';

export default class CustomProductSetupPageApp extends ProductPageLoaderAbstract {

    @data()
    override product_service: CustomService;

    @data()
    show_preview_tool: boolean;

    @data()
    show_more_sizes: boolean;

    constructor() {
        super(Services.get('CustomService'), 'product', 'configured_product');
    }

    @method()
    showCustomSizeOption() {
        return this.product_service.base.allow_custom_size;
    }

    @method()
    useDesignTool($event) {
        if (this.product_service.saving) {
            return;
        }

        this.product_service.configured.tool_type = ToolTypeEnum.ADVANCED;
        this.product_service.configured.configure = true;

        if (this.product_service.similar_templates) {
            if (this.product_service.similar_templates.totalCount > 1) {
                this.showRelated($event);
            }
            else if (this.product_service.similar_templates.totalCount === 1) {
                this.product_service.useDesign(this.product_service.similar_templates.items[0], $event);
            }
            else {
                this.product_service.save($event);
            }
        }
        else {
            this.product_service.save($event);
        }
    }

    @method()
    uploadFile($event) {
        if (this.product_service.saving) {
            return;
        }

        // Don't show the popup if there are error because they will be hidden
        this.product_service.validate();
        if (this.product_service.hasErrors) {
            return;
        }

        this.product_service.configured.tool_type = ToolTypeEnum.UPLOAD;
        openModal('vector-canvas-modals-setup-upload-modal')
    }

    @method()
    showRelated($event) {
        if ($event) {
            $event.preventDefault();
        }
        openModal('show-related-products-modal')
    }
}