export const html: string = `
<div class="tool-tab">
    <div class="add-file-component">
        <div class="panel panel-primary" v-if="method == 'upload'">
            <label><img src="/static/img/vector-canvas/upload-file-thick.svg" alt="Upload New File"> Upload New File</label>
            <div class="panel-body">
                <p>Upload one of the following file formats: eps, ai, pdf, psd, tif, bmp, png, jpg, gif, heif or avif</p>
                <div class="drop-image-container" @dragover.prevent="" @drop.prevent="uploadFile">
                    <i class="fa fa-image"></i>
                    <p> Drag files here to upload <br> or </p>
                    <div class="form-group" v-if="!file" id="user-file-input">
                        <input class="btn-ghost" id="file-upload" type="file" @change="uploadFile">
                        <label for="file-upload">Choose file</label>
                    </div>
                </div>
                <div v-if="upload" class="form-group email-file-attachment"> {{ file.name }} <p class="note block">Do not navigate away from this page or close your browser. Your file is being processed.</p></div>
                <div class="progress" v-if="upload">
                    <div class="progress-bar" role="progressbar" :aria-valuenow="percent_loaded" aria-valuemin="0" aria-valuemax="100" :style="{'width': percent_loaded + '%'}">{{ percent_loaded }}%</div>
                </div>
                <h4 v-if="percent_loaded == 100">Processing graphic file - Please do not close this window</h4>
                <error-list field="file" :errors="errors"></error-list>
            </div>
            
            <button class="btn btn-light-tool change-type-button btn-full hidden-xs" @click="changeMethod('my-files')">
                <i class="fa fa-upload"></i>
                Use A Previously Uploaded File
            </button>
            <button class="btn btn-light-tool change-type-button btn-full hidden-lg hidden-md hidden-sm" @click="changeMethod('my-files')">
                <i class="fa fa-upload"></i>
                Use Previous File
            </button>
        </div>
        
        <div class="panel panel-primary" v-if="method == 'my-files'">
            <label><i class="fa fa-file"></i> My Files</label>
            <span class="back-a-step" @click="changeMethod('upload')"><i class="fa fa-arrow-left"></i> Back To Upload</span>
            <div class="panel-body">
                <div class="input-group col-xs-12 col-full user-file-search-area">
                    <input type="text" class="form-control search-input" v-model="search" @keydown.enter="getFiles()">
                    <a @click.prevent="getFiles()" class="input-group-addon"><i class="fa fa-search"></i> Search</a>
                </div>
                <ul class="item-list col-xs-12 col-full" v-if="user_files">
                    <li v-for="user_file in user_files.items" class="col-xs-12 col-sm-6 no-padd-xs select-file-option">
                        <div class="user-file-container">
                            <i class="fa fa-times" @click="removeUserFile(user_file, $event)"></i>
                            <div class="user-uploads-img-container" @click="addFile(user_file)">
                                <img :src="user_file.preview"/>
                            </div>
                            <div class="user-file-action-area">
                                <span class="block">{{ user_file.file_name }}</span>
                                <button class="btn btn-ghost btn-sm" @click="addFile(user_file)">Use This File</button>
                            </div>
                        </div>
                    </li>
                </ul>
                <callback-paginator :total="user_files.totalCount" :limit="limit" :page="page" :callback="paginator_callback"></callback-paginator>
            </div>
        </div>
    </div>
</div>
`;