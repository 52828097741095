export const html:string = `
<div class="right-aligned-options-area">
    <span class="change-text-type subtle-action" @click="show_type_option = !show_type_option">
        Change Data Rage <i class="fa" :class="{'fa-chevron-down': !show_type_option, 'fa-chevron-up': show_type_option}"></i>
    </span>
    <div class="tool-selection-set" v-show="show_type_option">
        <div class="left-side expanding-item">
            <span>Range Type</span>
        </div>
        <div class="right-side">
            <div v-if="productService.base.show_variable_data">
                <generic-dropdown v-if="productService.configured.defined_quantity && (!element || !element.model.definesQuantity)" :items="limited_types" v-model:selected="element.model.symbol_by_name"></generic-dropdown>
                <generic-dropdown v-if="!(productService.configured.defined_quantity && (!element || !element.model.definesQuantity))" :items="element.model.available_types" v-model:selected="element.model.symbol_by_name"></generic-dropdown>
            </div>
            <div v-if="!productService.base.show_variable_data">
                <generic-dropdown :items="limited_types" v-model:selected="element.model.symbol_by_name"></generic-dropdown>
            </div>
        </div>
    </div>
</div>
`;