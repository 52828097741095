export const html:string = `
<div class="tool-tab">
    <template-setup-options-shapes :side="canvas_service.active_side" :show-icon="true" :product-service="productService"></template-setup-options-shapes>
    <label v-show="element_manager.shapeElements.length > 0"><img src="/static/img/vector-canvas/background-layer.svg" alt="Background Colors"> Background Colors</label>
    <div v-for="shape_element in element_manager.shapeElements">
        <div class="right-aligned-options-area">
            <div class="tool-selection-set">
                <div class="left-side">
                    <span>Background Color</span>
                </div>
                <div class="right-side color-picker-set" v-if="!productService.base.show_material_color">
                    <generic-color-dropdown :items="productService.colors_available" v-model:selected="shape_element.model.background_shape_fill" none="Custom Color" :none-color="shape_element.model.background_shape_custom_fill_color" :hide-none="true"></generic-color-dropdown>
                    <color-picker v-model:color="shape_element.model.background_shape_custom_fill_color"></color-picker>
                </div>
                <div class="right-side" v-if="productService.base.show_material_color">
                    <generic-color-dropdown :items="productService.colors_available_for_material" v-model:selected="shape_element.model.background_shape_fill"></generic-color-dropdown>
                </div>
            </div>
        </div>
        <div class="right-aligned-options-area" v-show="shape_element.model.background_shape_border">
            <div class="tool-selection-set">
                <div class="left-side">
                    <span>Border Color</span>
                </div>
                <div class="right-side color-picker-set">
                    <generic-color-dropdown :items="productService.colors_available" v-model:selected="shape_element.model.background_shape_border_color" none="Custom Color" :none-color="shape_element.model.background_shape_custom_border_color" :hide-none="true"></generic-color-dropdown>
                    <color-picker v-model:color="shape_element.model.background_shape_custom_border_color"></color-picker>
                </div>
            </div>
        </div>
        <div class="normal-options-area" v-show="shape_element.model.background_shape_border">
            <div class="tool-action-item margin-options">
                <button class="btn btn-light-tool btn-single" @click="scaleBorder(shape_element, 1.10, $event)">
                    <i class="fa fa-plus-circle"></i>
                </button>
                <button class="btn btn-light-tool btn-single" @click="scaleBorder(shape_element, 0.90, $event)">
                    <i class="fa fa-minus-circle"></i>
                </button>
                <span>Border Size</span>
            </div>
            <div class="tool-action-item margin-options">
                <input type="range" max="150" step="1" v-model="shape_element.model.background_shape_border_margin" :min="shape_element.model.background_shape_border_size">
            </div>
            <div class="tool-action-item margin-options">
                <input v-model="shape_element.model.background_shape_border_margin" :min="shape_element.model.background_shape_border_size" max="150">
                <span>Border Margin</span>
            </div>
            <div class="tool-action-item margin-options">
                <button class="btn btn-light-tool" @click="removeBackgroundBorder(shape_element)">
                    <i class="fa fa-times"></i>
                </button>
                <span>Remove</span>
            </div>
        </div>
        <div class="normal-options-area" v-show="(!shape_element.model.background_shape_border && shape_element.has_border) || !shape_element.model.background_shape_border">
            <div class="tool-action-item full-width">
                <button class="btn btn-light-tool btn-single btn-full" @click="shape_element.resetBorder()">Add Border</button>
            </div>
        </div>
    </div>
    <div v-for="clipart_element in element_manager.editableBackgroundClipartElements">
        <label v-if="clipart_element.model.regions.length > 0"><i class="fa fa-image"></i> {{ clipart_element.model.label }}</label>
        <div v-for="region in clipart_element.model.regions" :class="{'important-options-area': region.region.type == 1 && region.hasStroke}">
            <div class="right-aligned-options-area">
                <div class="tool-selection-set">
                    <div class="left-side">
                        <span>{{ region.region.name }}</span>
                    </div>
                    <div class="right-side color-picker-set" v-if="!isSingleColor() && !region.use_material_colors">
                        <generic-color-dropdown :items="productService.colors_available" v-model:selected="region.regionColor" :none="'Custom Color: ' + region.custom_color" :none-color="region.custom_color" :hide-none="true"></generic-color-dropdown>
                        <color-picker v-model:color="region.custom_color"></color-picker>
                    </div>
                    <div class="right-side color-picker-set" v-if="!isSingleColor() && region.use_material_colors">
                        <generic-color-dropdown :items="productService.colors_available_for_material" v-model:selected="region.regionColor" :none="'Custom Color: ' + region.custom_color" :none-color="region.custom_color" :hide-none="true" :trigger-function="update_product_color"></generic-color-dropdown>
                    </div>
                    <div class="right-side" v-if="isSingleColor()">
                        <generic-color-dropdown :items="productService.colors_available" v-model:selected="productService.configured.material_color"></generic-color-dropdown>
                    </div>
                </div>
            </div>
            <div class="normal-options-area" v-show="region.region.type == 1 && region.hasStroke">
                <div class="tool-action-item">
                    <button class="btn btn-light-tool btn-single" @click="changeStrokeWidth(region, 1, $event)">
                        <i class="fa fa-plus-circle"></i>
                    </button>
                    <button class="btn btn-light-tool btn-single" @click="changeStrokeWidth(region, -1, $event)">
                        <i class="fa fa-minus-circle"></i>
                    </button>
                    <span>Stoke Width</span>
                </div>
                <div class="tool-action-item">
                    <input v-model="region.stroke_width">
                    <span>Stroke Width</span>
                </div>
                <div class="tool-action-item">
                    <button class="btn btn-light-tool" @click="region.removeStroke()">
                        <i class="fa fa-times"></i>
                    </button>
                    <span>Remove</span>
                </div>
            </div>
        </div>
    </div>
</div>
`;
