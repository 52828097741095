import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './EditClipart.html';
import {data, method} from '../../../../../core/adapters/VueComponent';
import {CustomMarketplace} from '../../../models/CustomMarketplace';
import ButtonProcessingState from '../../../../../core/utils/ButtonProcessingState';

class DesignToolEditClipartController extends AbstractDesignToolController {

    @method()
    switchClipart($event){
        if ($event)
            $event.preventDefault();

        this.productService.element_to_replace = this.element;
        this.activeTab = 'clipart-new';
    }

    @method()
    async toggleBackground($event) {
        let state = new ButtonProcessingState($event);
        state.process();

        let selected = this.element;
        let model: CustomMarketplace = this.element.model;

        if (!model.removed_background && model.artwork && !model.artwork.public_raster_remove_bg) {
            await model.artwork.removeBackground();
        }

        model.removed_background = !model.removed_background;

        selected.remove();

        this.productService.addMarketplaceElement(model);

        state.resolved();
    }
}


export default function DesignToolEditClipart(): IVueComponent {

    return {
        controller: DesignToolEditClipartController,
        template: html,
        tag: 'design-tool-edit-clipart'
    };
}
