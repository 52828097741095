import VueComponent from '../adapters/VueComponent';

export function FileInputRef(el: HTMLElement, scope: VueComponent, key: string) {
    /*
        Vue does not have v-model support for file inputs. In order to get files from the input we have to use the @change function
        which works for most cases. However, this does not allow us to unset the selected file in case of a network error or
        validation rejection. If the same file is selected for upload, and the file is not unset from the input, the @change method
        will not be called which prevents things from working correctly.

        This will be called when the value is set and if its undefined/null, it will unset the value on the dom element.
     */
    if (!scope[key]) {
        (el as HTMLInputElement).value = null;
    }
}
