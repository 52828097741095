export const html:string = `
<div class="tool-tab">
    <label><i class="fa fa-upload"></i> File Upload</label>
    <div class="important-options-area">
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.centerVertically()">
                <img src="/static/img/vector-canvas/center-horizontally.svg">
            </button>
            <button class="btn btn-light-tool" @click="element.centerHorizontally()">
                <img src="/static/img/vector-canvas/center-vertically.svg">
            </button>
            <span>Alignment</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool btn-single" @click="scaleSize(0.05, $event)">
                <i class="fa fa-plus-circle"></i>
            </button>
            <button class="btn btn-light-tool btn-single" @click="scaleSize(-0.05, $event)">
                <i class="fa fa-minus-circle"></i>
            </button>
            <span>Image Size</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="moveElementUp($event)">
                <img src="/static/img/vector-canvas/layer-up-icon.svg">
            </button>
            <button class="btn btn-light-tool" @click="moveElementDown($event)">
                <img src="/static/img/vector-canvas/layer-down-icon.svg">
            </button>
            <span>Layering</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.model.maintain_proportion = !element.model.maintain_proportion">
                <span v-show="!element.model.maintain_proportion"><i class="fa fa-unlock"></i> Unlocked</span>
                <span v-show="element.model.maintain_proportion"><i class="fa fa-lock"></i> Locked</span>
            </button>
            <span>Proportions</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.x_scale_inverted = !element.x_scale_inverted">
                <span><i class="fa fa-exchange"></i> Invert</span>
            </button>
            <span>Invert Image</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="switchCustomFile($event)">
                <span><i class="fa fa-sync"></i> Replace</span>
            </button>
            <span>Replace Image</span>
        </div>
    </div>
    <div class="normal-options-area" v-if="isElementLowQuality()">
        <div class="tool-warning">
            This element is lower than 200dpi and will look blurry when printed.
            Decrease the size of this element or the size of your product to fix this warning.
        </div>
    </div>
    <div class="normal-options-area even-spaced">
        <b>Image Options</b>
        <button class="btn btn-light-tool" @click="fitWidth($event)">
            <span><i class="fa fa-arrows-h"></i> Fit Width</span>
        </button>
        <button class="btn btn-light-tool" @click="fitHeight($event)">
            <span><i class="fa fa-arrows-v"></i> Fit Height</span>
        </button>
        <button class="btn btn-light-tool" @click="toClipMask($event)">
            <span v-if="!element.cropped"><i class="fa fa-crop"></i> Crop Image</span>
            <span v-if="element.cropped"><i class="fa fa-crop"></i> Edit Crop</span>
        </button>
    </div>
    <div class="normal-options-area" v-if="element.model.user_file && !element.model.user_file.is_vector && !productService.base.disable_remove_background">
        <b>Remove Image Background</b>
        <button @click="toggleBackground($event)" class="btn btn-light-tool full-width">
            <span v-if="!element.model.user_file.original_background_file"><i class="fa fa-cut"></i> Remove</span>
            <span v-if="element.model.user_file.original_background_file"><i class="fa fa-undo"></i> Undo</span>
        </button>
    </div>
    <hr class="section-divider">
    <div class="right-aligned-options-area margin-set" v-if="element.can_offset_path">
        <b>Cutpath Options</b>
        <div class="tool-selection-set" v-show="element.offset_path_enabled">
            <div class="left-side">
                <span>Border Width</span>
            </div>
            <div class="right-side">
                <div class="col-xs-6 col-full-left">
                    <input type="range" min="7" max="30" v-model="element.model.offset_width" @change="element.queueOffsetPath()">
                </div>
                <div class="col-xs-6 input-button-group">
                    <input type="number" min="7" max="30" v-model="element.model.offset_width" @change="element.queueOffsetPath()">
                    <button class="btn btn-light-tool" @click="element.removeOffsetPath()"><i class="fa fa-times"></i></button>
                </div>
            </div>
        </div>
        <div class="tool-selection-set" v-show="element.offset_path_enabled && !productService.base.offset_path_uses_material_color">
            <div class="left-side">
                <span>Border Color</span>
            </div>
            <div class="right-side color-picker-set">
                <generic-color-dropdown :items="productService.colors_available" v-model:selected="productService.configured.offset_path_fill_color" 
                    none="Custom Color" :none-color="productService.configured.offset_path_custom_fill_color" :hide-none="true" :trigger-function="on_path_color_change">    
                </generic-color-dropdown>
                
                <color-picker v-model:color="productService.configured.offset_path_custom_fill_color" :change="on_path_color_change"></color-picker>
            </div>
        </div>
        <div class="right-side" v-show="!element.offset_path_enabled">
            <button class="btn btn-light-tool" @click="element.addOffsetPath()">Add Border</button>
        </div>
    </div>
    <div class="col-xs-12 no-padd error-lists">
        <error-list class="col-xs-12" field="remove-bg" :errors="errors"></error-list>
    </div>
</div>
`;
