import {html} from './AutosaveList.html';
import CustomEditorService from '../../services/CustomEditorService';
import VueComponent, {method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';

class AutosaveComponentController extends VueComponent {

    @prop()
    productService: CustomEditorService;

    @prop()
    isNew: boolean;

    @prop()
    newDesign;

    @prop()
    continueEditing;

    @prop()
    newDesignImage;

    @method()
    remove(autosave) {
        autosave.delete();

        if (this.productService.autosaves.items.indexOf(autosave) != -1) {
            this.productService.autosaves.items.splice(this.productService.autosaves.items.indexOf(autosave), 1);
        }
    }
}

export default function AutosaveList(): IVueComponent {

    return {
        controller: AutosaveComponentController,
        template: html,
        tag: 'autosave-list'
    };
}
