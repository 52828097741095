import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './EditQRCode.html';
import {data, method} from '../../../../../core/adapters/VueComponent';
import {CustomQRCode} from '../../../models/CustomQRCode';

class DesignToolEditQRCodeController extends AbstractDesignToolController {
    @data()
    show_type_option: boolean;

    @data()
    paste_mode: boolean;

    @data()
    paste_data: string;

    timeout_id;

    onSizeChange() {
        clearTimeout(this.timeout_id);
        this.timeout_id = setTimeout(() => {
            this.productService.validateCodeSizes();
            this.$forceUpdate();
        }, 100);
    }

    override mounted() {
        super.mounted();

        this.element.model.bind('change:x', this.onSizeChange.bind(this));
        this.element.model.bind('change:y', this.onSizeChange.bind(this));

        this.productService.validateCodeSizes();

        // Needed for the size to display correctly
        this.element.once('updatePaper', () => {
            this.$forceUpdate();
        })
    }

    @method()
    dataKeys() {
        let model: CustomQRCode = this.element.model;

        if (!model.variable_data || model.variable_data.length == 0) {
            return [];
        }

        return Object.keys(model.variable_data[0]);
    }

    @method()
    addRow() {
        let item = {};

        for (const row of this.dataKeys()) {
            item[row] = '';
        }

        // Just in case something broke, reset it
        if (!this.element.model.variable_data) {
            this.element.model.variable_data = [this.element.model.qrcode.data_json];
        }

        this.element.model.variable_data.push(item);
    }

    @method()
    toReadableName(item) {
        return item.split('_').join(' ')
    }

    @method()
    size() {
        let size = this.element.measurements(this.productService.width, this.productService.height);
        return `${Number(size.width).toFixed(2)}"x${Number(size.height).toFixed(2)}`
    }

    csvToData(csv: string, keys: string[]): any[] {
        let data_set = [];
        let data = csv.split(',');
        let buffer = Object.create(null);

        for (let i = 0; i < data.length; i++) {
            buffer[keys[i % keys.length]] = data[i];

            if (i % keys.length == keys.length - 1) {
                data_set.push(buffer);
                buffer = Object.create(null);
            }
        }

        if (Object.keys(buffer).length != 0) {
            data_set.push(buffer);
        }

        return data_set;
    }

    dataToCSV(data: any[], keys: string[]): string {
        let data_list = [];
        for (const row of data){
            for (const col of keys) {
                data_list.push(row[col]);
            }
        }

        return data_list.join(',');
    }

    @method()
    togglePasteMode() {
        // Warning: A lot of assumptions are made about the format of the data are made here.
        // We accept an arbitrary number of rows and columns and the ordering of these columns may not match between
        // what the system parses and the data the user pastes in. Both in terms of the number of columns and the ordering
        // of the columns within the data set. If there are problems, this might need to be refactored to
        // ask the user to align the data and correct errors, or disable the feature for multiple columns, or separate the columns.
        if (this.paste_mode) {
            (this.element.model as CustomQRCode).variable_data = this.csvToData(this.paste_data, this.dataKeys());
        }
        else {
            this.paste_data = this.dataToCSV((this.element.model as CustomQRCode).variable_data, this.dataKeys());
        }

        this.paste_mode = !this.paste_mode;
    }

    @method()
    deleteRow(row: any) {
        (this.element.model as CustomQRCode).variable_data.splice((this.element.model as CustomQRCode).variable_data.indexOf(row), 1);
    }
}


export default function DesignToolEditQRCode(): IVueComponent {
    return {
        controller: DesignToolEditQRCodeController,
        template: html,
        tag: 'design-tool-edit-qr'
    };
}
