export const html: string = `
<div class="row">
    <div class="col-xs-6">
        <div class="form-group">
            <label>Sticker Size</label>
            <generic-dropdown :items="product_service.base.sizes" v-model:selected="product_service.size"></generic-dropdown>
        </div>
    </div>
    <div class="col-xs-6 size">
        <span>{{ $filters.number(product_service.width, 1) }}" x {{ $filters.number(product_service.height, 1) }}"</span>
    </div>
</div>
<div class="row">
    <div class="col-sm-4 col-xs-6">
        <div class="form-group">
            <label>Quantity</label>
            <input class="form-control flat" v-model="product_service.configured.quantity" />
        </div>
    </div>
    <div class="col-sm-8 col-xs-6 price">
        <span>{{ $filters.currency(product_service.price.price, product_service.currency) }}</span>
    </div>
</div>
<div class="row">
    <div class="col-xs-12">
        <label>&nbsp;</label>
        <a class="btn-ghost inverted add-to-cart" @click="product_service.save()"><span>{{ product_service.configured.id == null ? 'Add To Cart' : 'Save' }}</span></a>
    </div>
</div>
`;
