export const html:string = `
<div class="template-selection-item">
    <div class="template-selection-preview">
        <image-shadow :image="newDesignImage"></image-shadow>
    </div>
    <div class="template-selection-description">
        <div class="template-title">{{ isNew == 'true' ? 'Start a new design' : 'Saved Product' }}</div>
        <button class="btn btn-ghost" @click="newDesign()">{{ isNew == 'true' ? 'New Design' : 'Continue Editing'}}</button>
    </div>
</div>
<div class="template-selection-item" v-for="autosave in productService.autosaves.items">
    <i class="fa fa-trash remove-autosave" @click="remove(autosave)"></i>
    <div class="template-selection-preview">
        <image-shadow :image="autosave.preview"></image-shadow>
    </div>
    <div class="template-selection-description">
        <div class="template-title">{{ autosave.name || 'Untitled Design' }}</div>
        <button class="btn btn-ghost" @click="continueEditing(autosave.id)">Load Autosave</button>
    </div>
</div>
`;