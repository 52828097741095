import AddClipartComponent from './AddClipartComponent/AddClipartComponent';
import AutosaveComponent from './AutosaveComponent/AutosaveComponent';
import AutosaveList from './AutosaveList/AutosaveList';
import ColorPicker from './ColorPicker/ColorPicker';
import PaperCanvas from './PaperCanvas/PaperCanvas';
import TemplateSetupSize from './SetupSize/SetupSize';
import VectorCanvasModalsSetupUpload from './SetupUpload/SetupUpload';
import SubCanvasPreview from './SubCanvasPreview/SubCanvasPreview';
import TemplateFinalizeOptions from './TemplateFinalizeOptions/Options';
import TemplateSetupMaterial from './TemplateSetupMaterial/TemplateSetupMaterial';
import TemplateSetupOptions from './TemplateSetupOptions/TemplateSetupOptions';
import TemplateSetupOptionsShapes from './TemplateSetupOptionsShapes/TemplateSetupOptionsShapes';
import DragDropBuyClipartSelector from './ClipartSelector/ClipartSelector';
import DragDropBuyCanvas from './Canvas/Canvas';
import DragDropBuyClipartEditor from './ClipartEditor/ClipartEditor';
import DragDropBuyAddToCart from './AddToCart/AddToCart';
import VectorCanvasModalsRelatedTemplates from './VectorCanvas/Modals/RelatedTemplates/RelatedTemplates';
import StandaloneAddQRCode from './StandaloneAddQRCode/StandaloneAddQRCode';
import TemplateSetupColor from './SetupColor/SetupColor';

import registerToolComponents from './DesignTool/init';
import WebsiteQRCode from './WebsiteQRCode/WebsiteQRCode';
import CustomQRCode from './CustomQRCode/CustomQRCode';
import QualityWarning from './QualityWarning/QualityWarning';
import VueApp from '../../../core/adapters/VueApp';

/*
    Only components that are used on the following pages
    - Template Setup
    - Design Tool
    - Finalize Page
 */
export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(AddClipartComponent);
    vue_app.registerComponent(AutosaveComponent);
    vue_app.registerComponent(AutosaveList);
    vue_app.registerComponent(ColorPicker);
    vue_app.registerComponent(PaperCanvas);
    vue_app.registerComponent(TemplateSetupSize);
    vue_app.registerComponent(TemplateSetupColor);
    vue_app.registerComponent(VectorCanvasModalsSetupUpload);
    vue_app.registerComponent(SubCanvasPreview);
    vue_app.registerComponent(TemplateFinalizeOptions);
    vue_app.registerComponent(TemplateSetupMaterial);
    vue_app.registerComponent(TemplateSetupOptions);
    vue_app.registerComponent(TemplateSetupOptionsShapes);
    vue_app.registerComponent(DragDropBuyClipartSelector);
    vue_app.registerComponent(DragDropBuyCanvas);
    vue_app.registerComponent(DragDropBuyClipartEditor);
    vue_app.registerComponent(DragDropBuyAddToCart);
    vue_app.registerComponent(VectorCanvasModalsRelatedTemplates);
    vue_app.registerComponent(StandaloneAddQRCode);
    vue_app.registerComponent(WebsiteQRCode);
    vue_app.registerComponent(CustomQRCode);
    vue_app.registerComponent(QualityWarning);

    registerToolComponents(vue_app);
}