import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './Crop.html';
import {method} from '../../../../../core/adapters/VueComponent';
import {ClipMaskElement} from '../../../tool/ClipMaskElement';
import {ClipartElement} from '../../../tool/ClipartElement';

class DesignToolCropController extends AbstractDesignToolController {
    @method()
    changeClipMask($event, clipart) {
        if ($event) {
            $event.preventDefault();
        }

        let _element = this.element as ClipMaskElement;
        let current_mask = _element.mask;

        let options = {
            'clipart': clipart,
            'maintain_proportion': false,
            'x_scale_inverted': false,
            'y_scale_inverted': false,
            'template_clipart': null,
            'x': current_mask.x,
            'y': current_mask.y,
            'z': current_mask.z,
            'width': current_mask.width,
            'height': current_mask.height,
            'regions': null,
            'rotation': 0
        };

        // If we are editing a saved mask then we need to remove the clipart from the list so the server can delete it
        if (current_mask.model.id) {
            this.productService.configured.clipart_elements.removeItemFromID(current_mask.model.id)
        }

        let mask_clipart_model = this.productService.createClipartElement(options, false, false);
        let mask_clipart = new ClipartElement(mask_clipart_model.clipart.clipart, mask_clipart_model);
        let _old_mask = _element.changeClipMask(mask_clipart);

        let index = this.productService.configured.clipart_elements.indexOf(_old_mask);
        if (index != -1) {
            this.productService.configured.clipart_elements.splice(index, 1);
        }

        this.selection_manager.select(_element);
        _element.edit_state = true;

        this.updateSelection();
        this.selection_manager.drawControls();
    }
}


export default function DesignToolCrop(): IVueComponent {

    return {
        controller: DesignToolCropController,
        template: html,
        tag: 'design-tool-crop'
    };
}
