import {html} from './SubCanvasPreview.html';
import PaperCanvasService from '../../services/PaperCanvasService';
import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {Services} from '../../../../core/services/Services';
import {getInnerWidth} from '../../../../core/utils/utils';
import {IDeferred, IPromise, SimplePromise} from '../../../../core/utils/SimplePromise';
import {uuid4} from '../../../../core/utils/uuid';
import {disableVueForImport} from '../../../../core/adapters/VueUtils';

class SubCanvasPreviewController extends VueComponent {

    @prop()
    side;

    private setup_complete: IPromise<void>;
    private setup_deferred: IDeferred<void>;
    private paperScope;
    private layer;
    private size;
    private initilized: boolean;
    private delay;
    private paper_import;
    private paper;

    constructor(component) {
        super(component);
        this.setup_deferred = SimplePromise.defer();
        this.setup_complete = this.setup_deferred.promise;

        this.paper_import = import('paper');

        Services.get<PaperCanvasService>('PaperCanvasService').bind('design-change', async () => {
            // Don't let this try to update faster than 100ms due to how expensive it is
            if (this.delay) {
                clearTimeout(this.delay);
            }
            this.delay = setTimeout(() => {
                this.reloadLayer();
            }, 100);
        })
    }

    override mounted() {
        // Reset the canvas because vue reuses dom elements...
        const canvas = this.$el.children[0];
        canvas.style.height = null
        canvas.style.width = '100%';

        this.paper_import.then((paper) => {
            disableVueForImport(paper);
            this.paper = paper;
            this.setupCanvas();
        });
    }

    async setupCanvas() {
        await Services.get<PaperCanvasService>('PaperCanvasService').setup_complete

        const canvas = this.$el.children[0];
        this.size = getInnerWidth(canvas);

        canvas.style.width = `${this.size}px`;
        canvas.style.height = `${this.size}px`;

        this.paperScope = new this.paper.PaperScope();
        this.paperScope.activate();
        this.paperScope.setup(canvas);

        // Setup high quality down sampling for better scaling
        const ctx = this.paperScope.view.element.getContext('2d');
        ctx.imageSmoothingQuality = 'medium';
        ctx.imageSmoothingEnabled = true;

        this.layer = new this.paper.Layer();
        this.initilized = true;

        this.setup_deferred.resolve();

        this.reloadLayer();
    }

    async reloadLayer() {
        await this.setup_complete;

        if (!this.side.projectLayer) {
            return;
        }

        this.layer.removeChildren();

        let clone;
        if (this.side.clipViewArea) {
            let _opacity = this.side.clipViewArea.opacity;
            this.side.clipViewArea.opacity = 0;
            clone = this.side.projectLayer.clone({insert: false});
            this.side.clipViewArea.opacity = _opacity;
        }
        else {
            clone = this.side.projectLayer.clone({insert: false});
        }

        clone.visible = true;
        this.layer.addChild(clone);
        this.layer.fitBounds(new this.paper.Rectangle(0, 0, this.size, this.size));
    }
}

export default function SubCanvasPreview(): IVueComponent {
    return {
        controller: SubCanvasPreviewController,
        template: html,
        tag: 'sub-canvas-preview'
    };
}
