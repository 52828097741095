import AbstractDesignToolController from '../AbstractDesignToolController';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './EditFile.html';
import {data, method} from '../../../../../core/adapters/VueComponent';
import {UserFile} from '../../../models/UserFile';
import {Services} from '../../../../../core/services/Services';
import {Http} from '../../../../../core/services/Http';
import MessageList from '../../../../../core/utils/MessageList';
import {RasterElement} from '../../../tool/RasterElement';
import {processRequestError} from '../../../../../core/utils/utils';
import ButtonProcessingState from '../../../../../core/utils/ButtonProcessingState';
import {TForeignKeyURI} from '../../../../../core/models/fields/ForeignKeyURIField';

class DesignToolEditFileController extends AbstractDesignToolController {

    @method()
    isElementLowQuality() {
        if (!this.element || !this.element.model.user_file) {
            return false;
        }

        if (this.element.model.user_file.is_vector) {
            return false;
        }

        let product_size = this.productService.getProductSizeFromCanvas();
        let used_canvas_size = this.element_manager.getUsedSize();

        let width_percent = this.element.model.width / used_canvas_size.width;
        let height_percent = this.element.model.height / used_canvas_size.height;

        let element_width = width_percent * product_size.width;
        let element_height = height_percent * product_size.height;

        let upload = this.element.model.user_file;

        let width_dpi = upload.width / element_width;
        let height_dpi = upload.height / element_height;

        return width_dpi
    }

    @method()
    switchCustomFile($event) {
        if ($event) {
            $event.preventDefault();
        }

        this.productService.element_to_replace = this.element;
        this.activeTab = 'upload-new'
    }

    @method()
    toggleBackground($event) {
        let state = new ButtonProcessingState($event);
        state.process();

        let selected_element = this.element;
        let user_file: UserFile = selected_element.model.user_file;
        user_file.toggleRemoveBackground().then((new_file: UserFile) => {
            let options = {
                user_file: new_file,
                x: selected_element.model.x,
                y: selected_element.model.y
            };

            this.productService.createUserFileElement(options);
            selected_element.remove();

            state.resolved();
        }, (error) => {
            if (!this.errors) {
                this.errors = new MessageList();
            }

            this.errors.merge(processRequestError(error));
            state.resolved();
        })
    }

    @method()
    toClipMask($event) {
        if ($event) {
            $event.preventDefault();
        }

        this.productService.createClipMaskElement(this.element).then(() => {
            this.updateSelection();
            this.selection_manager.drawControls();
        })
    }

    @method()
    fitWidth($event?): void {
        if ($event) {
            $event.preventDefault();
        }

        this.element.centerVertically();
        this.element.centerHorizontally();
        this.element.height *= this.canvas_service.workspace.artboard.width / this.element.width;
        this.element.width = this.canvas_service.workspace.artboard.width;
    }

    @method()
    fitHeight($event?): void {
        if ($event) {
            $event.preventDefault();
        }

        this.element.centerVertically();
        this.element.centerHorizontally();
        this.element.width *= this.canvas_service.workspace.artboard.height / this.element.height;
        this.element.height = this.canvas_service.workspace.artboard.height;
    }
}


export default function DesignToolEditFile(): IVueComponent {

    return {
        controller: DesignToolEditFileController,
        template: html,
        tag: 'design-tool-edit-file'
    };
}
