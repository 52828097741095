import {SymbolType} from '../../../models/enums';
import {CodeType, QRCodeType} from '../../../models/QRCode';

export const html:string = `
<div class="tool-tab">
    <label><i class="fa fa-qrcode"></i> Code</label>
    <error-list class="warning" field="size" :errors="element.model.errors"></error-list>
    <variable-data-selector v-model:element="element" :product-service="productService"></variable-data-selector>
    <div class="important-options-area">
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="element.centerVertically()">
                <img src="/static/img/vector-canvas/center-horizontally.svg">
            </button>
            <button class="btn btn-light-tool" @click="element.centerHorizontally()">
                <img src="/static/img/vector-canvas/center-vertically.svg">
            </button>
            <span>Alignment</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool btn-single" @click="scaleSize(0.05, $event)">
                <i class="fa fa-plus-circle"></i>
            </button>
            <button class="btn btn-light-tool btn-single" @click="scaleSize(-0.05, $event)">
                <i class="fa fa-minus-circle"></i>
            </button>
            <span>QR Code Size</span>
        </div>
        <div class="tool-action-item">
            <button class="btn btn-light-tool" @click="moveElementUp($event)">
                <img src="/static/img/vector-canvas/layer-up-icon.svg">
            </button>
            <button class="btn btn-light-tool" @click="moveElementDown($event)">
                <img src="/static/img/vector-canvas/layer-down-icon.svg">
            </button>
            <span>Layering</span>
        </div>
    </div>
    <div class="qr-preview-info clearfix">
        <div class="qr-data" v-if="element.model.symbol == ${SymbolType.NONE}">
            <label class="sub-label" v-if="element.model.qrcode.code_type == ${CodeType.QR_CODE}">QR Code Info</label>
            <label class="sub-label" v-if="element.model.qrcode.code_type == ${CodeType.BAR_CODE}">Barcode Info</label>
            <div v-if="element.model.qrcode.data_json">
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.name"><b>Name:</b>{{ element.model.qrcode.data_json.name }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.title"><b>Title:</b>{{ element.model.qrcode.data_json.title }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.email"><b>Email:</b>{{ element.model.qrcode.data_json.email }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.phone_home"><b>Home Telephone:</b>{{ element.model.qrcode.data_json.phone_home }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.home_address_1"><b>Home Address:</b>{{ element.model.qrcode.data_json.home_address_1 }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.home_city"><b>Home City:</b>{{ element.model.qrcode.data_json.home_city }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.home_state"><b>Home State:</b>{{ element.model.qrcode.data_json.home_state }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.home_zip"><b>Home Zip:</b>{{ element.model.qrcode.data_json.home_zip }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.home_country"><b>Home Country:</b>{{ element.model.qrcode.data_json.home_country }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.company"><b>Company:</b>{{ element.model.qrcode.data_json.company }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.phone_work"><b>Work Phone:</b>{{ element.model.qrcode.data_json.phone_work }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.work_address_1"><b>Work Address:</b>{{ element.model.qrcode.data_json.work_address_1 }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.work_city"><b>Work City:</b>{{ element.model.qrcode.data_json.work_city }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.work_state"><b>Work State:</b>{{ element.model.qrcode.data_json.work_state }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.work_zip"><b>Work Zip:</b>{{ element.model.qrcode.data_json.work_zip }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.work_country"><b>Work Country:</b>{{ element.model.qrcode.data_json.work_country }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.url"><b>Website URL:</b>{{ element.model.qrcode.data_json.url }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.sms"><b>Text Cell Phone Number:</b>{{ element.model.qrcode.data_json.sms }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.smsm"><b>Text Message:</b>{{ element.model.qrcode.data_json.smsm }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.custom"><b>Custom:</b>{{ element.model.qrcode.data_json.custom }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.code_type == ${CodeType.BAR_CODE}"><b>Type:</b>{{ element.model.qrcode.barcode_type_name }}</div>
                <div class="qr-data-item" v-if="element.model.qrcode.data_json.value"><b>Value:</b>{{ element.model.qrcode.data_json.value }}</div>
                <div class="qr-data-item"><b>Size:</b>{{ size() }}</div>
            </div>
        </div>
        <img v-if="element.model.symbol == ${SymbolType.NONE} && element.model.qrcode.id" :src="'/custom/api/v1/qr-clipart/' + element.model.qrcode.id + '.svg'">
        
        <div class="qr-code-table" v-if="element.model.symbol == ${SymbolType.CSV_LIST}">
            <i class="fa toggle-data-input" :class="{'fa-table': paste_mode, 'fa-clipboard': !paste_mode}" @click="togglePasteMode()"></i>
            <span v-if="paste_mode">CSV Data</span>
            <textarea v-model="paste_data" v-if="paste_mode"></textarea>
            <table class="table" v-if="!paste_mode">
                <thead>
                    <tr>
                        <th v-for="key in dataKeys()">
                            <span style="text-transform: capitalize">{{ toReadableName(key) }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in element.model.variable_data">
                        <td v-for="key in dataKeys()">
                            <input type="text" v-model="row[key]">
                        </td>
                        <td class="text-center">
                            <i class="fa fa-trash" @click="deleteRow(row)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button @click="addRow()" class="btn btn-light-tool" v-if="!paste_mode">Add Row</button>
        </div>
        <div class="qr-code-table" v-if="element.model.symbol == ${SymbolType.NUMBER_RANGE}">
            <label>Range From</label>
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="key in dataKeys()">
                            <span style="text-transform: capitalize">{{ toReadableName(key) }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-for="key in dataKeys()">
                            <input type="text" v-model="element.model.variable_data[0][key]">
                        </td>
                    </tr>
                </tbody>
            </table>
            <label>Range To</label>
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="key in dataKeys()">
                            <span style="text-transform: capitalize">{{ toReadableName(key) }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-for="key in dataKeys()">
                            <input type="text" v-model="element.model.variable_data[1][key]">
                        </td>
                    </tr>
                </tbody>
            </table>
            <span>Total Quantity</span>
            <input v-model="element.model.range" disabled="disabled">
        </div>
    </div>
</div>
`;
