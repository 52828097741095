import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import PaperCanvasService from '../../services/PaperCanvasService';
import {Services} from '../../../../core/services/Services';
import IVueComponent from '../../../../core/adapters/IVueComponent';

/*
 * This directive's main purpose is to set up a PaperJS canvas and tool.
 * PaperService is then passed the canvas and handles all of the heavy lifting.
 */

class PaperCanvasController extends VueComponent {
    @data()
    canvas_service;

    @prop()
    blank: boolean;

    constructor(c) {
        super(c);

        this.canvas_service = Services.get<PaperCanvasService>('PaperCanvasService');
    }

    override async mounted() {
        super.mounted();

        this.canvas_service.setupPaperFromCanvas(this.$el);

        if (window.location.origin.indexOf('192.168') != -1 || window.location.origin.indexOf('localhost') != -1) {
            window.onbeforeunload = () => {
                return 'Are you sure you wish to leave this page? Your design will be lost.' //override generic string use in certain browsers.
            }
        }
    }
}

export default function PaperCanvas(): IVueComponent {
    return {
        controller: PaperCanvasController,
        tag: 'paper-canvas',
        template: `<canvas id="vector-canvas" :style="{'background-color': blank ? '' : canvas_service.canvas_background_color}"></canvas>`
    };
}
