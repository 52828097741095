import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import {html} from './ProductProductionTime.html';
import {Material} from '../../../svgcustom/models/Material';
import {Services} from '../../../../core/services/Services';
import {Http} from '../../../../core/services/Http';

class ProductProductionTimeController extends VueComponent {
    @prop({
        watch: true
    })
    material: Material;

    @prop({
        watch: true
    })
    quantity: number;

    @data()
    standard: number;

    @data()
    rush: number;

    @data()
    message: string;

    override mounted() {
        super.mounted();
        this.updateTimes();

        this.bind('change:material', this.updateTimes.bind(this));
        this.bind('change:quantity', this.updateTimes.bind(this));
    }

    @method()
    updateTimes() {
        if (!this.material || !this.quantity) {
            return;
        }

        Services.get<Http>('$http').request({
            method: 'GET',
            url: '/delivery/api/get-production-times-basic/',
            params: {
                material: this.material.id,
                quantity: this.quantity
            }
        }).then((response) => {
            this.message = response.data.message;
            this.standard = response.data.standard;
            this.rush = response.data.rush;
        });
    }
}

export default function ProductProductionTime() {
    return {
        tag: 'product-production-time',
        controller: ProductProductionTimeController,
        template: html
    }
}